import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { FormControl, Validators } from '@angular/forms';
import { UrlHistoryService } from '../../../services/url.history.service';
import {TranslateService} from "../../../services/translate.service";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-receipt-email-dialog',
  templateUrl: './receipt-email-dialog.component.html',
  styleUrls: ['./receipt-email-dialog.component.scss']
})
export class ReceiptEmailDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  action: string;
  saveEmail: boolean;
  receiptKey = "";
  venueId = "";
  isWorking: boolean;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  email: string;
  voucherId: string;
  title: string;

  @ViewChild("emailInput") emailInput: ElementRef;
  private askToSave = false;
  entityName: string;
  actionToEntityNameMap = { send_receipt: "kvitto", send_voucher: "biljett", send_all_vouchers: "biljett", send_giftcard: "presentkortet" };
  postId: string;
  isKiosk: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) data,
              private dialogRef: MatDialogRef<ReceiptEmailDialogComponent>,
              public api: ApiService, private identity: UrlHistoryService, private translate: TranslateService,
              private auth: AuthService,) {
    this.action = data.action || "";
    this.title = data.title || 'Skicka';
    this.venueId = data.venueId;
    this.receiptKey = data.receiptKey;
    this.email = data.receipt_mail;
    this.postId = data.postId;
    this.entityName = this.actionToEntityNameMap[this.action];
    this.askToSave = true;
    this.saveEmail = data.receipt_mail != null;
    this.isWorking = false;
    this.voucherId = data.voucher_id;
  }


  ngOnInit(): void {
    this.isKiosk = this.auth.isKioskMode();
    if (this.isKiosk) {
      this.email = "";
      this.saveEmail = false;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.emailInput.nativeElement.focus());
  }

  ngOnDestroy() {
  }

  isEmailValid(): boolean {
    return !this.emailFormControl.hasError('email') && !this.emailFormControl.hasError('required');
  }

  onSend(): void {
    this.isWorking = true;
    console.log(this.voucherId);
    switch (this.action) {
      case "send_receipt":
        this.api.sendReceiptPdf(this.venueId, this.receiptKey, this.email, this.saveEmail).subscribe(r => {
          console.log(`Receipt pdf successfully sent to ${this.emailFormControl.value}`);
          this.isWorking = false;
          this.dialogRef.close();
        }, error => {
          this.isWorking = false;
        });
        break;
      case "send_voucher":
        this.api.sendVoucherPdf(this.venueId, this.voucherId, this.email).subscribe(r => {
          console.log(`Receipt pdf successfully sent to ${this.emailFormControl.value}`);
          this.isWorking = false;
          this.dialogRef.close();
        }, error => {
          this.isWorking = false;
        });
        break;
      case "send_all_vouchers":
        this.api.sendAllVoucherPdfs(this.venueId, this.receiptKey, this.email).subscribe(r => {
          console.log(`Receipt pdf successfully sent to ${this.emailFormControl.value}`);
          this.isWorking = false;
          this.dialogRef.close();
        }, error => {
          this.isWorking = false;
        });
        this.dialogRef.close();
        break;
      case "send_giftcard":
        this.api.sendGiftcard(this.venueId, this.postId, this.email).subscribe(r => {
          console.log(`Griftcard successfully sent to ${this.emailFormControl.value}`);
          this.isWorking = false;
          this.dialogRef.close();
        }, error => {
          this.isWorking = false;
        });
        break;
      default:
        this.dialogRef.close();
    }
  }

  getString(s: string) {
    return this.translate.single(s);
  }

}
