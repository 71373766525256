import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FireService } from '../../services/fire.service';
import { FSPayment } from '../../models/PaymentModels';
import MoneyUtils, { HackUtils } from '../../utils/Utils';
import { combineLatest, from, Subscription } from 'rxjs';
import { OrderService } from '../../services/order.service';
import * as moment from 'moment';
import { delay, first } from 'rxjs/operators';
import { MessengerService } from '../../services/messenger.service';
import { AuthService } from '../../services/auth.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AuthorizedApiService} from "../../services/authorized-api.service";
import {environment} from "../../../environments/environment";
import {TranslateService} from "../../services/translate.service";
import {SwishNumberDialogComponent} from "./swish-number-dialog/swish-number-dialog.component";

@Component({
  selector: 'app-pay-status',
  templateUrl: './pay-status.component.html',
  styleUrls: ['./pay-status.component.css']
})
export class PayStatusComponent implements OnInit, OnDestroy {
  isLoading = true;
  private receiptKey: string;
  payment: FSPayment;
  typeImage: string;
  amountFormatted: string;
  // Viva callbacks are not guaranteed to be received in the demo environment, let the user manually click a button to check
  vivaDemoIssue = false;
  private paySub: Subscription;
  private combinedSub: Subscription;
  private timeSub: Subscription;
  swishQR: string;
  private venueId = this.auth.venueId;
  private isKiosk: boolean;
  private vivaParamOrderCode: string;
  private vivaParamTxId: string;
  payWithNumber = false;
  noticeSwishAuto = false;
  private state;
  showInfo = false;

  constructor(private route: ActivatedRoute, private fire: FireService,
              private order: OrderService, private messengerService: MessengerService,
              private auth: AuthService, private appState: AppStateService,
              private dialog: MatDialog, private router: Router,
              private snackbar: MatSnackBar, private apiService: AuthorizedApiService,
              public translate: TranslateService) { }

  ngOnInit(): void {
    this.isKiosk = this.auth.isKioskMode();
    console.log("PayStatusComponent init");

    // Important to subscribe to app state here, so that magic sign in
    // stuff can happen before listening to payment status
    this.combinedSub = combineLatest([
      this.route.paramMap,
      this.route.queryParamMap,
      this.appState.observeAppState()]
    ).pipe(first()).subscribe(res => {

      this.state = res[2];

      this.vivaParamOrderCode = res[1].get("s");
      this.vivaParamTxId = res[1].get("t");
      if (this.vivaParamOrderCode && this.vivaParamTxId) {
        setTimeout(() => {
          this.vivaDemoIssue = true;
        }, environment.viva === "demo" ?4000:10000);
        console.log("Redirected from viva payment");
        this.resolveReceiptKey(this.vivaParamOrderCode, this.vivaParamTxId);
      } else {
        this.receiptKey = res[0].get("receipt_key");
        this.beginObserving();
      }
    });
  }

  private updateSwishNotice() {
    if (this.payWithNumber) {
      // Payment is already in progress, do not show the notice again
      return;
    }

    const messengerSDKInfo = this.messengerService.getMessengerState();
    const sdkLoaded = messengerSDKInfo === "IN_MESSENGER";
    const isMessenger = this.state.order.user_cfg.user_type === "messenger";
    const isIphone = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if ((isMessenger || sdkLoaded) && isIphone) {
      this.isLoading = false;
      this.noticeSwishAuto = true;
    }
  }

  private beginObserving() {
    this.paySub = this.fire.observePayment(this.receiptKey).subscribe(payment => {
      console.log(payment);
      if (payment == null) {
        console.log(`Payment ${this.receiptKey} not found...`);
      } else if (payment.state === 'new') {
        this.payment = payment;
        if (payment.type==='swish') {
          this.updateSwishNotice();
        }
        this.typeImage = "https://storage.googleapis.com/heynow/media/" + (this.payment.type === "card" ? "mc_visa.png" : "swish_open_24.png");
        this.amountFormatted = MoneyUtils.formatCent(payment.amount_cent);
        if (this.payment.type === "swish") {
          this.checkSwishRequestAge();
          if (this.auth.kioskMode) {
            this.showSwishQR();
          }
        }
      } else if (payment.state === 'paid') {
        if(this.isKiosk) {
          this.fetchOrderAndExit("menu/food");
          this.openReceiptAskDialog(this.receiptKey);
        } else {
          this.fetchOrderAndExit();
        }
      } else {
        if (this.isKiosk) {
          this.fetchOrderAndExit("cart");
          this.snackbar.open("Betalningen misslyckades", "OK", { duration: 5000 });
        } else {
          this.returnToSender();
        }
      }
    });
  }

  private fetchOrderAndExit(urlNotMessenger = "feed") {
    this.timeSub?.unsubscribe();
    this.order.fetchCurrentOrder().subscribe(o => {
      this.messengerService.doPostAction(this.auth.venueId, "cart_paid", urlNotMessenger);
    });
  }

  cancel() {
    this.fetchOrderAndExit("cart");
  }

  ngOnDestroy(): void {
    this.combinedSub?.unsubscribe();
    this.paySub?.unsubscribe();
    this.timeSub?.unsubscribe();
  }

  showSwishQR() {
    this.swishQR = this.payment.qr;
    console.log(this.swishQR);
  }

  private checkSwishRequestAge() {
    const diff = moment().diff(moment(this.payment.date.toDate()), "seconds");
    if (diff < 3 * 60) {
      const msLeft = (3 * 60 - diff) * 1000;
      console.log(`Swish will timeout in ${msLeft} ms`);
      this.timeSub = from([1]).pipe(delay(msLeft)).subscribe(r => this.fetchOrderAndExit("cart"));
    } else {
      console.log(`Swish age ${diff} sec`);
      this.fetchOrderAndExit("cart");
    }
  }

  private returnToSender() {
    this.messengerService.doPostAction(this.auth.venueId, "payment_failed");
  }

  private openReceiptAskDialog(rkey: string) {
    const dialogRef = this.dialog.open(SimpleDialogComponent, HackUtils.DLG({
      minWidth: "95vw",
      position: { top: "8px" },
      data: {
        title: "Tack för ditt köp!",
        message: "Önskas ett kvitto?",
        posBtn: "Ja",
        negBtn: "Nej",
      }
    }));

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigateByUrl(`${this.venueId}/receipt/${rkey}`);
      } else {
        this.router.navigateByUrl(`${this.venueId}/menu/food`);
      }
    });
  }

  private resolveReceiptKey(orderCode: string, transactionId: string) {
    this.apiService.vivaResolveReceiptKey(orderCode, transactionId).subscribe(res => {
      if (res) {
        this.receiptKey = res.receipt_key;
        this.beginObserving();
      } else {
        this.router.navigateByUrl(`${this.venueId}/feed`);
      }
    });
  }

  forceVivaTxCheck() {
    this.apiService.vivaCheckOrderStatus(this.vivaParamOrderCode, this.vivaParamTxId).subscribe(res => {
      // if transaction is paid it will be updated, and the read in the observer
    });
  }

  showSwishPhone() {
    const dialogRef = this.dialog.open(SwishNumberDialogComponent, HackUtils.DLG({
      data: {
        phone: this.state.order.user_cfg.swish_number
      }
    }));

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        const phoneNumber = result.phone;
        const savePhone = result.savePhone;
        this.isLoading = true;
        this.apiService.swishLink(0, undefined, this.receiptKey, undefined, undefined, undefined, undefined, phoneNumber, savePhone ).subscribe(res => {
          this.noticeSwishAuto = false;
          this.payWithNumber = true;
        }, error => {
          console.log(error.error);
          const message = error.error?.message || "Det gick inte att påbörja swish-betalning";
          this.isLoading = false;
          this.snackbar.open(message, "OK", { duration: 10000 });
        } );
      }
    });
  }

  getString(s: string, pc: string = "normal") {
    return this.translate.single(s, pc);
  }

  setShowInfo(event: Event): void {
    event.preventDefault(); // Prevent the default link behavior
    this.showInfo = true;
  }
}
