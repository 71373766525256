import {MenuItem} from "../models/FSMenu";
import {UserSpecificConfig} from "../models/Order";

export class ICParentLookup {
  private data: {[key: string]: Set<string>};

  constructor(data: {[key: string]: Set<string>}) {
    this.data = data;
  }

  isChildOf(parent: string, child: string): boolean {
    return true;
  }
}

export class Filter {
  private expressions: FilterExpression[];
  private cfg: UserSpecificConfig;
  private lookup: ICParentLookup;

  constructor(expressions: FilterExpression[], cfg?: UserSpecificConfig, lookup?: ICParentLookup) {
    this.expressions = expressions;
    this.cfg = cfg;
    this.lookup = lookup;
  }

  itemMatches<T>(item: MenuItem): boolean {
    for (const expr of this.expressions) {
      if (expr.matches(item, this.cfg, this.lookup)) {
        return true;
      }
    }
    return false;
  }
}

export class FilterExpression {
  public terms: FilterTerm[];

  constructor(terms: FilterTerm[]) {
    this.terms = terms;
  }

  matches(item: MenuItem, cfg?: UserSpecificConfig, ic?: ICParentLookup): boolean {
    for(const term of this.terms) {
      let isMatch = false;
      switch (term.type) {
        case 'empty':
          break;
        case 'ic':
          // const itemIc = item.ic;
          // isMatch = term.ic.some( t => t === "*" || ic.isChildOf(t, itemIc) );
          break;
        case 'tag':
          const tags = item.tags;
          isMatch = term.tags.some( t => tags?.some( t2 => t2 === t));
          break;
        case 'phase':
          isMatch = term.phases.some( t => t === item.phaseid);
          break;
        case 'area':
          isMatch = term.areas.has(cfg?.area);
          break;
        case 'table':
          isMatch = term.tables.has(cfg?.table);
          break;
        case 'device':
          //isMatch = term.devices.has(accessor.itemDevice(item, props));
          break;
        case 'dt':
          console.log(cfg);
          isMatch = term.deliveryTypes.has(cfg.dt);
          break;
        case 'ordertag':
          // const orderTags = item.order_tags;
          // isMatch = term.orderTags?.some( t => orderTags?.some( t2 => t2 === t));
          break;
        case 'attribute':
          const attributes = item.attributes?.map( a => a.id.toLowerCase());
          isMatch = term.attributes?.some ( a => attributes?.some(a2 => a2 === a));
          break;
        default:
          break;
      }
      if( (isMatch && !term.include) || (!isMatch && term.include) ) {
        return false;
      }
    }
    return true;
  }
}

export type FilterTerm =
    | { type: "empty", include: boolean }
    | { type: "ic", ic: string[], include: boolean }
    | { type: "tag", tags: string[], include: boolean }
    | { type: "phase", phases: string[], include: boolean }
    | { type: "area", areas: Set<string>, include: boolean }
    | { type: "table", tables: Set<string>, include: boolean }
    | { type: "device", devices: Set<string>, include: boolean }
    | { type: "dt", deliveryTypes: Set<string>, include: boolean }
    | { type: "attribute", attributes: string[], include: boolean }
    | { type: "ordertag", orderTags: string[], include: boolean };

const expressionRegex = /[+-][^+-]+/g;
const filterTermRegex = /([+-])([^=]+)=?(.+)?/;

export class FilterLogic {

  static parseFilterExpressions(filterDefinition: string): FilterExpression[] {
    return filterDefinition.split(",").map(expr =>
      FilterLogic.buildFilterExpression(expr)
    );
  }

  static buildFilterExpression(filterItemDefinition: string): FilterExpression {
    const exp = !filterItemDefinition.startsWith("-") && !filterItemDefinition.startsWith("+") ? "+" + filterItemDefinition : filterItemDefinition;
    const matches = exp.match(expressionRegex);
    const terms = matches ? matches.map(value => FilterLogic.buildFilterTerm(value)) : [];
    return new FilterExpression(terms);
  }

  static buildFilterTerm(trm: string): FilterTerm {
    const match = filterTermRegex.exec(trm);
    if (match?.length === 4) {
        const pm = match[1];
        const include = pm === "+";
        const key = match[2];
        const value = match[3] ? match[3] : "";

        switch (key) {
            case "ic": return { type: "ic", ic: value.split("|"), include };
            case "tag": return { type: "tag", tags: value.split("|"), include };
            case "phase": return { type: "phase", phases: value.split("|"), include };
            case "area": return { type: "area", areas: new Set(value.split("|")), include };
            case "table": return { type: "table", tables: new Set(value.replace("_", " ").split("|")), include };
            case "device": return { type: "device", devices: new Set(value.split("|")), include };
            case "dt": return { type: "dt", deliveryTypes: new Set(value.split("|")), include };
            case "attribute": return { type: "attribute", attributes: value.split("|").map(val => val.toLowerCase()), include };
            case "ordertag": return { type: "ordertag", orderTags: value.split("|"), include };
            default: return { type: "empty", include: true };
        }
    } else {
        return { type: "empty", include: true };
    }
  }
}
