import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  constructor(public authService: AuthService, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  getString(s: string) {
    return this.translate.single(s);
  }
}
