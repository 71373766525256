<mat-dialog-content class="item-dialog mat-typography">

  <div class="item-header">
    <div class="item-title">Ange {{roomHint}}</div>
  </div>

  <div class="item-content webcode-content">
    <div style="margin-top: 16px;margin-bottom: 16px;">
      <input [disabled]="sending" type="text" class="webcode-input" [(ngModel)]="room" placeholder="{{roomPlaceholder}}" autofocus style="text-align: center;">
    </div>
  </div>

  <div class="button-list" style="padding: 16px;margin-top: 0px;">
    <button mat-stroked-button mat-dialog-close>Stäng</button>
    <mat-spinner class="add-item-spinner" *ngIf="sending" [diameter]="32"></mat-spinner>
    <button [disabled]="sending" mat-raised-button color="accent" (click)="selected()">OK</button>
  </div>

</mat-dialog-content>
