import { AfterViewChecked, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MenuItem, MenuItemPush, MenuStructure } from '../../models/FSMenu';
import { MenuService } from '../../services/menu.service';
import { OrderService } from '../../services/order.service';
import { HackUtils, MenuUtils } from '../../utils/Utils';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-push-dialog',
  templateUrl: './push-dialog.component.html',
  styleUrls: ['./push-dialog.component.css']
})
export class PushDialogComponent implements OnInit, OnDestroy, AfterViewChecked {
  item: MenuItem;
  parentRID: string;
  pushIndex: number;
  push: MenuItemPush;
  isWorking = false;
  selectedItems = {};
  attrValues = {};
  itemIncluded = false;
  itemIncludedNotSelected = false;
  private menu: MenuStructure;

  private scrollHack = { count: 0 };
  private sub: Subscription;
  ngAfterViewChecked(): void {
    /*
      Scroll hack. To make the push dialog align at the top of the window at start.
      Angular material dialog is placing the dialog incorrectly on ios devices.
      This code will force the scroller to the top the first initial viewchecked events.
      Usually the dialog has it final initial size after 3-4 events, and usually happens after about 200ms.
      TODO: This is a shady scroll hack that will break. Find a better solution.
     */
    if (HackUtils.shouldScroll(this.scrollHack)) {
      const elements = document.getElementsByClassName("cdk-global-overlay-wrapper");
      console.log("cdk-global-overlay-wrapper.scrollTop = ", elements[0].scrollTop);
      elements[0].scrollTop = 0;
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<PushDialogComponent>,
              public dialog: MatDialog, private menuService: MenuService, private auth: AuthService,
              private orderService: OrderService, private translate: TranslateService) {
    this.item = data.item;
    this.pushIndex = data.pushIndex;
    this.parentRID = data.rid;
    console.log(this.parentRID);
    console.log(`Open push dialog index: ${this.pushIndex}`);
  }

  ngOnInit(): void {
    const pi = this.item.pushes[this.pushIndex];
    this.push = { index: pi.index, title: pi.title, groups: [] } as MenuItemPush;
    const pushes = this.item.pushes[this.pushIndex];

    if (pi.included) {
      this.itemIncluded = true;
      this.itemIncludedNotSelected = true;
    }

    const venueId = this.auth.venueId;
    this.sub = this.menuService.observeUnfilteredMenu(venueId).subscribe(menu => {
      this.push = { index: pi.index, title: pi.title, groups: [] } as MenuItemPush;
      this.menu = menu;
      let count = 0;
      for (const group of pushes.groups) {
        const menuItems = group.items.map(it => MenuUtils.getItemInMenu(it, menu)).filter(it => it !== null && !this.menuService.isOutOfStock(it.id) && !this.isAlcoholButNotAllowed(it));
        for (const item of menuItems) {
          this.selectedItems[item.id] = false;
          count++;
        }
        if (menuItems.length > 0) {
          this.push.groups.push({ title: group.title, menuItems, items: group.items });
        }
      }

      if (count === 0) {
        this.continue();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  continue() {
    console.log("cont...");

    const itemIds = MenuUtils.collectSelectedItemIds(this.selectedItems, this.attrValues, this.menu);
    if (itemIds.length > 0) {
      this.addItemsToOrder(itemIds);
    } else {
      this.next();
    }
  }

  openNextPushDialog() {
    const dialogRef = this.dialog.open(PushDialogComponent, HackUtils.DLG({
      data: { item: this.item, rid: this.parentRID, pushIndex: this.pushIndex + 1 }
    }));
  }

  selectedChange(selectedItem: MenuItem) {
    if (this.itemIncluded) {
      const cur = this.selectedItems[selectedItem.id];
      for (const key of Object.keys(this.selectedItems)) {
        this.selectedItems[key] = false;
      }
      this.selectedItems[selectedItem.id] = cur;
      this.itemIncludedNotSelected = !cur;
    }

    // Setup default attribute values
    this.attrValues[selectedItem.id] = {};
    for (const atr of selectedItem.attributes ?? []) {
      if (!this.attrValues[selectedItem.id][atr.id]) {
        const opt = atr.options.find(o => o.default) ?? (atr.options.length > 1 ? atr.options[0] : undefined);
        if (opt) {
          this.attrValues[selectedItem.id][atr.id] = opt.key;
        }
      }
    }
  }

  addItemsToOrder(itemIds) {
    this.isWorking = true;
    const inc = this.itemIncluded === true ? true : undefined;
    this.orderService.addItemsToOrder(itemIds, this.parentRID, inc).subscribe(ord => {
      this.next();
    });
  }

  private next() {
    this.dialogRef.close();
    if (this.pushIndex < this.item.pushes.length - 1) {
      this.openNextPushDialog();
    }
  }

  private isAlcoholButNotAllowed(it: MenuItem) {
    const isTakeaway = this.orderService.getOrderOrNull()?.user_cfg?.dt === "takeaway";
    return isTakeaway && it.alc;
  }

  getString(s: string, postCase = "normal") {
    return this.translate.single(s, postCase);
  }

  getItemPriceFormatted(item: MenuItem) {
    return this.orderService.getPriceForItem(item);
  }
}
