import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrderService} from "../../services/order.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-generic-info-dialog',
  templateUrl: './generic-info-dialog.component.html',
  styleUrls: ['./generic-info-dialog.component.css']
})
export class GenericInfoDialogComponent implements OnInit {

  title: string;
  type: string;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<GenericInfoDialogComponent>, private order: OrderService, private snackbar: MatSnackBar) {
    this.title = data.title;
    this.type = data.type;
  }

  ngOnInit(): void {
  }
}
