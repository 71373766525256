<mat-dialog-content class="item-dialog mat-typography" style="max-height: 80vh;">

  <div class="item-header">
    <div class="item-title">Swishbetalning</div>
  </div>

  <div class="item-content">
    <div class="info-form">
      <mat-form-field class="info-full-width">
        <mat-label>{{getString("Ditt mobilnummer")}}</mat-label>
        <input #phoneInput matInput [formControl]="phoneFormControl" placeholder="073 225811" type="tel" [(ngModel)]="phone">
      </mat-form-field>

      <mat-checkbox class="clickable-area" [(ngModel)]="savePhone" *ngIf="!isKiosk">
        <div>{{getString("Spara nummer")}}</div>
      </mat-checkbox>
    </div>
  </div>

  <div class="button-list">
    <button mat-stroked-button mat-dialog-close>{{getString("Stäng")}}</button>
    <mat-spinner class="add-item-spinner" *ngIf="isWorking" [diameter]="32"></mat-spinner>
    <button [disabled]="isWorking || !isValid()" mat-raised-button color="accent" (click)="onSend()">{{getString("Betala")}}</button>
  </div>

</mat-dialog-content>
