<div class="payment-stat-cont">
  <div *ngIf="payment" style="margin-top: auto;">
    <img  src="{{typeImage}}">
  </div>
  <div *ngIf="payment" style="margin-bottom: 48px;margin-top: 16px;font-weight: bold;font-size: 15px;">{{amountFormatted}}</div>
  <div *ngIf="payment && swishQR">
    <img class="qrImage" [src]="swishQR">
  </div>
  <div *ngIf="noticeSwishAuto && !payWithNumber" class="notice-swish-auto">
    <div class="inst">NOTIS!</div>
    <div class="inst">Swish kan inte längre öppnas automatiskt från messenger.</div>
    <div class="inst"><b>Välj betala med telefonnummer</b></div>
    <div class="inst">eller avbryt och betala med kort istället.</div>
    <div *ngIf="!showInfo" class="more_info"><a href="#" (click)="setShowInfo($event)" style="color: white;">Mer info</a></div>
    <div *ngIf="showInfo">
      <div class="inst">Info:</div>
      <div class="inst">För närvarande kan vi inte öppna andra applikationer, som Swish, automatiskt från Messenger på iPhone. Detta är tyvärr en begränsning i Messenger-appen och inget som betalleverantören kan lösa.</div>
      <div class="inst">Vi rekommenderar att du istället använder webbversionen, som du når genom att skanna QR-koden.</div>
    </div>
  </div>

  <div *ngIf="payWithNumber" class="pay-swish-phone-panel">
    <div class="inst">1. Öppna Swish-appen</div>
    <div class="inst">2. Genomför betalning</div>
    <div class="inst">3. Återgå till denna sida</div>
  </div>
  <mat-spinner *ngIf="isLoading" [diameter]="48"></mat-spinner>

  <div *ngIf="payment" style="margin-top: auto;margin-bottom: 32px;">
    <div style="display: flex; flex-direction: column; align-content: center; align-items: center;">
      <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
        <div>
          <button class="panel-with-border" *ngIf="payment.type==='swish' && !swishQR && !payWithNumber" mat-button (click)="showSwishPhone()">Betala med telefonnummer</button>
        </div>
        <div style="margin-top: 16px;">
          <button class="panel-with-border" *ngIf="payment.type==='swish' && !swishQR && !payWithNumber" mat-button (click)="showSwishQR()">Betala med annan enhet</button>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <button mat-button (click)="cancel()">Avbryt</button>
      </div>
    </div>
  </div>

  <div *ngIf="vivaDemoIssue" style="margin-top: 128px;;margin-bottom: 32px;">
    <button mat-button (click)="forceVivaTxCheck()">Tar längre tid än vanligt, kontrollera igen.</button>
  </div>

</div>
