import { Component, OnInit } from '@angular/core';
import {OrderService} from '../../services/order.service';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "../../services/auth.service";
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-web-code-dialog',
  templateUrl: './web-code-dialog.component.html',
  styleUrls: ['./web-code-dialog.component.css']
})
export class WebCodeDialogComponent implements OnInit {
  webcode = "";
  error = false;
  sending = false;
  lastTestedCode: string;

  constructor(private order: OrderService, private dialogRef: MatDialogRef<WebCodeDialogComponent>, private snackBar: MatSnackBar,
              private auth: AuthService, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  onKeyup(event: KeyboardEvent) {
    let code = this.webcode.toUpperCase();
    if (code.length > 5) {
      code = code.substring(0, 5);
    }
    this.webcode = code;
    console.log(this.webcode);
    this.error = false;
    if (code === "XKIOX") {
      return this.activateKioskMode();
    }
    if (code.length === 5 && this.lastTestedCode !== code) {
      this.sending = true;
      console.log(`Changing to code: ${code}`);
      this.lastTestedCode = code;
      this.order.changeTable(code).subscribe( r => {
        console.log("Changed table successfully");
        this.dialogRef.close(true);
      }, err => {
        console.log("Error");
        this.error = true;
        this.sending = false;
      });
    }
  }

  private activateKioskMode() {
    const userCfg = this.order.getOrderOrNull()?.user_cfg;
    if (userCfg?.dt === "table" && !userCfg?.must_scan && userCfg?.is_anon) {
      this.auth.activateKioskMode().subscribe( r => {
        this.dialogRef.close(true);
        const sn = this.snackBar.open("Kiosk mode activate (wait...)", "OK", {duration: 3000});
        sn.afterDismissed().subscribe(() => {
          location.reload();
        });
      });
    } else {
      if (!userCfg?.is_anon) {
        this.snackBar.open("Cannot enter kiosk mode while logged in", "OK", {duration: 5000});
      } else {
        this.snackBar.open("Kiosk mode only available after you have selected a table", "OK", {duration: 5000});
      }
    }
  }

  getString(s: string, pc="normal") {
    return this.translate.single(s, pc);
  }
}
