import {Component, ElementRef, OnInit} from '@angular/core';
import { UrlHistoryService } from '../../services/url.history.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Giftcard } from '../../models/Order';
import MoneyUtils, { HackUtils } from '../../utils/Utils';
import {ReceiptEmailDialogComponent} from "../receipt/receipt-email-dialog/receipt-email-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MessengerService} from "../../services/messenger.service";
import {OrderService} from "../../services/order.service";
import {ConfigService} from "../../services/config.service";

@Component({
  selector: 'app-external-giftcard',
  templateUrl: './external-giftcard.component.html',
  styleUrls: ['./external-giftcard.component.css']
})
export class ExternalGiftcardComponent implements OnInit {
  isLoading = true;
  postId: string;
  venueId: string;
  giftcard: Giftcard;
  lastFour: string;
  receiverName: string;
  senderName: string;
  message: string;
  amount: string;
  reciverLayout: boolean;
  isMobile: boolean;
  isMobileiOS: boolean;
  inMessenger: boolean;
  showQRCode: boolean;
  smallDLLink: boolean;
  showButton: string;
  showDLLink: boolean;

  constructor(private identityService: UrlHistoryService, private api: ApiService, private route: ActivatedRoute, private dialog: MatDialog, private messengerService: MessengerService, private orderService: OrderService, private configService: ConfigService) { }

  ngOnInit(): void {
    console.log("ExternalGiftcardComponent init");
    this.route.data.subscribe(data => {
      this.reciverLayout = data.receiver_layout ?? false;
    });
    this.route.paramMap.subscribe(data => {
      this.isMobile = HackUtils.isMobile();
      this.isMobileiOS = HackUtils.isMobileiOS();
      this.inMessenger = this.messengerService.inMessenger();

      /*
        BUYER LAYOUT
        1. For web and desktop => show download button
        2. For web and mobile and ios => show sms button, show download link
        3. For web and mobile and not ios => show download button
        4. For messenger and ios => show sms button
        5. For messenger and not ios => show download button
        For all show email link

        RECEIVER LAYOUT
        For all but messenger ios => show download link
        For all show email link
       */

      if (this.reciverLayout) {
        this.showButton = null;
        this.showDLLink = true;
      } else {
        this.showButton = this.isMobileiOS ? "sms" : "download";
        this.showDLLink = !this.inMessenger && this.isMobileiOS;
      }

      this.smallDLLink = this.reciverLayout || this.isMobile;
      this.postId = data.get("post_id");
      this.venueId = data.get("venue_id");

      this.configService.getNonEmptyConfig().subscribe(config => {
        this.loadGiftcard();
      });
    });
  }

  loadGiftcard(): void {
    this.api.getGiftcard(this.venueId, this.postId).subscribe(gc => {
      this.isLoading = false;
      console.log("Got giftcard", gc);
      this.giftcard = gc;
      this.amount = MoneyUtils.format(gc.amount);
      if (gc.identity?.length > 3) {
        this.lastFour = gc.identity.substring(gc.identity.length - 4);
      }
      if (gc.spec.message) {
        this.message = gc.spec.message;
      }
      if (gc.spec.receiver_name) {
        this.receiverName = `${gc.spec.receiver_name}`;
      }
      if (gc.spec.sender_name) {
        this.senderName = `${gc.spec.sender_name}`;
      }
    });
  }

  backClicked(): void {
    this.identityService.navigateBack();
  }

  sendInternalSMS() {
    let m = this.message ?? "Hej här kommer en överraskning!";
    if (this.senderName) {
      m += ` Hälsningar ${this.senderName}!`;
    }
    const link = `sms:&body=${m} ${this.giftcard.web_link}`;
    window.location.replace(link);
  }

  download() {
    // window.location.replace(this.giftcard.pdf_link);
    window.open(this.giftcard.pdf_link, '_blank');
    return false;
  }

  showQR() {
    this.showQRCode = !this.showQRCode;
  }

  sendEmail() {
    const dialogRef = this.dialog.open(ReceiptEmailDialogComponent, HackUtils.DLG({
      data: {
        action: "send_giftcard",
        title: "Skicka presentkort",
        venueId: this.venueId,
        postId: this.postId,
        receipt_mail: this.orderService.getOrderOrNull()?.user_cfg.receipt_mail
      }
    }));
    return false;
  }
}
