import { Injectable } from '@angular/core';
import { Observable, fromEventPattern, merge } from 'rxjs';
import { map, startWith, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  public visibilityChange$: Observable<boolean>;

  constructor() {
    this.visibilityChange$ = merge(
      fromEventPattern(
        handler => document.addEventListener('visibilitychange', handler),
        handler => document.removeEventListener('visibilitychange', handler)
      ),
      fromEventPattern(
        handler => window.addEventListener('focus', handler),
        handler => window.removeEventListener('focus', handler)
      ),
      fromEventPattern(
        handler => window.addEventListener('blur', handler),
        handler => window.removeEventListener('blur', handler)
      )
    ).pipe(
      startWith(this.checkVisibility()),
      map(() => this.checkVisibility()),
      distinctUntilChanged()
    );
  }

  private checkVisibility(): boolean {
    return document.visibilityState === 'visible';
  }
}
