import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { MatDialogRef } from '@angular/material/dialog';
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-enter-discount-code-dialog',
  templateUrl: './enter-discount-code-dialog.component.html',
  styleUrls: ['./enter-discount-code-dialog.component.css']
})

export class EnterDiscountCodeDialogComponent implements OnInit {
  code = "";
  error = false;
  errorMessage: string;
  sending = false;
  lastTestedCode: string;

  constructor(private order: OrderService, private dialogRef: MatDialogRef<EnterDiscountCodeDialogComponent>,
              public translate: TranslateService) { }

  ngOnInit(): void {
  }
  onInput(event: Event): void {
    this.handleCodeInput();
  }

  onKeyup(event: KeyboardEvent): void {
    this.handleCodeInput();
  }

  handleCodeInput(): void {
    let code = this.code.toUpperCase();
    if (code.length > 6) {
      code = code.substring(0, 6);
    }
    this.code = code;
    this.error = false;
    if (code.length === 6 && this.lastTestedCode !== code) {
      this.error = false;
      this.sending = true;
      console.log(`Changing to code: ${code}`);
      this.lastTestedCode = code;
  
      this.order.addDiscountCode(code).subscribe(
        (r) => {
          console.log("Added discount successfully");
          this.error = true;
          this.dialogRef.close();
        },
        (err) => {
          console.log("Error");
          this.error = true;
          if (err.error.message === "invalid code") {
            this.errorMessage = "Okänd rabattkod angiven";
          } else if (err.error.message === "not active code") {
            this.errorMessage = "Rabattkod utgången eller förbrukad";
          } else if (err.error.message === "max_users") {
            this.errorMessage = "Rabattkod fullt utnyttjad";
          } else if (err.error.message === "missing_discount") {
            this.errorMessage = "Kod har fel rabattprogram";
          } else {
            this.errorMessage = "Okänt fel inträffade";
          }
          this.sending = false;
        }
      );
    }
  }

}
