import {AfterViewChecked, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Order, OrderItem} from '../../models/Order';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MenuItem, PostActions} from '../../models/FSMenu';
import {OrderService} from '../../services/order.service';
import { Observable } from 'rxjs';
import {PushDialogComponent} from '../push-dialog/push-dialog.component';
import {HackUtils, MenuUtils} from '../../utils/Utils';
import {isKitchenOpen} from '../../models/FSConfig';
import {ConfigService} from '../../services/config.service';
import {CollectInfoDialogComponent} from "../collect-info-dialog/collect-info-dialog.component";
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-menu-item-dialog',
  templateUrl: './menu-item-dialog.component.html',
  styleUrls: ['./menu-item-dialog.component.scss']
})
export class MenuItemDialogComponent implements OnInit, OnDestroy, AfterViewChecked {
  item: MenuItem;
  orderItem: OrderItem;
  outOfStock: boolean;
  hasPostAction: boolean;
  isWorking = false;
  attrValues = {};
  selCons = {};
  selAdjs = {};
  tweaksOpen = false;
  tweakTitle: string;
  showTweak = false;
  cartPage = true;
  kitchenOpen = true;
  venueOpen = true;
  prepTime: number;
  showWhen = false;
  readableWhen: string;
  priceFormatted: string;

  private scrollHack = {count: 0};
  private postActionData: any;

  ngAfterViewChecked(): void {
    /*
      Scroll hack. To make the push dialog align at the top of the window at start.
      Angular material dialog is placing the dialog incorrectly on ios devices.
      This code will force the scroller to the top the first initial viewchecked events.
      Usually the dialog has it final initial size after 3-4 events, and usually happens after about 200ms.
      TODO: This is a shady scroll hack that will break. Find a better solution.
     */
    //console.log("ngAfterViewChecked", this.checkedCount, moment().valueOf());
    if (HackUtils.shouldScroll(this.scrollHack)) {
      const elements = document.getElementsByClassName("cdk-global-overlay-wrapper");
      console.log("cdk-global-overlay-wrapper.scrollTop = ", elements[0].scrollTop);
      elements[0].scrollTop = 0;
    }
  }

  constructor(private orderService: OrderService, @Inject(MAT_DIALOG_DATA) data,
              private dialogRef: MatDialogRef<MenuItemDialogComponent>,
              private dialog: MatDialog, private config: ConfigService, private translate: TranslateService) {
    console.log("MenuItemDialogComponent init", data);
    this.item = data.item;
    this.priceFormatted = this.orderService.getPriceForItem(this.item);
    translate.item(this.item);
    this.hasPostAction = this.item.post_actions?.length > 0 ?? false;
    this.cartPage = data.cartPage;
    this.outOfStock = data.outOfStock;
    this.orderItem = data.orderItem;
    this.venueOpen = data.venueOpen;
    this.showWhen = data.showWhen;
    if (this.showWhen) {
      this.readableWhen = MenuUtils.readableWhenDeadline(this.item);
    }
    console.log(this.item);
  }

  ngOnInit(): void {
    this.setupDeafultAttributeValues();
    const hasAdjusts = this.item.adjusts?.length > 0 ?? false;
    this.showTweak = hasAdjusts || (this.item.contains?.length > 0 ?? false);
    const canBeTweaked = hasAdjusts || (this.item.contains?.filter(con => con.del)?.length > 0 ?? false);
    this.tweakTitle = this.getString("Anpassa rätten (allergier och innehåll)");

    // Update kitchenOpen for food items
    if (this.item.form === "f") {
      const cfg = this.config.getSnapshot();
      this.kitchenOpen = isKitchenOpen(cfg);
      // Should we show item cooking time?
      const dt = MenuUtils.getDeliverTypeConfig(this.orderService.getOrderOrNull()?.user_cfg?.dt, cfg);
      console.log("DT:",dt);
      if (dt) {
        if (dt.show_time) {
          this.prepTime = dt.prep_time;
          console.log("dt.prep_time",dt.prep_time);
        }
      }
    }
  }

  private setupDeafultAttributeValues() {
    if (this.item.attributes) {
      for (const atr of this.item.attributes) {
        const opt = atr.options.find(o => o.default) ?? (atr.options.length > 1 ? atr.options[0] : undefined);
        if (opt) {
          this.attrValues[atr.id] = opt.key;
        }
      }
    }
  }

  addNewItem() {
    const pas = new PostActions(this.item.post_actions);
    const giftcard = pas.getPostAction("giftcard");
    if (giftcard) {
      this.collectGiftCardInfo();
      return;
    }
    let comment = this.buildComment(undefined);
    if (this.translate.isActive() && comment!=null) {
      const sv = this.buildComment("sv");
      comment += " (" + sv + ")";
    }
    this.addItemNextStep(comment);
  }

  addItemNextStep(comment) {
    const fullItemId = MenuUtils.buildFullItemId(this.item, this.attrValues);
    this.add(fullItemId, comment, true, this.postActionData, undefined);
  }

  addCopy() {
    this.add(this.orderItem.id, undefined, false, undefined, this.orderItem.rid);
  }

  private add(fullItemId: string, comment: string, addNewItem: boolean, postActionData?: any, sameAsRid?: string) {
    this.isWorking = true;
    console.log(`onAdd: ${fullItemId} comment: ${comment}`);
    const uid = Math.random().toString(36).substring(7);
    this.orderService.addItemToOrder(fullItemId, comment, uid, postActionData, sameAsRid).subscribe((order: Order) => {
        if (addNewItem) {
          const rid = order.add_item_result?.last_rid;
          this.postAddNew(rid);
        }
      },
      undefined,
      () => {
        this.isWorking = false;
      }
    );
  }

  subtractCopy() {
    this.isWorking = true;
    console.log(`onSub: ${this.orderItem.id} ${this.orderItem.package != null}`);
    this.orderService.removeItemFromOrder(this.orderItem.id, this.orderItem.package != null).subscribe((order: Order) => {
        if ( this.orderService.getCount(this.orderItem.id, true, this.orderItem.package != null) === 0) {
          this.dialogRef.close();
        }
      },
      undefined,
      () => {
        this.isWorking = false;
      }
    );
  }

  buildComment(lang: string): string | undefined {
    const strs = [];
    Object.keys(this.selCons).forEach( s => {
      const con = this.item.contains.find( it => it.id === s);
      if (con) {
        // removed_symbol_list.push(con.id);
        const info = con.info ? " (" + this.getString(con.info, "lower", lang) + ")" : "";
        const str = this.getString("utan", undefined, lang) + " " + this.getString(con.name, "lower", lang) + info;
        strs.push(str);
      }
    });
    Object.keys(this.selAdjs).forEach( s => {
      const adj = this.item.adjusts.find( it => it.id === s);
      if (adj) {
        const str = this.getString("utan", undefined, lang) + " " + this.getString(adj.name, "lower", lang);
        strs.push(str);
      }
    });

    return strs.length > 0 ? this.buildCommaAndStringFromList(strs, lang) : undefined;
  }

  private buildCommaAndStringFromList(strings: string[], lang: string): string {
    if (strings.length > 2) {
      const last = strings.pop();
      const commaString = strings.join(", ");
      strings = [commaString, last];
    }
    const value =  strings.join(` ${this.getString("och", undefined, lang)} `);
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  visibleAttributes() {
    return this.item.attributes?.filter( atr => atr.hidden !== true ) ?? [];
  }

  hiddenAttributes() {
    return this.item.attributes?.filter( atr => atr.hidden === true ) ?? [];
  }

  openTweaks() {
    this.tweaksOpen = !this.tweaksOpen;
  }

  ngOnDestroy() {
  }

  getCount() {
    return this.orderItem ? this.orderService.getCount(this.orderItem.id, true, this.orderItem.package != null) : 0;
  }

  private postAddNew(rid: string) {
    this.dialogRef.close();
    if (!this.isDrink() && this.item.pushes) {
      this.openPushDialog(rid);
    }
  }

  openPushDialog(rid: string) {
    const dialogRef = this.dialog.open(PushDialogComponent, HackUtils.DLG({
      data: { item: this.item, rid, pushIndex: 0 }
    }));
  }

  private isDrink() {
    return this.item.id.startsWith("iddrk");
  }

  addedItems(): OrderItem[] {
    return this.orderService.getItems(this.item.id);
  }

  private collectGiftCardInfo() {
    this.postActionData = undefined;
    this.openCollectDialog().subscribe((result) => {
      console.log(result);
      if (!result) {
        return;
      }
      localStorage.removeItem("receiver_name");
      this.postActionData = [Object.assign({action:"giftcard"}, result)];
      console.log(this.postActionData);
      let comment = this.postActionData?.[0]?.receiver_name;
      if (comment === "" || comment == null) {
        comment = undefined;
      }
      this.addItemNextStep(comment);
    });
  }

  openCollectDialog(): Observable<any> {
    const collectItems = [
      { id: "sender_name", title: "Namn på avsändare (ditt namn)", type: "text", value: localStorage.getItem("sender_name") },
      { id: "receiver_name", title: "Namn på mottagare", type: "text", value: localStorage.getItem("receiver_name")},
      { id: "message", title: "Hälsningsmeddelande", type: "textarea", value: localStorage.getItem("message")}
    ];
    const dialogRef = this.dialog.open(CollectInfoDialogComponent, HackUtils.DLG({
      data: { title: "Skapa personligt presentkort", closeTitle: "Avbryt", okTitle:"OK", collectItems, onOK: "justClose", infoLink: "personal_giftcard" }
    }));
    return dialogRef.afterClosed();
  }

  getString(s: string, pc = "normal", lang?: string) {
    if (lang === "sv") { return s; }
    return this.translate.single(s, pc);
  }
}
