import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HackUtils } from '../utils/Utils';
import { OrderService } from './order.service';
import { UserType } from '../objects/Identity';

@Injectable({
  providedIn: 'root'
})
export class MessengerService {

  triedToLoadSDK = false;
  extensionsLoaded = false;

  desktopInMessenger: boolean;
  desktopInTab: boolean;
  supportedFeatures: any;

  constructor(private router: Router, private orderService: OrderService) { }

  init() {
    this.loadMessengerSDK();
  }

  getMessengerState(): string {
    if (this.extensionsLoaded) {
      if (this.supportedFeatures) {
        return "IN_MESSENGER";
      } else {
        return "OUTSIDE";
      }
    } else {
      return "UNKNOWN";
    }
  }

  // If in messenger: try to close the web view else redirect to post-landing
  // If not in messenger: redirect to url
  doPostAction(venueId: string, postMessageId, urlNotMessenger = "feed") {
    if (this.orderService.getOrderOrNull()?.user_cfg.user_type === UserType.Messenger) {
      this.closeWebview().subscribe({
        error: err => {
          console.error("Failed to close messenger", err);
          this.router.navigateByUrl(`${venueId}/post/${postMessageId}`);
        }
      });
    } else {
      this.router.navigateByUrl(`${venueId}/${urlNotMessenger}`);
    }
  }

  inMessenger(): boolean {
    const s = this.getMessengerState();
    return s === "IN_MESSENGER";
  }

  // Try to close web view if we have the feature "context" else fail
  closeWebview(): Observable<any> {
    return new Observable(obs => {
      if (this.supportedFeatures?.includes("context")) {
        // @ts-ignore
        MessengerExtensions.requestCloseBrowser(function success() {
          obs.complete();
        }, function error(err) {
          obs.error(err);
        });
      } else {
        obs.error();
      }
    });
  }

  private loadMessengerSDK() {
    if (this.triedToLoadSDK) { return; }
    console.log("Started load of messenger SDK...");
    this.triedToLoadSDK = true;

    const wn = window.name;
    this.desktopInMessenger = wn === "messenger_ref";
    this.desktopInTab = wn === "facebook_ref";

    const that = this;
    // @ts-ignore
    window.extAsyncInit = () => {
      console.log("Loaded messenger SDK");
      that.extensionsLoaded = true;

      // @ts-ignore
      MessengerExtensions.getSupportedFeatures(function success(result) {
        const features = result.supported_features;
        that.supportedFeatures = features;
        console.log("Supported features", that.supportedFeatures);
      }, function error(err) {
        console.error("Failed to get supported features", err);
        that.supportedFeatures = null;
      });
    };

    const d = document;
    const s = "script";
    const id = "messenger";
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    const js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
    fjs.parentNode.insertBefore(js, fjs);
  }

  isOnDesktopAndInIFrame() {
    return this.inMessenger() && this.desktopInMessenger && !HackUtils.isMobile();
  }
}
