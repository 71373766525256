<mat-dialog-content class="item-dialog mat-typography">
    <div class="item-header">
        <div class="item-title">{{translate.single("Du har artiklar i din beställning")}}</div>
      </div>

  <div class="item-content">
    <div>{{translate.single("Om du byter leveranssätt så kommer din beställning att tömmas.")}}</div>
  </div>

  <div class="button-list" style="padding: 16px;">
    <button mat-stroked-button mat-dialog-close>{{translate.single("Avbryt")}}</button>
    <button mat-raised-button color="accent"  (click)=submit()>{{translate.single("Fortsätt")}}</button>

  </div>

</mat-dialog-content>
