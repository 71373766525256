import { Component, OnInit } from '@angular/core';
import {combineLatest, Subscription} from "rxjs";
import {first} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {FireService} from "../../../services/fire.service";
import {AppStateService} from "../../../services/app-state.service";
import {AuthorizedApiService} from "../../../services/authorized-api.service";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-fail-redirect',
  templateUrl: './fail-redirect.component.html',
  styleUrls: ['./fail-redirect.component.css']
})
export class FailRedirectComponent implements OnInit {

  private combinedSub: Subscription;
  private vivaParamOrderCode: string;

  constructor(private route: ActivatedRoute, private fire: FireService, private router: Router,
              private appState: AppStateService, private apiService: AuthorizedApiService,
              private auth: AuthService) { }

  ngOnInit(): void {
    this.combinedSub = combineLatest([
      this.route.queryParamMap,
      this.appState.observeAppState()]
    ).pipe(first()).subscribe(res => {
      this.vivaParamOrderCode = res[0].get("s");
      if (this.vivaParamOrderCode) {
        this.resolveReceiptKey(this.vivaParamOrderCode, undefined);
      }
    });
  }

  private resolveReceiptKey(orderCode: string, transactionId: string) {
    // Resolve the location where the payment was initiated "cart", "livesplit", "receipt", "paymentlink"
    this.apiService.vivaResolveReceiptKey(orderCode, transactionId).subscribe(res => {
      console.log(res);
      this.router.navigateByUrl(`${res.loc}`);
    });
  }

}
