import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';
import {Order, UserSpecificConfig} from '../../models/Order';
import { TipAnyAmountDialogComponent } from '../tip-any-amount-dialog/tip-any-amount-dialog.component';
import {HackUtils} from '../../utils/Utils';
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-pay-dialog',
  templateUrl: './pay-dialog.component.html',
  styleUrls: ['./pay-dialog.component.css']
})
export class PayDialogComponent implements OnInit {
  typeImage: string;
  buttons: any[];
  private type: string;
  private cfg: UserSpecificConfig;
  amount: number;
  private overrideTip?: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<PayDialogComponent>,  public dialog: MatDialog,
              private translate: TranslateService) {
    this.type = data.type;
    this.amount = data.amount;
    this.cfg = data.userCfg;
    this.overrideTip = data.overrideTip;
    console.log(`Open tip with type: ${this.type}`);
  }

  ngOnInit(): void {
    if (this.type !== "prepaid") {
      const images = {card: "mc_visa.png", swish: "swish_open_24.png", viva_smart: "apaygpaytr.png"};
      this.typeImage = "https://storage.googleapis.com/heynow/media/" + images[this.type];
    }
    this.buttons = [];
    const showTip = this.overrideTip == null ? this.cfg.payment.use_tip : this.overrideTip;
    if (showTip) {
      this.setupTipSelect();
    } else if (this.type === "card") {
      this.setupSelectCard({tip: 0});
    }
  }

  private setupTipSelect() {
    this.buttons.push({tip: 0, text: this.getString("Ingen dricks"), dt: "tip"});
    if (this.amount >= 100) {
      this.buttons.push({tip: 5, text: this.getString("❤️ 5% dricks"), dt: "tip"});
      this.buttons.push({tip: 10, text: this.getString("😍 10% dricks"), dt: "tip"});
    } else {
      this.buttons.push({tip: 'v5', text: this.getString("😍 5kr dricks"), dt: "tip"});
      this.buttons.push({tip: 'v10', text: this.getString("❤️ 10kr dricks"), dt: "tip"});
    }
    this.buttons.push({tip: 'select_amount', text: this.getString("🔥Valfritt belopp🔥"), dt: "tip"});
  }

  btnClicked(btn: any) {
    if (btn.dt === "tip") {
      if (this.type === 'card' && this.cfg.payment?.saved_card) {
        if (btn.tip === "select_amount") {
          this.openTipAnyAmountDialog(btn).subscribe(b => this.setupSelectCard(b));
        } else {
          this.setupSelectCard(btn);
        }
      } else {
        if (btn.tip === "select_amount") {
          this.openTipAnyAmountDialog(btn).subscribe(b => this.dialogRef.close(b));
        } else {
          this.dialogRef.close(btn);
        }
      }
    } else if (btn.dt === "sc") {
      this.dialogRef.close(btn);
    }
  }

  private setupSelectCard(btn) {
    this.buttons = [
      {tip: btn.tip, selected: 'saved_card', text: `${this.getString("Sparat kort")} (${this.cfg.payment.saved_card})`, dt: "sc"},
      {tip: btn.tip, selected: 'new_card', text: this.getString("Annat kort"), dt: "sc"}
      ];
  }

  openTipAnyAmountDialog(btn): Observable<any> {
    const dialogRef = this.dialog.open(TipAnyAmountDialogComponent, HackUtils.DLG({
        data: { amount: this.amount }
      }));
    return dialogRef.afterClosed().pipe(filter(res => res), map(res => {
      btn.tip = "v" + res.amount;
      return btn;
    }));
  }

  getString(s: string) {
    return this.translate.single(s);
  }
}
