import { Component, OnDestroy, OnInit } from '@angular/core';
import { PrepaidCardData } from '../../models/Order';
import { AppStateService } from '../../services/app-state.service';
import { Subscription } from 'rxjs';
import { AppState } from '../../models/AppState';
import { AuthorizedApiService } from '../../services/authorized-api.service';
import { UrlHistoryService } from '../../services/url.history.service';
import { VenueConfig } from '../../models/FSConfig';
import { Router } from '@angular/router';
import {SignInDialogComponent} from "../sign-in/sign-in-dialog/sign-in-dialog.component";
import {HackUtils} from "../../utils/Utils";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-prepaid-card',
  templateUrl: './prepaid-card.component.html',
  styleUrls: ['./prepaid-card.component.css']
})
export class PrepaidCardComponent implements OnInit, OnDestroy {

  isLoading = true;
  showQRCode = false;
  prepaidData: PrepaidCardData;
  config: VenueConfig;
  appState: AppState;
  private appStateSub: Subscription;

  constructor(private appStateService: AppStateService, private api: AuthorizedApiService,
              private identity: UrlHistoryService, private router: Router, private dialog: MatDialog,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.appStateSub = this.appStateService.observeAppState().subscribe(appState => {
      this.appState = appState;
      this.config = this.appState.cfg;
      this.loadCardData();
    });
  }

  ngOnDestroy(): void {
    if (this.appStateSub) {
      this.appStateSub.unsubscribe();
    }
  }

  private loadCardData() {
    this.api.fetchPrepaidData().subscribe(res => {
      this.prepaidData = res;
      console.log(res);
    }, null, () => { this.isLoading = false; });
  }

  showQR() {
    this.showQRCode = !this.showQRCode;
  }

  back() {
    this.identity.navigateBack();
  }

  refill() {
    this.isLoading = true;
    this.api.createRefillReceipt("param_not_used").subscribe(res => {
      this.openReceiptPayment(res.receipt_key);
    }, null, () => { this.isLoading = false; });
  }

  private openReceiptPayment(receiptKey: string) {
    console.log(receiptKey);
    this.router.navigateByUrl(`${this.appState.venueId}/livesplit/${receiptKey}`);
  }

  login() {
    console.log("login...");
    const dialogRef = this.dialog.open(SignInDialogComponent, HackUtils.DLG({
      data: {}
    }));
  }

  getString(s: string, pc: string = "normal") {
    return this.translate.single(s, pc);
  }

}
