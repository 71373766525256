import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { MenuPageComponent } from './components/menu-page/menu-page.component';
import { CartComponent } from './components/cart/cart.component';
import { FeedComponent } from './components/feed/feed.component';
import { ReceiptListComponent } from './components/receipt-list/receipt-list.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { AuthGuard } from './guards/auth.guard';
import { PayStatusComponent } from './components/pay-status/pay-status.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { LivesplitComponent } from './components/livesplit/livesplit.component';
import { GlanceVoucherComponent } from './components/glance-voucher/glance-voucher.component';
import { PrepaidCardComponent } from './components/prepaid-card/prepaid-card.component';
import { ConnectMessengerComponent } from './components/my-account/connect-messenger/connect-messenger.component';
import { PolicyComponent } from './components/policy/policy.component';
import { PostLandingComponent } from './components/post-landing/post-landing.component';
import { VoucherActivationComponent } from './components/receipt/voucher-activation/voucher-activation.component';
import { MenuOfTheWeekComponent } from './components/menu-of-the-week/menu-of-the-week.component';
import { ExternalGiftcardComponent } from './components/external-giftcard/external-giftcard.component';
import { SignOutPageComponent } from './components/sign-out-page/sign-out-page.component';
import {DunderComponent} from "./components/cart/dunder/dunder.component";
import {SelectLangComponent} from "./components/select-lang/select-lang.component";
import { GdprComponent } from './components/gdpr/gdpr.component';
import {FailRedirectComponent} from "./components/pay-status/fail-redirect/fail-redirect.component";


const routes: Routes = [
  /* Unguarded */
  { path: ":venue_id/terms", component: PolicyComponent },

  /* Guarded */
  { path: ":venue_id/signout", component: SignOutPageComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/menu/:page_id", component: MenuPageComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/menu/:page_id/:section_id", component: MenuPageComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/receipt/:receipt_id", component: ReceiptComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/activate/:receipt_id", component: VoucherActivationComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/week", component: MenuOfTheWeekComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/giftcard/:post_id", component: ExternalGiftcardComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/g/:post_id", component: ExternalGiftcardComponent, data: { receiver_layout: true }, canActivate: [AuthGuard] },
  { path: ":venue_id/cart", component: CartComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/dunder", component: DunderComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/feed", component: FeedComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/account", component: MyAccountComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/receipts", component: ReceiptListComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/paystatus/:receipt_key", component: PayStatusComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/glance/:voucher_id", component: GlanceVoucherComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/prepaid", component: PrepaidCardComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/livesplit", component: LivesplitComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/livesplit/:receipt_key", component: LivesplitComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/pl/:pl_key", component: LivesplitComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/connect-messenger", component: ConnectMessengerComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/post/:message_id", component: PostLandingComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/language", component: SelectLangComponent, canActivate: [AuthGuard] },
  { path: ":venue_id/gdpr", component: GdprComponent, canActivate: [AuthGuard] },

  { path: ":venue_id", redirectTo: ':venue_id/menu/food', pathMatch: 'full' },

  { path: ":venue_id/vivasuccess", component: PayStatusComponent },
  { path: ":venue_id/vivafail", component: FailRedirectComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled' }), BrowserModule],
  exports: [RouterModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppRoutingModule { }
