<div *ngIf="!isLoading">
  <div class="container">
    <div class="back" (click)="back()">
      <mat-icon style="font-size: 27px">navigate_before</mat-icon>
      <div style="margin: 3px 0 0 5px;">GDPR</div>
    </div>

    <p>
      Din integritet och förtroende är av högsta prioritet för oss. Här följer en sammanfattning av eventuell personlig information som kan samlas in.
    </p>

    <h2>Beställning via webben</h2>

    <h3>Beställning genom Messenger-profil</h3>
    <ul>
      <li>Vid användning av Messenger skapas en unik profil för varje restaurang du besöker.</li>
      <li>Profilen lagrar namn och bild från den offentliga informationen i ditt Messenger-konto.</li>
    </ul>

    <h3>Inloggad beställning med profil</h3>
    <ul>
      <li>Vid inloggning skapas en profil, och i den lagras e-post, namn och profilbild som hämtas från det
        användarkonto som användes för inloggning.</li>
      <li>Om gästen använder ett Apple-konto har de möjlighet att välja vilka uppgifter som delas. I grundutförande
        delas inte e-post eller namn.</li>
    </ul>

    <h3>Anonym beställning utan profil</h3>
    <ul>
      <li>Vissa restauranger tillåter beställningar utan inloggning.</li>
      <li>Ingen personlig information lagras i dessa fall.</li>
    </ul>

    <h2>Vilka uppgifter lagras vid köp?</h2>
    <ul>
      <li>Kvittot visar vilka artiklar som har köpts.</li>
      <li>Vid kortbetalning lagras de sista fyra siffrorna av kortnumret.</li>
      <li>Vid betalning med Swish lagras de sista fyra siffrorna av ditt telefonnummer.</li>
      <li>Swish-referensdata lagras med extra kryptering.</li>
      <li>Vid takeaway eller leverans sparas det telefonnummer och adress du angav. Denna data raderas automatiskt efter 3 år eller när profilen raderas.</li>
      <li>Du hittar din köphistorik här: <a href (click)="navtoReceipts()">kvitton</a></li>
    </ul>

    <h2>Händelser</h2>
    <ul>
      <li>Systemet skickar notiser om händelser vid användning i form av chattmeddelanden.</li>
      <li>Vissa händelser kan innehålla personlig information, som ditt namn.</li>
      <li>Händelser raderas automatiskt efter 3 år, men kan även <a href="#" (click)="cleanEvents(); $event.preventDefault()">rensas manuellt.</a></li>
      <li>Händelser sparas inte för messenger-profiler</li>
    </ul>

    <h2>Hur används profiluppgifterna?</h2>
    <ul>
      <li>Namn: Visas endast i samband med köp och beställning för att ge bättre service.</li>
      <li>E-post: Används av systemet för att skicka notifikationer i samband med köp, som t.ex. kvitto och att order är färdig.</li>
      <li>Telefonnummer/Adress: Används vid köp som kräver kontakt med beställaren. Enbart synliga vid köptillfället.</li>
      <li>Händelser: Sparas enbart för att ge användarna en översikt över tidigare händelser.</li>
    </ul>

    <hr style="margin: 32px 0 32px 0">

    <h2>Din profil</h2>
    <mat-card *ngIf="profileInfo">
      <div style="display: flex; flex-direction: row;">
        <img [src]="profileInfo.image" alt="Profilbild" style="width: 100px; height: 100px;" *ngIf="profileInfo.image">
        <div style="margin-left: 30px; display: flex; flex-direction: column; justify-content: flex-end;">
          <div><strong>Namn:</strong> {{profileInfo.name}}</div><br>
          <div><strong>E-post:</strong> {{profileInfo.email}}</div>
        </div>
      </div>
<!--      <div *ngIf="profileInfo.venues.length > 0" style="padding-top: 1em;">-->
<!--        <h3>Ställen som du besökt</h3>-->
<!--        <ul>-->
<!--          <li *ngFor="let venue of profileInfo.venues">{{venue.venue_name}}</li>-->
<!--        </ul>-->
<!--      </div>-->
    </mat-card>

    <h2 style="margin: 32px 0 0 0">Radera profilen</h2>
    <ul>
      <li>Profil och alla händelser kommer att raderas.</li>
      <li>Köphistorik kommer inte längre att kopplas till profilen och blir anonym.</li>
      <li>Köphistorik kan inte återställas.</li>
      <li>Alla rabattkoder tas bort.</li>
      <li>Eventuella presentkort/rabattkort tas bort.</li>
      <li>Det kan ta upp till 30 dagar innan all personlig information är raderad eller anonymiserad.</li>
    </ul>
    <div class="payment-button" mat-ripple (click)="wipeProfile()" style="margin-top: 32px;">
      <div class="payment-button-text" style="color: red">{{getString("Radera profil", "upper")}}</div>
    </div>
  </div>
</div>

<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
