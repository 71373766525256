import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable, of} from 'rxjs';
import {filter, flatMap, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
    // return this.authService.isLoggedIn().pipe(filter(s => s != null)).pipe(map(loggedIn => {
    //   if (loggedIn) {
    //     return true;
    //   } else {
    //     this.router.navigate([`${next.paramMap.get("venue_id")}/menu/food`], { queryParams: next.queryParams, queryParamsHandling: "merge" });
    //     return false;
    //   }
    // }));
  }
}
