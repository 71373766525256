<mat-dialog-content class="item-dialog mat-typography">

  <div class="item-header">
    <div class="item-title">{{title}}<br>{{range}}</div>
  </div>

  <div *ngIf="config" class="item-content">
    <div class="time-select">
      <input type="time" [(ngModel)]="time">
    </div>
    <div class="err-mess">{{errorMessage}}</div>
  </div>

  <div class="button-list">
    <button mat-stroked-button mat-dialog-close>Stäng</button>
    <mat-spinner class="add-item-spinner" *ngIf="isWorking" [diameter]="32"></mat-spinner>
    <button [disabled]="isWorking" mat-raised-button color="accent" (click)="selectedTime()">OK</button>
  </div>

</mat-dialog-content>
