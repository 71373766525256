<div class="dos-base">
  <div *ngFor="let inst of printInstructions" [ngClass]="inst.tclass">
    <div *ngIf="inst.temp == 'div'">
      <hr>
    </div>
    <div *ngIf="inst.temp == 'center'" class="dos-center">
      {{inst.text.center}}
    </div>
    <div *ngIf="inst.temp == 'row'" class="dos-row">
      <div [innerHTML]="inst.text.left" style="margin-right: auto;"></div>
      <div [innerHTML]="inst.text.center"></div>
      <div [innerHTML]="inst.text.right" style="margin-left: auto;"></div>
    </div>
    <div *ngIf="inst.temp == 'row5-5-8'" class="dos-row">
      <div [innerHTML]="inst.text.left" style="flex: 10;"></div>
      <div [innerHTML]="inst.text.center" style="flex: 10;text-align: end;"></div>
      <div [innerHTML]="inst.text.right" *ngIf="inst.text.right" style="flex: 16;text-align: end;"></div>
    </div>
    <div *ngIf="inst.feed" [ngStyle]="{'height':inst.feed+'px'}"></div>
  </div>
</div>

