import { Component, OnDestroy, OnInit } from '@angular/core';
import { Receipt } from '../../models/Receipt';
import { UrlHistoryService } from 'src/app/services/url.history.service';
import { AuthorizedApiService } from '../../services/authorized-api.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-receipt-list',
  templateUrl: './receipt-list.component.html',
  styleUrls: ['./receipt-list.component.scss']
})
export class ReceiptListComponent implements OnInit, OnDestroy {
  receipts: Receipt[];
  isLoading = true;
  private stateSub: Subscription;
  private receiptsSub: Subscription;

  constructor(
    private identityService: UrlHistoryService,
    private appState: AppStateService,
    private api: AuthorizedApiService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    console.log("ReceiptListComponent init");
    this.stateSub = this.appState.observeAppState().pipe(first()).subscribe(state => {
      this.receiptsSub = this.api.getReceipts().subscribe(r => {
        this.isLoading = false;
        console.log("New receipts");
        console.log(r);
        this.receipts = r;
      });
    });
  }

  ngOnDestroy() {
    this.stateSub?.unsubscribe();
    this.receiptsSub?.unsubscribe();
  }

  backClicked() {
    this.identityService.navigateBack();
  }

  getString(s: string, postCase = "normal") {
    return this.translate.single(s, postCase);
  }

}
