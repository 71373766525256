<div class="feed-container" #feedScroller>
  <div *ngFor="let event of events">
    <div *ngIf="event.type==='text'" [class]="event.class">
      <div [innerHTML]="translateTitle(event.title)" (click)="bgClick()"></div>
      <div class="when">{{event.timeString}}</div>
    </div>
    <div *ngIf="event.type==='image'" [class]="event.class">
      <div class="image-cont"><img [src]="event.url"></div>
      <div class="when">{{event.timeString}}</div>
    </div>
    <div *ngIf="event.type==='generic'" [class]="event.class">
      <div *ngIf="event.topImg" class="generic-img"><img [src]="event.topImg"></div>
      <div class="generic-title">{{translateTitle(event.title)}}</div>
      <div *ngIf="event.subtitle" class="generic-subtitle">{{translateSubTitle(event.subtitle)}}</div>
      <div *ngFor="let btn of event.buttons" class="generic-button" (click)="clicked(btn)" mat-ripple>
        {{getString(btn.title)}}
      </div>
      <div *ngIf="event.vouchers" style="margin-top: 24px;">
        <div *ngFor="let o of event.vouchers">
          <app-dragger [voucher]="o" [venueId]="appState.venueId" [type]="'feed'"></app-dragger>
        </div>
        <div class="r-panel-info-sm">
          <p>{{getString(translate.STRING_ACTIVATE1)}}</p>
          <p>{{getString(translate.STRING_ACTIVATE2)}}</p>
        </div>
      </div>
      <div class="when" style="padding-top: 8px;">{{event.timeString}}</div>
    </div>
  </div>
</div>
<div *ngIf="showConsole" style="position: absolute; top:37px;left:8px;z-index: 10;padding: 8px;background-color: gainsboro;border-radius: 5px;">
  <input type="text" style="height:30px;border:none;width: 200px;font-size: 16px;" [(ngModel)]="message">
  <button mat-stroked-button style="margin-left:4px;font-size: 15px;" (click)="go()"><mat-icon>send</mat-icon></button>
</div>
