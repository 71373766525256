import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import {RouterModule} from '@angular/router';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { AppRoutingModule } from './app-routing.module';
import { MatRippleModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MenuPageComponent } from './components/menu-page/menu-page.component';
import { MenuListItemComponent } from './components/menu-list-item/menu-list-item.component';
import { MenuItemDialogComponent } from './components/menu-item-dialog/menu-item-dialog.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { MenuListSectionComponent } from './components/menu-list-section/menu-list-section.component';
import { MenuListCategoryComponent } from './components/menu-list-category/menu-list-category.component';
import { CartComponent } from './components/cart/cart.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { PayDialogComponent } from './components/pay-dialog/pay-dialog.component';
import { FeedComponent } from './components/feed/feed.component';
import { HiresDirective } from './directives/hires.directive';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { GlobalErrorHandler } from './global-error-handler';
import { ReceiptListComponent } from './components/receipt-list/receipt-list.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { ReceiptEmailDialogComponent } from './components/receipt/receipt-email-dialog/receipt-email-dialog.component';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { SelectDeliverTypeComponent } from './components/sidebar/select-deliver-type/select-deliver-type.component';
import { WebCodeDialogComponent } from './components/web-code-dialog/web-code-dialog.component';
import { Scroll1Directive } from './directives/scroll1.directive';
import { TipAnyAmountDialogComponent } from './components/tip-any-amount-dialog/tip-any-amount-dialog.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { PayStatusComponent } from './components/pay-status/pay-status.component';
import { PushDialogComponent } from './components/push-dialog/push-dialog.component';
import { LivesplitComponent } from './components/livesplit/livesplit.component';
import { ChangeDeliverTypeAlertDialogComponent } from './components/sidebar/select-deliver-type/change-deliver-type-alert-dialog/change-deliver-type-alert-dialog.component';
import { SplitItemDialogComponent } from './components/livesplit/split-item-dialog/split-item-dialog.component';
import { CollectInfoDialogComponent } from './components/collect-info-dialog/collect-info-dialog.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { GlanceVoucherComponent } from './components/glance-voucher/glance-voucher.component';
import { PrepaidCardComponent } from './components/prepaid-card/prepaid-card.component';
import { ConnectMessengerComponent } from './components/my-account/connect-messenger/connect-messenger.component';
import { PolicyComponent } from './components/policy/policy.component';
import { SelectTimeDialogComponent } from './components/cart/select-time-dialog/select-time-dialog.component';
import { FilterHiddenAtrPipe } from './pipes/filter-hidden-atr.pipe';
import { PostLandingComponent } from './components/post-landing/post-landing.component';
import { DraggerComponent } from './components/dragger/dragger.component';
import { VoucherActivationComponent } from './components/receipt/voucher-activation/voucher-activation.component';
import { CallWaitressDialogComponent } from './components/sidebar/call-waitress-dialog/call-waitress-dialog.component';
import { MenuOfTheWeekComponent } from './components/menu-of-the-week/menu-of-the-week.component';
import { EnterDiscountCodeDialogComponent } from './components/cart/enter-discount-code-dialog/enter-discount-code-dialog.component';
import { ExternalGiftcardComponent } from './components/external-giftcard/external-giftcard.component';
import { SelectDateTimeDialogComponent } from './components/cart/select-date-time-dialog/select-date-time-dialog.component';
import { SelectRoomDialogComponent } from './components/cart/select-room-dialog/select-room-dialog.component';
import { SignInDialogComponent } from './components/sign-in/sign-in-dialog/sign-in-dialog.component';
import { SignOutPageComponent } from './components/sign-out-page/sign-out-page.component';
import {DossierViewerComponent} from './components/dossier-viewer/dossier-viewer.component';
import { DunderComponent } from './components/cart/dunder/dunder.component';
import { RescanDialogComponent } from './components/rescan-dialog/rescan-dialog.component';
import { GenericInfoDialogComponent } from './components/generic-info-dialog/generic-info-dialog.component';
import { DeactivateKioskModeDialogComponent } from './components/sidebar/deactivate-kiosk-mode-dialog/deactivate-kiosk-mode-dialog.component';
import { AttributeCutterPipe } from './pipes/attribute-cutter.pipe';
import { SelectLangComponent } from './components/select-lang/select-lang.component';
import { ConfirmLangDialogComponent } from './components/select-lang/confirm-lang-dialog/confirm-lang-dialog.component';
import { PhoneNumberValidatorDirective } from './phone-number-validator.directive';
import { GdprComponent } from './components/gdpr/gdpr.component';
import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component';
import { FailRedirectComponent } from './components/pay-status/fail-redirect/fail-redirect.component';
import { SwishNumberDialogComponent } from './components/pay-status/swish-number-dialog/swish-number-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    MenuPageComponent,
    MenuListItemComponent,
    MenuItemDialogComponent,
    MenuListSectionComponent,
    MenuListCategoryComponent,
    CartComponent,
    SignInComponent,
    PayDialogComponent,
    FeedComponent,
    HiresDirective,
    ErrorDialogComponent,
    ReceiptListComponent,
    ReceiptComponent,
    ReceiptEmailDialogComponent,
    SentenceCasePipe,
    SelectDeliverTypeComponent,
    WebCodeDialogComponent,
    Scroll1Directive,
    TipAnyAmountDialogComponent,
    MyAccountComponent,
    PayStatusComponent,
    PushDialogComponent,
    LivesplitComponent,
    ChangeDeliverTypeAlertDialogComponent,
    SplitItemDialogComponent,
    CollectInfoDialogComponent,
    GlanceVoucherComponent,
    PrepaidCardComponent,
    ConnectMessengerComponent,
    PolicyComponent,
    SelectTimeDialogComponent,
    FilterHiddenAtrPipe,
    PostLandingComponent,
    DraggerComponent,
    VoucherActivationComponent,
    CallWaitressDialogComponent,
    MenuOfTheWeekComponent,
    EnterDiscountCodeDialogComponent,
    ExternalGiftcardComponent,
    SelectDateTimeDialogComponent,
    SelectRoomDialogComponent,
    SignInDialogComponent,
    SignOutPageComponent,
    DossierViewerComponent,
    DunderComponent,
    RescanDialogComponent,
    GenericInfoDialogComponent,
    DeactivateKioskModeDialogComponent,
    AttributeCutterPipe,
    SelectLangComponent,
    ConfirmLangDialogComponent,
    PhoneNumberValidatorDirective,
    GdprComponent,
    SimpleDialogComponent,
    FailRedirectComponent,
    SwishNumberDialogComponent,
  ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        RouterModule,
        MatListModule,
        MatIconModule,
        MatExpansionModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatSnackBarModule,
        MatDialogModule,
        AngularFireModule.initializeApp(environment.firebase),
        MatRadioModule,
        MatCheckboxModule,
        MatCardModule,
        MatBottomSheetModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule
    ],
  providers: [
  { provide: ErrorHandler, useClass: GlobalErrorHandler }],
  bootstrap: [AppComponent]
})
export class AppModule {}
