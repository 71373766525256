import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from '../../services/app-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sign-out-page',
  templateUrl: './sign-out-page.component.html',
  styleUrls: ['./sign-out-page.component.css']
})
export class SignOutPageComponent implements OnInit {
  isLoading = true;
  private stateSub: Subscription;
  private venueId: Number;

  constructor(
    private router: Router,
    private appState: AppStateService) { }

  ngOnInit(): void {
    this.stateSub = this.appState.observeAppState().subscribe(app => {
      this.venueId = app.venueId;
      if (app.order.user_cfg.is_anon) {
        this.isLoading = false;
      } else {
        this.router.navigateByUrl(`${app.venueId}`);
      }
    });
  }

  goToStartPage() {
    this.router.navigateByUrl(`${this.venueId}`);
  }

  ngOnDestroy() {
    this.stateSub?.unsubscribe();
  }
}
