import {AfterViewChecked, AfterViewInit, Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import {empty, from} from 'rxjs';
import {delay} from 'rxjs/operators';

@Directive({
  selector: '[appScroll1]'
})
export class Scroll1Directive implements AfterViewChecked {
  private iOS: boolean;
  private messenger: boolean;

  constructor(private elementRef: ElementRef) {
    this.iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    this.messenger = /\?ml=/.test(window.location.href);
  }

  // Trigger when image has been correctly loaded
  @HostListener('scroll') scroll() {
    if (!this.iOS || !this.messenger) { return; }
    this.forceToScroll1();
  }

  ngAfterViewChecked(): void {
    if (!this.iOS || !this.messenger) { return; }
    this.forceToScroll1();
  }

  forceToScroll1() {
    const elm = this.elementRef.nativeElement;
    if (elm.scrollTop === 0) {
      elm.scrollTop = 1;
      elm.paddingTop = 1 + "px";
      console.log("forceToScroll1");
    }

    const ftr = elm.querySelector('.scrollfooter');
    if (ftr) {
      const nh = elm.offsetHeight - ftr.offsetTop;
      if (nh >= 0) {
        const newH = nh + 5 + "px";
        if (ftr.style.height !== newH) {
          ftr.style.height = newH;
          //console.log("-------------- SET!");
        }
      }
    }
  }

}
