import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attributeCutter'
})
export class AttributeCutterPipe implements PipeTransform {

  // Cut attribute name after " –" exapmle: "Gåva – 35kr" -> "Gåva"
  transform(value: string, ...args: unknown[]): unknown {
    return value.replace(/ –.*/g, "");
  }

}
