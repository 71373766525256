import { Injectable, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {
  private opened = false;

  constructor(private dialog: MatDialog, private ngZone: NgZone) {}

  openDialog(message: string, status?: number): void {
    if (!this.opened) {
      this.opened = true;

      this.ngZone.run(() => {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: { message, status },
          width: "540px",
          maxWidth: "90%",
          hasBackdrop: true
        });

        dialogRef.afterClosed().subscribe(() => {
          this.opened = false;
        });
      });
    }
  }
}
