import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {

  transform(value: string | undefined): string {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : undefined;
  }

}
