<main>
  <section>
    <div *ngIf="!isLoading && (!isKiosk || isKiosk !== isMonter)" class="cart-container">
      <div class="back" (click)="backClicked()">
        <mat-icon style="font-size: 27px">navigate_before</mat-icon>
        <div style="margin: 3px 0 0 2px;">{{getString("Kvitto", "upper")}}</div>
      </div>
    </div>

    <div style="padding: 0 15px 0 15px;">
      <div *ngIf="receiptData">

        <div class="r-panel" *ngIf="receiptData.post_actions">
          <div class="r-panel-title">Presentkort</div>
          <div>
            <div class="payment-button" mat-ripple *ngFor="let pa of receiptData.post_actions" (click)="openGiftcard(pa)">
              <div class="pay-btn-center" style="padding-top: 9px;">🎁 Presentkort {{getGiftcardName(pa)}}</div>
            </div>
          </div>
        </div>

        <div class="r-panel" *ngIf="receiptData.voucher_bump">
          <div class="r-panel-title">Pappersvoucher direktutskrift</div>
          <div>
            <mat-spinner class="spinner" *ngIf="bumpState.sending"></mat-spinner>
            <div *ngIf="bumpState.bump!='s'">
              <div class="payment-button" mat-ripple *ngFor="let o of receiptData.voucher_bump.stations" (click)="printVoucher(receiptData.voucher_bump.key,o)">
                <div class="pay-btn-center" style="padding-top: 9px;">🖥️ {{o.name}}</div>
              </div>
              <div class="r-panel-info">
                <b class="r-info-emp">OBS! Stå vid utskriftsstationen.</b>
                <p>Tryck på stationens namn ovan så skrivs din voucher ut.</p>
              </div>
            </div>
            <div *ngIf="bumpState.bump=='s'">
              Din voucher skrivs strax ut på <b>🖥️ {{bumpState.bump_station}}.</b> 😀
            </div>
          </div>
        </div>

        <div class="r-panel" *ngIf="receiptData.mvoucher">
          <div class="r-panel-title">🎟️ Biljett</div>
          <div>
            <div *ngFor="let o of receiptData.mvoucher">
              <app-dragger [voucher]="o" [venueId]="venueId"></app-dragger>
            </div>
            <div class="r-panel-info-sm">
              <p>{{getString(translate.STRING_ACTIVATE1)}}</p>
              <p>{{getString(translate.STRING_ACTIVATE2)}}</p>
            </div>
          </div>
        </div>

        <div class="r-panel" *ngIf="receiptData.ticket_vouchers?.length > 0">
          <div class="r-panel-info-sm-2" *ngIf="receiptData.ticket_vouchers?.length == 1">
            <div style="font-weight: bold; margin-bottom: 8px;">Här nedan är din biljett!</div>
            <p>Du kan använda den direkt från telefonen, eller maila den för utskrift.</p>
          </div>
          <div class="r-panel-info-sm-2" *ngIf="receiptData.ticket_vouchers?.length > 1">
            <div style="font-weight: bold; margin-bottom: 8px;">Här nedan är dina biljetter!</div>
            <p>Du kan använda dessa direkt från telefonen, eller maila dem för utskrift.</p>
          </div>
          <div style="display: flex; flex-direction: column; align-items: end;padding-top: 16px;" *ngIf="receiptData.ticket_vouchers?.length > 1">
            <div>
              <button mat-button color="primary" (click)="sendAllTickets()">
                <mat-icon class="mat-icon">mail</mat-icon>
                Maila alla biljetter ({{receiptData.ticket_vouchers?.length}}st)
              </button>
            </div>
          </div>
        </div>
        <div class="r-panel" *ngFor="let ticket of receiptData?.ticket_vouchers">
          <div style="margin: 16px;">
            <div>
              <img [src]="ticket.ticket.logo_link" style="max-width: 200px;max-height: 100px;">
            </div>
            <div *ngIf="ticket.ticket.name" class="ticket-title">
              {{ticket.ticket.name}}
            </div>
            <div *ngIf="ticket.ticket.date" class="ticket-title">
              {{ticket.ticket.date}}
            </div>
            <div *ngIf="ticket.ticket.place" class="ticket-sub1">
              Plats: {{ticket.ticket.place}}
            </div>
            <div *ngIf="ticket.price_cent" class="ticket-sub">
              Pris: {{ MoneyUtils.formatCent(ticket.price_cent) }}
            </div>
            <div *ngIf="ticket.ticket.desc" class="ticket-desc">
              {{ticket.ticket.desc}}
            </div>
            <div class="qr-panel">
              <div class="qr-panel-title">
                *{{ticket.id}}*
              </div>
              <div class="">
                <img class="ticket-qr" [src]="ticket.qr_url">
              </div>
            </div>
            <div class="ticket-more-info">
              <div style="margin-left: -17px;">
                <button mat-button color="primary" (click)="sendTicket(ticket.id)">Maila biljett</button>
              </div>
              <div *ngIf="ticket.ticket.more_text">
                <a href="{{ticket.ticket.more_link}}" target="_blank">{{ticket.ticket.more_text}}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="r-panel" *ngIf="receiptData.voucher">
          <div class="r-panel-title">Pappersvoucher QR-kod</div>
          <div>
            <div class="qr-image"><img [src]="receiptData.voucher" style="width: 100%;"/></div>
            <div class="r-panel-info">Visa QR-koden för en skanningsstation inne på restaurangen för att få en
              voucher.
            </div>
          </div>
        </div>

        <app-dossier-viewer *ngIf="receiptDossier" [printInstructions]="receiptDossier.instructions"></app-dossier-viewer>

        <div style="margin-top: 32px;margin-left: -10px;">
          <button mat-button color="primary" (click)="openMail()">{{getString("Maila kvittokopia")}}</button>
        </div>

        <div class="env-panel">
          <div>{{getString("Miljövänligt kvitto")}}</div>
          <div>{{getString("Elektroniska kvitton är ett bra miljöval.")}} 🌱 ☀️</div>
        </div>

        <div *ngIf="isKiosk && isMonter" style="height: 64px;">
          <div class="floating-button-container">
            <div class="payment-button-inv" mat-ripple (click)="goToStartpage()">
              {{getString("Klar")}}
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</main>
<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
