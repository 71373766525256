<mat-dialog-content class="item-dialog mat-typography">
  <div class="item-header">
    <div class="item-title">Ange pin för att gå ur kioskläge</div>
  </div>

  <div class="item-content kiosk-content">
    <div style="margin-top: 8px;margin-bottom: 16px;">
      <input type="password" class="kiosk-input" placeholder="PIN" autofocus [(ngModel)]="passcode" (keyup)="onKeyup($event)" style="text-align: center;">
    </div>
    <div style="height: 32px;">
      <div *ngIf="wrongPin" style="color: red;font-size: 16px;">Fel pin</div>
    </div>
  </div>

  <div class="button-list" style="padding: 16px;margin-top: 32px;">
    <button mat-stroked-button mat-dialog-close>Stäng</button>
    <mat-spinner class="add-item-spinner" *ngIf="isWorking" [diameter]="32"></mat-spinner>
  </div>

</mat-dialog-content>
