<div *ngIf="_category.items">
  <div *ngIf="_category.class!=='promo'" class="category-row">
    {{_category.name.toUpperCase()}}
    <div *ngIf="_category.desc" class="category-row-d">{{_category.desc}}</div>
  </div>
  <div *ngFor="let item of visitems; let i = index; let last = last;">
    <app-menu-list-item [item]="item" [count]="item.currentOrderCount" [outOfStock]="item.currentStockState" [venueOpen]="venueOpen" [itemDisabled]="itemsDisabled" [showWhen]="showWhen">
    </app-menu-list-item>
    <div *ngIf="!last" class="item-separator"></div>
  </div>
</div>
