import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthorizedApiService} from '../../../services/authorized-api.service';
import {Router} from '@angular/router';
import {HackUtils} from '../../../utils/Utils';
import {MessengerService} from '../../../services/messenger.service';
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-call-waitress-dialog',
  templateUrl: './call-waitress-dialog.component.html',
  styleUrls: ['./call-waitress-dialog.component.css']
})
export class CallWaitressDialogComponent implements OnInit {
  isWorking = false;
  table: string;
  private venueId: any;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<CallWaitressDialogComponent>,  public dialog: MatDialog,
              private api: AuthorizedApiService, private router: Router, private messengerService: MessengerService, public translate: TranslateService) {
    this.table = data.table;
    this.venueId = data.venueId;
  }

  ngOnInit(): void {
  }

  call() {
    this.isWorking = true;
    this.api.callForWaiter().subscribe(r => {
      this.isWorking = false;
      this.dialogRef.close();
      this.messengerService.doPostAction(this.venueId, "waitress_called");
    });
  }
}
