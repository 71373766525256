import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from "../../../services/translate.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Translation} from "../../../models/Translation";

@Component({
  selector: 'app-confirm-lang-dialog',
  templateUrl: './confirm-lang-dialog.component.html',
  styleUrls: ['./confirm-lang-dialog.component.css']
})
export class ConfirmLangDialogComponent implements OnInit {

  lang: any;
  isWorking = true;
  alert = "";

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<ConfirmLangDialogComponent>, private snackbar: MatSnackBar, private dialog: MatDialog, private translate: TranslateService) {
    this.lang = data.lang;
  }

  ngOnInit(): void {
    this.isWorking = true;
    this.translate.observeAlert(this.lang.code).subscribe((res: Translation) => {
      this.isWorking = false;
      console.log("in observe",res);
      this.alert = res.translation;
    }, error => {
      this.isWorking = false;
      this.snackbar.open("Some error happened :(", null, { duration: 3000 });
    });
  }

  getString(s: string) {
    return this.translate.single(s);
  }

  selectLang() {
    this.isWorking = true;
    this.alert = "";
    this.translate.prepareLangTranslation(this.lang.code).subscribe(res => {
      if (res) {
        localStorage.setItem("collinsLang", this.lang.code);
        window.location.reload();
      }
    }, error => {
      this.isWorking = false;
      this.snackbar.open("Some error happened :(", null, { duration: 3000 });
    });
  }
}
