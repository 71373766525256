<mat-dialog-content class="item-dialog mat-typography">
  <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm)">

    <div class="item-header">
      <div class="item-title">{{title}}</div>
    </div>

    <div class="item-content">

      <div class="info-form">
        <div *ngFor="let ci of collectItems">

          <mat-form-field class="info-full-width" *ngIf="ci.type=='tel'">
            <mat-label>{{ci.title}}</mat-label>
            <input matInput [placeholder]="ci.placeholder" appPhoneNumberValidator [type]="ci.type" [ngModel]="ci.model" [name]="ci.id" #name="ngModel" (ngModelChange)="modelChange($event, ci)" required>
            <mat-error *ngIf="name?.errors?.phoneNumberInvalid">Ogiltigt telefonnummer</mat-error>
          </mat-form-field>

          <mat-form-field class="info-full-width" *ngIf="ci.type=='textarea'">
            <mat-label>{{ci.title}}</mat-label>
            <textarea matInput [ngModel]="ci.model" #name="ngModel" (ngModelChange)="modelChange($event, ci)" [name]="ci.id" maxlength="150"></textarea>
          </mat-form-field>

          <mat-form-field class="info-full-width" *ngIf="ci.type!='textarea' && ci.type!='tel'">
            <mat-label>{{ci.title}}</mat-label>
            <input matInput [placeholder]="ci.placeholder" [type]="ci.type" [ngModel]="ci.model" #name="ngModel" [name]="ci.id" (ngModelChange)="modelChange($event, ci)">
          </mat-form-field>

        </div>
      </div>

      <div *ngIf="infoLink" style="display: flex; flex-direction: row; align-items: center;">
        <mat-icon style="transform: scale(0.8);color:#f39a83;">info</mat-icon>
        <a href (click)="openPersonalGiftcardInfoDialog()">Mer information om personligt presentkort</a>
      </div>
    </div>

    <div class="button-list" style="padding: 16px;margin-top: 32px;">
      <button mat-stroked-button mat-dialog-close>{{closeTitle}}</button>
      <mat-spinner class="add-item-spinner" *ngIf="isWorking" [diameter]="32"></mat-spinner>
      <button id="submit" [disabled]="isWorking" mat-raised-button color="accent" type="submit">{{okTitle}}</button>
    </div>
  </form>

</mat-dialog-content>
