<mat-dialog-content>
	<div class="item-header" cdkFocusRegionstart>
		<div class="item-title">{{title}}</div>
	</div>

	<div class="item-content">
		<div class="message-text">{{message}}</div>
	</div>
	
	<div class="button-list" style="padding: 16px;margin-top: 32px;">
		<button mat-stroked-button mat-dialog-close style="color: #aaa;">Stäng</button>
		<div *ngFor="let button of buttons">
			<button mat-raised-button color="accent" (click)="dialogButtons(button.action)">{{button.text}}</button>
		</div>
	</div>
</mat-dialog-content>
