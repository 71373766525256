import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AccountEvent } from '../../models/AccountEvent';
import { FireService } from '../../services/fire.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AppStateService } from '../../services/app-state.service';
import { AppState } from '../../models/AppState';
import { AuthorizedApiService } from '../../services/authorized-api.service';
import { AuthService } from 'src/app/services/auth.service';
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.css']
})
export class FeedComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild("feedScroller") private feedContainer: ElementRef;
  events: AccountEvent[];
  private eventSub: Subscription;
  private appStateSub: Subscription;
  vouchers = [{ consumed: false, name: "Test" }];
  appState: AppState;
  private largestSC = 0;
  private clickCount = 0;
  showConsole = false;
  message: string;

  constructor(private fire: FireService, private appStateService: AppStateService, private router: Router,
              private api: AuthorizedApiService, private auth: AuthService, public translate: TranslateService) { }

  ngOnInit(): void {
    console.log("FeedComponent onInit");
    this.appStateSub = this.appStateService.observeAppState().subscribe(appState => {
      console.log("FeedComponent user config");
      this.appState = appState;
      if (!this.eventSub) {
        this.beginObserving(appState);
      }
    });
  }

  private beginObserving(appState: AppState) {
    console.log("FeedComponent beginObserving");
    const source = this.auth.isKioskMode() ?
      this.fire.observeEventsWindow(appState.order.user_cfg.chat_id, moment().subtract(30, "minute").toDate()) :
      this.fire.observeEvents(appState.order.user_cfg.chat_id);
    this.eventSub = source.subscribe(events => {
      console.log("FeedComponent got events");
      let preEvent: AccountEvent;
      for (const event of events) {
        const t = moment(event.date.toDate());
        const hours = moment().diff(t, "hours");
        event.moment = t;
        event.timeString = hours > 24 ? t.format('YYYY-MM-DD HH:mm') : t.format('HH:mm');
        if (event.body) {
          const body = JSON.parse(event.body);
          event.buttons = body.buttons;
          event.url = body.url;
          event.vouchers = body.vouchers;
          //console.log(event.buttons);
        }

        // Logic to extract "group" class
        if (preEvent === undefined) {
          event.class = "bfirst";
        } else {
          const old = event.moment.diff(preEvent.moment, "seconds") > 120;
          if (old) {
            event.class = "bfirst";
            if (preEvent.class === "bmiddle") {
              preEvent.class = "blast";
            } else if (preEvent.class === "bfirst") {
              preEvent.class = "bubble";
            }
          } else {
            event.class = "bmiddle";
          }
        }
        preEvent = event;
      }
      if (preEvent !== undefined) {
        if (preEvent.class === "bmiddle") {
          preEvent.class = "blast";
        } else if (preEvent.class === "bfirst") {
          preEvent.class = "bubble";
        }
      }

      // As long as we do not have a good way to find out if the voucher is used or not special treat last and new generic glance events
      for (let i = 0; i < events.length; i++) {
        const event = events[i];
        if (event.vouchers) {
          const isNew = moment().diff(event.moment, "seconds") < 6 * 3600;
          if (i === events.length - 1 || isNew) {
            event.topImg = "https://storage.googleapis.com/heynow/media/chef_phone3.png";
          } else {
            event.vouchers = null;
          }
        }
      }
      console.log(events);
      this.events = events;
    });
  }

  ngOnDestroy(): void {
    if (this.eventSub) {
      this.eventSub.unsubscribe();
    }
    if (this.appStateSub) {
      this.appStateSub.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    const sc = this.feedContainer.nativeElement.scrollHeight;
    if (sc > this.largestSC) {
      this.largestSC = sc;
      this.feedContainer.nativeElement.scrollTop = sc;
    }
  }

  clicked(btn: any) {
    console.log(btn);
    this.router.navigateByUrl(btn.url);
  }

  bgClick() {
    this.clickCount++;
    if (this.clickCount % 10 === 0) {
      this.message = "";
      this.showConsole = true;
    }
  }

  go() {
    this.api.sendMessage(this.message).subscribe(res => {
      this.showConsole = false;
    });
  }

  getString(s: string) {
    return this.translate.single(s);
  }

  translateTitle(s: string) {
    if (this.translate.lang === "sv") { return s; }
    if (s.startsWith("Din beställning")) {
      //Din beställning<br>1x Tisdagspizza - 105 kr
      const suff = s.substring(19);
      const lines = suff.split("<br>");
      if (lines.length > 0) {
        const trslLines = [];
        for (const line of lines) {
          //&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lax marinerad i soya, Kryddig riskaka
          if (line.startsWith("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")) {
            trslLines.push("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"+this.getString(line.substring(30)));
            continue;
          }
          if (line.startsWith("Totalt:")) {
            trslLines.push(this.getString("Totalt") + ":" + line.substring(7));
            continue;
          }
          //1x Glassbåt med hemmagjord sylt - 260 kr
          const spi = line.indexOf(" ");
          if (spi > 0) {
            const pre = line.substring(0,spi+1);
            const post = line.substring(spi+1);
            const namePrice = post.split(" - ");
            trslLines.push(pre + " " + this.getString(namePrice[0]) + " - " + namePrice[1]);
          }
        }
        return this.getString("Din beställning") + "<br>" + trslLines.join("<br>");
      }
      return this.getString("Din beställning") + "<br>" + suff;
    } else if(s.startsWith("Tack för din beställning.")) {
      return this.getString("Tack för din beställning.") + " " + this.getString("Vi kommer strax ut med den.");
    } else if(s.startsWith("Notan är betald. Beställningsnummer")) {
      //Notan är betald. Beställningsnummer #69
      const parts = s.split(" #");
      return this.getString("Notan är betald. Beställningsnummer") + " #" + parts[1];
    } else if(s.startsWith("Wow")) {
      const s1 = this.getString("Wow! 😍 😍");
      if (s.includes("och övrig personal")) {
        //"Wow Anonym! 😍 😍<br><br>Davido, Lisa, Ronaldo och övrig personal gör allt för att du skall ha en trevlig upplevelse, så stort tack för dricksen!<br><br>Hoppas att allt var till din belåtenhet."
        const s2 = this.getString("och övrig personal gör allt för att du skall ha en trevlig upplevelse, så stort tack för dricksen!");
        const s3 = this.getString("Hoppas att allt var till din belåtenhet.");
        let names = "";
        if (s.includes("<br><br>")) {
          const d1 = s.split("<br><br>");
          const d2 = d1[1].split("och");
          names = d2[0];
        }
        return s1 + "<br><br>" + names + s2 + "<br><br>" + s3;
      } else if (s.includes("kommer strax ut med din beställning")) {
        //"Wow Anonym! 😍 😍 Tack för dricksen!<br><br>Robert kommer strax ut med din beställning."
        const s2 = this.getString("Tack för dricksen!");
        const s3 = this.getString("kommer strax ut med din beställning.");
        let names = "";
        if (s.includes("<br><br>")) {
          const d1 = s.split("<br><br>");
          const d2 = d1[1].split("kommer");
          names = d2[0];
        }
        return s1 + " " + s2 + "<br><br>" + names + s3;
      }
    } else if(s.startsWith("Tack") && s.endsWith("belåtenhet.")) {
      const s1 = this.getString("Tack!");
      const s3 = this.getString("Hoppas att allt var till din belåtenhet.");
      return s1 + " " + s3;
    } else {
      return this.getString(s);
    }
  }

  translateSubTitle(s: string) {
    if (this.translate.lang === "sv") { return s; }
    if (s.startsWith("Kassakvitto")) {
      return this.getString("Kvitto") + " " + s.substring(11);
    } else {
      return this.getString(s);
    }
  }
}
