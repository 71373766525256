import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UrlHistoryService {

  private urlHistory = [];

  constructor(private route: Router, private activatedRoute: ActivatedRoute, private auth: AuthService) {
  }

  pushUrl(url: string) {
    if (this.urlHistory.length > 5) {
      this.urlHistory.splice(0, 1);
    }
    const removedQuery = url.split("?")[0];
    this.urlHistory.push(removedQuery);
    console.log("URL history:", this.urlHistory);
  }

  popBack(urlPostFix: string): string {
    while (this.urlHistory.length > 1) {
      const t = this.urlHistory[this.urlHistory.length - 2];
      this.urlHistory.splice(this.urlHistory.length - 2, 2);
      if (t.indexOf("/post") >= 0 || t.indexOf("/dunder") >= 0) { continue; }
      console.warn("URL history: after pop", this.urlHistory);
      return t;
    }
    this.clearHistory();
    return `${this.auth.venueId}${urlPostFix}`;
  }

  navigateBack(urlPostFix: string = "/menu/food") {
    const url = this.popBack(urlPostFix);
    console.log("URL history: nav back to", url);
    if (url) {
      this.route.navigateByUrl(url);
    }
  }

  currentUrl(urlPostFix: string = "/menu/food") {
    if (this.urlHistory.length > 0) {
      return this.urlHistory[this.urlHistory.length - 1];
    }
    return `${this.auth.venueId}${urlPostFix}`;
  }

  clearHistory() {
    console.warn("URL history: cleared");
    this.urlHistory = [];
  }
}
