<div *ngIf="valid" id="particles-js"></div>
<div *ngIf="!valid" class="nonvalid"></div>
<div *ngIf="activated">
  <div class="voucher-container">
    <div style="text-align: center;margin-top:32px;"><img [src]="admitImg" [attr.width]="logo1Width"></div>
    <div style="margin-top: 32px;">
      <img [src]="activated.logo" [attr.width]="logo2Width">
    </div>
    <div style="font-size: 28px;font-weight: bold;margin-top: 16px;">{{activated.activated}}</div>
    <div style="font-size: 42px;font-weight: bold;margin-top: 48px;">{{activated.formatted}}</div>
    <div *ngIf="names" class="names-panel">
      <div class="name-item" *ngFor="let s of names">{{s}}</div>
    </div>
    <div *ngIf="!names" style="font-size: 30px;text-align: center;margin-top: 32px;">{{activated.name}}</div>
    <div style="text-align: center;margin-top: auto;margin-bottom: 32px;"># {{activated.set_serial_number}}</div>
  </div>
  <div class="x-button" (click)="close()"><mat-icon>close</mat-icon></div>
</div>
