import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizedApiService } from '../../services/authorized-api.service';
import { ActivatedRoute } from '@angular/router';
import { Voucher } from '../../models/Order';
import { UrlHistoryService } from '../../services/url.history.service';
import { Subscription, timer } from 'rxjs';

declare var particlesJS: any;
declare var pJSDom: any;

@Component({
  selector: 'app-glance-voucher',
  templateUrl: './glance-voucher.component.html',
  styleUrls: ['./glance-voucher.component.css']
})
export class GlanceVoucherComponent implements OnInit, OnDestroy {
  admitImg: string;
  activated: Voucher;
  valid = true;
  logo1Width = "100%";
  logo2Width = "200px";

  private venueId: string;
  private voucherId: number;
  private timeoutTime: number;
  private timerSub: Subscription;
  names: string[];

  constructor(private route: ActivatedRoute, private api: AuthorizedApiService, private identity: UrlHistoryService) { }

  ngOnInit(): void {
    particlesJS.load('particles-js', 'assets/particles.json', null);
    this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      this.voucherId = Number(data.get("voucher_id"));
      this.beginObserving();
    });
  }

  ngOnDestroy(): void {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  private beginObserving() {
    this.api.activateVoucherItem(this.voucherId).subscribe(res => {
      this.activated = res;
      let name = this.activated.name;
      if (name.startsWith("§§§")) {
        name = name.substring(3);
        this.names = name.split("|");
        this.logo1Width = "50px";
        this.logo2Width = "150px";
      }
      const date = new Date();
      this.admitImg = "https://storage.googleapis.com/heynow/media/admit_one2.png";
      this.timeoutTime = date.getTime() + this.activated.left * 1000;
      this.checkTimeout();
      this.timerSub = timer(0, 1000).subscribe(result => this.checkTimeout());
    });
  }

  private checkTimeout() {
    if (!this.valid) {
      return;
    }

    console.log("checkTimeout...");
    const now = new Date();
    if (now.getTime() >= this.timeoutTime) {
      console.log("Timedout...");
      this.activateNotValidState();
    }
  }

  private activateNotValidState() {
    this.activated.name = "Voucher använd";
    this.activated.formatted = "-";
    this.admitImg = "https://storage.googleapis.com/heynow/media/admit_expire.png";
    this.valid = false;
    this.hideAnim();
  }

  private hideAnim() {
    pJSDom[0].pJS.particles.move.enable = false;
  }

  close() {
    this.identity.navigateBack();
  }
}
