import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from "../../services/translate.service";


@Component({
  selector: 'app-tip-any-amount-dialog',
  templateUrl: './tip-any-amount-dialog.component.html',
  styleUrls: ['./tip-any-amount-dialog.component.css']
})
export class TipAnyAmountDialogComponent implements OnInit {
  orderAmount: number;
  totalAmount: number;
  enabled: boolean;
  btnText: string;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<TipAnyAmountDialogComponent>, private translate: TranslateService) {
    this.orderAmount = data.amount;
  }

  ngOnInit(): void {
    this.btnText = this.getString("Betala");
  }

  submit() {
    if (this.enabled) {
      const data = this.enabled ? { amount: this.totalAmount - this.orderAmount } : null;
      this.dialogRef.close(data);
    }
  }

  setupSubmit(): void {
    const tip = this.totalAmount - this.orderAmount;
    const tipRatio = tip * 100 / this.orderAmount;
    this.enabled = tip >= 0 && tipRatio < 100;
    const s = this.getString("Betala");
    if (!this.enabled) {
      this.btnText = s;
      return;
    }
    if (tipRatio > 20) {
      this.btnText = `${s} ${this.totalAmount}kr 🙏😍❤️`;
    } else if (tipRatio > 10.0) {
      this.btnText = `${s} ${this.totalAmount}kr ❤️❤️`;
    } else if (tipRatio > 0.5) {
      this.btnText = `${s} ${this.totalAmount}kr ❤️`;
    }
  }

  getString(s: string) {
    return this.translate.single(s);
  }
}
