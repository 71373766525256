<mat-dialog-content class="content mat-typography">

  <div class="item-header" cdkFocusRegionstart>
    <div class="item-title">{{getString(push.title)}}</div>
  </div>

  <div class="item-content">
    <div style="margin-top: 16px;">
      <div class="item-notice" *ngIf="itemIncluded">{{getString("Ingår i beställning")}}</div>
      <div *ngFor="let group of push.groups; let atri = index; let lastgr = last;">
        <div *ngIf="group.title" class="group-title"><span style="border-bottom: 1px solid #f39a83;">{{getString(group.title)}}</span></div>
        <div *ngFor="let item of group.menuItems; let lastit = last;" class="push-item">
          <mat-checkbox class="clickable-area" [(ngModel)]="selectedItems[item.id]" (change)="selectedChange(item)">
            <div class="push-name" *ngIf="!itemIncluded">{{getString(item.name)}} - {{getItemPriceFormatted(item)}}</div>
            <div class="push-name" *ngIf="itemIncluded">{{getString(item.name)}}</div>
          </mat-checkbox>
          <div style="padding-left: 33px">
            <div *ngIf="item.desc" class="desc">{{getString(item.desc)}}</div>
            <div class="item-contains" *ngIf="item.contains">
              {{getString("INNEHÅLLER", "upper")}} <span *ngFor="let con of item.contains; let last = last;">{{getString(con.name, "upper")}}{{last ? "" : ", "}}</span>
            </div>
            <div *ngIf="selectedItems[item.id]">
              <div *ngFor="let attribute of item.attributes; let atri = index; let lastatr = last;">
                <mat-radio-group *ngIf="attribute.options.length>1" [(ngModel)]="attrValues[item.id][attribute.id]">
                  <label class="clickable-area" style="margin-top: 8px;font-size: 16px;" *ngFor="let option of attribute.options; let opti = index; let lastopt = last;">
                    <mat-radio-button [value]="option.key">
                      <div><span class="atr-name">{{getString(option.value)}}</span><span *ngIf="!itemIncluded"> {{option.formatted}}</span></div>
                      <div class="item-contains-sm" *ngIf="option.contains">{{getString("INNEHÅLLER", "upper")}} <span *ngFor="let con of option.contains; let last = last;">{{getString(con.name, "upper")}}{{con.del ? "*" : ""}}{{last ? "" : ", "}}</span></div>
                    </mat-radio-button>
                  </label>
                </mat-radio-group>
                <label class="clickable-area">
                  <mat-checkbox *ngIf="attribute.options.length===1;" [(ngModel)]="attrValues[item.id][attribute.id]">
                    <div><span class="atr-name">{{attribute.options[0].value}}</span><span style="font-size: 16px;" *ngIf="!itemIncluded"> {{attribute.options[0].formatted}}</span></div>
                    <div class="item-contains-sm" *ngIf="attribute.options[0].contains">{{getString("INNEHÅLLER", "upper")}} <span *ngFor="let con of attribute.options[0].contains; let last = last;">{{getString(con.name, "upper")}}{{con.del ? "*" : ""}}{{last ? "" : ", "}}</span></div>
                  </mat-checkbox>
                </label>
                <div class="item-separator" style="margin-top: 15px"></div>
              </div>
            </div>
          </div>
          <div *ngIf="!lastit" class="item-separator"></div>
        </div>
      </div>
    </div>

  </div>

  <div class="button-list" style="padding: 16px;margin-top: 32px;">
    <button mat-stroked-button mat-dialog-close style="color: #aaa;" *ngIf="!itemIncluded">{{getString("Stäng")}}</button>
    <div *ngIf="itemIncluded"></div>
    <mat-spinner class="add-item-spinner" *ngIf="isWorking" [diameter]="32"></mat-spinner>
    <button [disabled]="isWorking || itemIncludedNotSelected" mat-raised-button color="accent" (click)="continue()">{{getString("Fortsätt")}}</button>
  </div>

</mat-dialog-content>
