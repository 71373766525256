import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectDeliverTypeComponent } from './select-deliver-type/select-deliver-type.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { hasMoreThanOneDeliverType, VenueConfig } from '../../models/FSConfig';
import { Identity } from 'src/app/objects/Identity';
import { AppStateService } from '../../services/app-state.service';
import { UserSpecificConfig } from '../../models/Order';
import { AuthService } from "../../services/auth.service";
import { MatDialog } from '@angular/material/dialog';
import { DeactivateKioskModeDialogComponent } from './deactivate-kiosk-mode-dialog/deactivate-kiosk-mode-dialog.component';
import { HackUtils } from 'src/app/utils/Utils';
import { TranslateService } from "../../services/translate.service";
import { SimpleDialogComponent } from "../simple-dialog/simple-dialog.component";
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  venueId: number;
  isLoggedIn: boolean;
  isKiosk: boolean;
  anonOrdering: boolean;
  showCart: boolean;
  showFood: boolean;
  showDrinks: boolean;
  showLang: boolean;
  cfg: UserSpecificConfig;
  lang: string;
  isMonter: boolean;

  config: VenueConfig;
  showMenuSelector = false;
  identity: Identity;
  private sub: Subscription;

  constructor(private appState: AppStateService, private bottomSheet: MatBottomSheet, private auth: AuthService,
    public dialog: MatDialog, private translate: TranslateService, public orderService: OrderService) { }

  ngOnInit(): void {
    this.isKiosk = this.auth.isKioskMode();
    this.isMonter = this.auth.getKioskState() === "monter";
    this.sub = this.appState.observeAppState().subscribe(appState => {
      this.showCart = appState.order.items.length > 0;
      this.showFood = appState.menu.food?.filter(r => !r.mhide).length > 0;
      this.showDrinks = appState.menu.drinks?.filter(r => !r.mhide).length > 0;
      this.venueId = appState.venueId;
      this.config = appState.cfg;
      this.identity = appState.identity;
      this.cfg = appState.order.user_cfg;
      this.lang = appState.lang;
      this.showMenuSelector = hasMoreThanOneDeliverType(this.config);
      this.isLoggedIn = !appState.order.user_cfg.is_anon;
      this.anonOrdering = this.auth.isAnonOrderable(appState.order.user_cfg);
      this.showLang = this.translate.useTranslations;
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  openBottomSheet(): void {
    if (this.cfg.smart_table != null) {
      const dialogRef = this.dialog.open(SimpleDialogComponent, HackUtils.DLG({
        minWidth: "95vw",
        position: { top: "8px" },
        data: {
          title: "Lämna kundkonto?",
          message: "Du har tidigare scannat en QR-kod som är kopplad till ett kundkonto, vill du lämna?",
          posBtn: "Ja",
          negBtn: "Nej",
        }
      }));

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.orderService.leaveSmartTable().subscribe(res => {
            console.log(res);
          });
        }
      });
      return;
    }

    const bottomSheetRef = this.bottomSheet.open(SelectDeliverTypeComponent, { data: { cfg: this.config, closeable: true } });
    bottomSheetRef.afterDismissed().subscribe(key => {
      // TODO Close sidebar here
    });
  }


  deactivateKioskMode() {
    const dialogRef = this.dialog.open(DeactivateKioskModeDialogComponent, HackUtils.DLG({
      data: {}
    }));
  }

  getString(s: string) {
    return this.translate.single(s);
  }
}
