import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OrderService} from '../../../services/order.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {VenueConfig} from '../../../models/FSConfig';
import * as moment from 'moment';
import {UserSpecificConfig} from '../../../models/Order';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-select-time-dialog',
  templateUrl: './select-time-dialog.component.html',
  styleUrls: ['./select-time-dialog.component.css']
})
export class SelectTimeDialogComponent implements OnInit {

  title: string;
  config: UserSpecificConfig;
  errorMessage: string;
  isWorking = false;
  range: string;
  time: any;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<SelectTimeDialogComponent>, private order: OrderService, private snackbar: MatSnackBar) {
    order.orderSnapshot().subscribe(res => {
      this.config = res.user_cfg;
      const lim = this.getTakeawayLimits();
      this.range = lim.min.format("HH:mm") + " - " + lim.max.format("HH:mm");
      this.title = `Välj upphämntningstid`;
      const sug = this.roundDate(lim.min, moment.duration(5, "minutes"), "ceil");
      this.time = res.pickup_time ?? sug.format("HH:mm");
    });
  }

  ngOnInit(): void {
  }

  roundDate(date, duration, method) {
    return moment(Math[method]((+date) / (+duration)) * (+duration));
  }

  getTakeawayLimits() {
    const minMinutes = this.config.takeaway.cooking_time + this.config.takeaway.pickup_add;
    const minMoment = moment().add(minMinutes, 'm');
    const maxMoment = moment(this.config.takeaway.closing_time, "hh:mm");
    return {min: minMoment, max: maxMoment};
  }

  selectedTime() {
    const lim = this.getTakeawayLimits();
    const selMoment = moment(this.time, "hh:mm");
    console.log(selMoment);
    if (selMoment.isBetween(lim.min, lim.max)) {
      this.isWorking = true;
      this.order.updateCollected(undefined, this.time, undefined, undefined).subscribe(res => {
        this.isWorking = false;
        this.dialogRef.close();
      });
    } else {
      this.errorMessage = "Välj en tid mellan " + lim.min.format("HH:mm") + " - " + lim.max.format("HH:mm");
    }
  }

}

