import {Component, OnDestroy, OnInit} from '@angular/core';
import { UrlHistoryService } from '../../services/url.history.service';
import {MenuUtils, TimeUtils} from '../../utils/Utils';
import { AppStateService } from '../../services/app-state.service';
import { AppState } from '../../models/AppState';
import { Subscription } from 'rxjs';
import { MenuCategory, MenuSection } from '../../models/FSMenu';
import { MenuService } from '../../services/menu.service';
import * as moment from 'moment';
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-menu-of-the-week',
  templateUrl: './menu-of-the-week.component.html',
  styleUrls: ['./menu-of-the-week.component.css']
})
export class MenuOfTheWeekComponent implements OnInit, OnDestroy {
  isLoading = false;
  private app: AppState;
  private sub: Subscription;
  promoUrl: string;
  sections: MenuSection[];
  categories: MenuCategory[];
  filterWeekDay = 0;
  days = [];

  constructor(private identityService: UrlHistoryService, private appState: AppStateService,
              public translate: TranslateService) { }

  ngOnInit(): void {
    this.sub = this.appState.observeAppState().subscribe(appState => {
      this.app = appState;
      this.promoUrl = MenuUtils.getRootPromoImgUrl(this.app.menu?.food);
      this.filterWeekDay = moment().isoWeekday() - 1;
      this.categories = this.getCategories(appState.menu.food);
      const daysString = this.getString("Mån,Tis,Ons,Tor,Fre");
      const days = daysString.split(",");
      this.days = [];
      for (let i = 0; i < days.length; i++) {
        this.days.push({ name: days[i], ix: i });
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  backClicked() {
    this.identityService.navigateBack();
  }

  showDay(num: number) {
    this.filterWeekDay = num;
    const cats = this.getCategories(this.app.menu.food);
    this.categories = cats;
  }

  private getCategories(sections: MenuSection[]) {
    const cats = [];
    let d = moment();
    const dayDiff = this.filterWeekDay - d.isoWeekday() + 1;
    d = moment().add(dayDiff, "days");
    const itemFilter = MenuUtils.buildFilter(this.app.order);
    MenuUtils.updatePageHiddenFlag(sections, this.app.cfg, this.app.order, d, "whide", true, true, null, itemFilter);
    for (const menuSec of sections) {
      for (const menuCat of menuSec.categories) {
        if (!menuCat.whide) {
          cats.push(menuCat);
        }
      }
    }
    console.log(cats);
    return cats;
  }

  getString(s: string, pc: string = "normal") {
    return this.translate.single(s, pc);
  }

}
