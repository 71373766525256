import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MenuItemDialogComponent} from '../menu-item-dialog/menu-item-dialog.component';
import {MenuItem} from '../../models/FSMenu';
import {OrderService} from '../../services/order.service';
import {Subscription} from 'rxjs';
import {HackUtils, MenuUtils} from '../../utils/Utils';
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss']
})
export class MenuListItemComponent implements OnInit, OnDestroy {
  @Input() item: MenuItem;
  @Input() count: number;
  @Input() outOfStock: boolean;
  @Input() venueOpen: boolean;
  @Input() itemDisabled = false;
  @Input() showWhen = false;

  title: string;
  priceFormatted: string;
  desc: string;
  ingridients: string;
  readableWhen: string;
  private orderSub: Subscription;
  private sub: Subscription;

  constructor(public dialog: MatDialog, private orderService: OrderService, private translate: TranslateService,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.prep();
    if (this.showWhen) {
      this.readableWhen = MenuUtils.readableWhenDeadline(this.item);
    }

    this.sub = this.translate.translationsReceivedSubject.subscribe(() => {
      console.log("MenuListItemComponent translations received");
      this.cdRef.detectChanges();
      this.prep();
    });
  }

  private prep() {
    this.priceFormatted = this.orderService.getPriceForItem(this.item);
    this.title = this.item.name;
    this.desc = this.item.desc;
    this.ingridients = this.item.contains ? this.getString("INNEHÅLLER", "upper")+": " + this.item.contains.map(c => this.getString(c.name)).join(", ").toUpperCase() : null;
  }

  ngOnDestroy(): void {
    this.orderSub?.unsubscribe();
    this.sub?.unsubscribe();
  }

  openDialog() {
    if (!this.itemDisabled) {
      this.dialog.open(MenuItemDialogComponent, HackUtils.DLG({
        data: { item: this.item, outOfStock: this.outOfStock, cartPage: false, venueOpen: this.venueOpen, showWhen: this.showWhen }
      }));
    }
  }

  getString(s: string, pc="normal") {
    return this.translate.single(s, pc);
  }
}
