<mat-dialog-content class="item-dialog mat-typography" style="max-height: 80vh;">

  <div class="item-header" cdkFocusRegionstart>
    <div class="item-title">{{item.name }}</div>
  </div>

  <div class="item-content">
    <div *ngIf="item.pic">
      <img [src]="'https://storage.googleapis.com/heynow/danilo/place-holder.gif'" class="promo-image" [appHires]="item.pic">
    </div>
    <div class="item-desc">{{item.desc}}</div>
    <div class="item-contains" *ngIf="item.contains">
      {{getString("INNEHÅLLER", "upper")}} <span *ngFor="let con of item.contains; let last = last;" [ngClass]="selCons[con.id] ? 'strike' : ''">{{getString(con.name, "upper")}}{{con.del ? "*" : ""}}{{last ? "" : ", "}}</span>
    </div>
    <div *ngIf="showTweak && !cartPage">
      <button *ngIf="!tweaksOpen" mat-button color="primary" class="tweak-button" (click)="openTweaks()">{{tweakTitle}}</button>
      <div *ngIf="tweaksOpen" class="tweak-panel">
        <button mat-button color="primary" class="tweak-button2" (click)="openTweaks()">{{getString("Anpassa rätten (allergier och innehåll)")}}</button>
        <div class="tweak-contains">
          <label class="contains-cb clickable-area" *ngFor="let con of item.contains; let last = last;">
            <mat-checkbox *ngIf="con.del" [(ngModel)]="selCons[con.id]">{{getString("utan", "cap")}} {{getString(con.name)}}<span *ngIf="con.info"> - {{con.info}}</span></mat-checkbox>
            <mat-checkbox *ngIf="!con.del" [disabled]="true">{{getString("Går inte att få utan")}} {{getString(con.name)}}</mat-checkbox>
          </label>

          <div *ngIf="item.adjusts">
            <div class="adj-title">{{getString("Kan fås utan")}}:</div>
            <label class="contains-cb clickable-area" *ngFor="let adj of item.adjusts; let last = last;">
              <mat-checkbox [(ngModel)]="selAdjs[adj.id]">{{getString("utan", "cap")}} {{getString(adj.name)}}</mat-checkbox>
            </label>
          </div>

          <div *ngIf="hiddenAttributes().length>0" style="margin-top: 16px;">
            <div class="adj-title">Övriga anpassningar:</div>
            <label class="clickable-area" *ngFor="let attribute of hiddenAttributes(); let atri = index; let lastatr = last;">
              <mat-radio-group *ngIf="attribute.options.length>1" [(ngModel)]="attrValues[attribute.id]">
                <label class="clickable-area" style="margin-top: 8px;font-size: 16px;" *ngFor="let option of attribute.options; let opti = index; let lastopt = last;">
                  <mat-radio-button [value]="option.key"><span class="atr-name">{{option.value}}</span><span> {{option.formatted}}</span></mat-radio-button>
                </label>
              </mat-radio-group>
              <label class="clickable-area">
                <mat-checkbox *ngIf="attribute.options.length===1;" [(ngModel)]="attrValues[attribute.id]">
                  <div><span class="atr-name">{{attribute.options[0].value}}</span><span style="font-size: 16px;"> {{attribute.options[0].formatted}}</span></div>
                </mat-checkbox>
              </label>
              <div class="item-separator" style="margin-top: 16px"></div>
            </label>
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="item.attributes && !cartPage" style="margin-top: 16px;">
      <div *ngFor="let attribute of visibleAttributes(); let atri = index; let lastatr = last;">
        <mat-radio-group *ngIf="attribute.options.length>1" [(ngModel)]="attrValues[attribute.id]">
          <label class="clickable-area" style="margin-top: 8px;font-size: 16px;" *ngFor="let option of attribute.options; let opti = index; let lastopt = last;">
            <mat-radio-button [value]="option.key">
              <div><span class="atr-name">{{option.value}}</span><span> {{option.formatted}}</span></div>
              <div class="item-opt-desc" *ngIf="option.desc">{{option.desc}}</div>
              <div class="item-contains-sm" *ngIf="option.contains">{{getString("INNEHÅLLER", "upper")}} <span *ngFor="let con of option.contains; let last = last;">{{getString(con.name, "upper")}}{{con.del ? "*" : ""}}{{last ? "" : ", "}}</span></div>
            </mat-radio-button>
          </label>
        </mat-radio-group>
        <div>
          <mat-checkbox *ngIf="attribute.options.length===1;" [(ngModel)]="attrValues[attribute.id]" class="item-opt-cb-cont clickable-area">
            <div><span class="atr-name">{{attribute.options[0].value}}</span><span style="font-size: 16px;"> {{attribute.options[0].formatted}}</span></div>
            <div class="item-opt-desc" *ngIf="attribute.options[0].desc">{{attribute.options[0].desc}}</div>
            <div class="item-contains-sm" *ngIf="attribute.options[0].contains">{{getString("INNEHÅLLER", "upper")}} <span *ngFor="let con of attribute.options[0].contains; let last = last;">{{getString(con.name, "upper")}}{{con.del ? "*" : ""}}{{last ? "" : ", "}}</span></div>
          </mat-checkbox>
        </div>
        <div class="item-separator" style="margin-top: 15px"></div>
      </div>
    </div>

    <div class="item-price">{{priceFormatted}}</div>
  </div>

  <div *ngIf="readableWhen" class="when-panel">{{readableWhen}}</div>
  <div *ngIf="!kitchenOpen" class="out-of-stock">Köket är stängt</div>
  <div *ngIf="outOfStock" class="out-of-stock">{{getString("SLUTSÅLD")}}</div>
  <div *ngIf="!venueOpen" class="out-of-stock">Stängt för beställning</div>

  <div class="middle-list" *ngIf="cartPage && venueOpen">
    <button [disabled]="isWorking" mat-raised-button color="accent" (click)="subtractCopy()">-</button>
    <div class="count">{{getCount()}}</div>
    <button [disabled]="isWorking || outOfStock || hasPostAction" mat-raised-button color="accent" (click)="addCopy()">+</button>
  </div>

  <div *ngIf="venueOpen && kitchenOpen && !outOfStock && !cartPage && prepTime" class="delivery-time">ca {{prepTime}} min tillagningstid</div>

  <div class="button-list">
    <button mat-stroked-button mat-dialog-close>{{getString("Stäng")}}</button>
    <mat-spinner class="add-item-spinner" *ngIf="isWorking" [diameter]="32"></mat-spinner>
    <button *ngIf="venueOpen && kitchenOpen && !outOfStock && !cartPage" [disabled]="isWorking" mat-raised-button color="accent" (click)="addNewItem()">{{getString("Lägg till")}}</button>
  </div>

<!--  <div style="margin-top: 8px;" *ngIf="!cartPage">-->
<!--    <div style="background-color: #f39a83;padding: 12px;color: white;font-weight: bold;">-->
<!--      Redan i varukorgen-->
<!--    </div>-->
<!--    <div style="padding: 12px;">-->
<!--      <div *ngFor="let item of addedItems(); let i = index; let last = last;">-->
<!--        <div class="list-item" mat-ripple>-->
<!--          <div>-->
<!--            <div class="item-name2"><span style="font-weight: normal;">{{item.count}} x </span>{{item.name}}</div>-->
<!--            <div>{{item.customized}}</div>-->
<!--            <div>{{item.comment}}</div>-->
<!--          </div>-->
<!--          <div class="cart-price">{{item.formatted_item_total}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

</mat-dialog-content>
