import { Component, OnInit } from '@angular/core';
import {AppStateService} from "../../services/app-state.service";
import {UrlHistoryService} from "../../services/url.history.service";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {TranslateService} from "../../services/translate.service";
import {HackUtils} from "../../utils/Utils";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmLangDialogComponent} from "./confirm-lang-dialog/confirm-lang-dialog.component";

@Component({
  selector: 'app-select-lang',
  templateUrl: './select-lang.component.html',
  styleUrls: ['./select-lang.component.css']
})
export class SelectLangComponent implements OnInit {
  public isLoading: boolean;

  languages = [
    {code: "en", name: "English", flag: "🇺🇸"},
    {code: "zh", name: "汉语", flag: "🇨🇳"},
    {code: "es", name: "Español", flag: "🇪🇸"},
    {code: "fr", name: "Français", flag: "🇫🇷"},
    {code: "de", name: "Deutsch", flag: "🇩🇪"},
    {code: "pt", name: "Português", flag: "🇵🇹"},
    {code: "it", name: "Italiano", flag: "🇮🇹"},
    // {code: "ru", name: "Русский", flag: "🇷🇺"},
    {code: "ja", name: "日本語", flag: "🇯🇵"},
    {code: "ko", name: "한국어", flag: "🇰🇷"},
    {code: "ar", name: "العربية", flag: "🇸🇦"},
    {code: "tr", name: "Türkçe", flag: "🇹🇷"},
    {code: "vi", name: "Tiếng Việt", flag: "🇻🇳"},
    {code: "nl", name: "Dutch", flag: "🇳🇱"},
    {code: "sv", name: "Svenska", flag: "🇸🇪"},
    {code: "da", name: "Dansk", flag: "🇩🇰"},
    {code: "fi", name: "Suomi", flag: "🇫🇮"},
    {code: "no", name: "Norsk", flag: "🇳🇴"},
    {code: "is", name: "Íslenska", flag: "🇮🇸"},
    {code: "he", name: "עברית", flag: "🇮🇱"},
    {code: "hi", name: "हिन्दी", flag: "🇮🇳"},
    {code: "id", name: "Bahasa Indonesia", flag: "🇮🇩"},
    {code: "mr", name: "मराठी", flag: "🇮🇳"},
    {code: "tl", name: "Tagalog", flag: "🇵🇭"},
    {code: "pl", name: "Polski", flag: "🇵🇱"},
    {code: "sk", name: "Slovenský", flag: "🇸🇰"},
    {code: "th", name: "ภาษาไทย", flag: "🇹🇭"},
    {code: "uk", name: "Українська", flag: "🇺🇦"},
  ];

  constructor(private appState: AppStateService, private identityService: UrlHistoryService,
              private router: Router, private authService: AuthService, private translate: TranslateService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  back() {
    this.identityService.navigateBack();
  }

  getString(s: string, postCase = "normal") {
    return this.translate.single(s, postCase);
  }

  selectLang(lang: any) {
    if (lang.code === "sv") {
      localStorage.setItem("collinsLang", lang.code);
      window.location.reload();
      return;
    }

    const dialogRef = this.dialog.open(ConfirmLangDialogComponent, HackUtils.DLG({
      data: { lang },
    }));
    return dialogRef.afterClosed();
  }

}
