import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {environment} from "../../environments/environment";
import {flatMap} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {PhrasePair} from "../models/Translation";

@Injectable({
  providedIn: 'root'
})
export class GenjiService {

  constructor(protected http: HttpClient) { }

  private getOptions(): Observable<any> {
    const headers = new HttpHeaders({Authorization: "collins_123"});
    return of({headers, observe: "body", responseType: 'json'});
  }

  private post<T>(endpointUrl: string, body?: any): Observable<T> {
    const baseUrl = environment.genji_url;
    const url = `${baseUrl}/${endpointUrl}`;
    return this.getOptions().pipe(flatMap(o => this.http.post<T>(url, body, o) as unknown as Observable<T>));
  }

  public translate(phrasePairs: PhrasePair[], lang: string): Observable<any> {
    const body = {phrase_pairs: phrasePairs, lang, action: "translate"};
    return this.post<any>("facade", body );
  }

}
