<mat-dialog-content class="item-dialog mat-typography">

  <div class="item-header">
    <div class="item-title">🙋 ➡️ {{table}}</div>
  </div>

  <div class="item-content" style="margin-top: 16px;">
    <div class="payment-button" mat-ripple (click)="call()">
      <div class="payment-button-text">{{translate.single("Kalla på servitör")}}</div>
    </div>
  </div>

  <div class="button-list" style="padding: 16px;margin-top: 32px;">
    <button mat-stroked-button mat-dialog-close>{{translate.single("Stäng")}}</button>
    <mat-spinner class="add-item-spinner" *ngIf="isWorking" [diameter]="32"></mat-spinner>
  </div>

</mat-dialog-content>

