import * as firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import * as moment from 'moment';

export interface FSMenu {
  data: string;
  venue_id: number;
}

export interface FSMenuAddendum {
  data: string;
  venue_id: number;
  date?: Timestamp;
}

export interface FSMenu2 {
  // the visual structure
  pages: string;
  // the product database
  products: string;
  // menu packages
  packages: string;
  // meta info
  meta: string;
  venue_id: number;
}

export interface MenuStructure {
  pages: MenuPage[];
  food: MenuSection[];
  drinks: MenuSection[];
  donation_items: MenuItem[];
}

export interface MenuPage {
  id: string;
  name: string;
  pic: string;
  sections: MenuSection[];
  index: number;
}

export interface MenuSection {
  id: string;
  name: string;
  desc: string;
  pic: string;
  categories: MenuCategory[];
  index: number;

  mhide?: boolean;
  lang?: string;
}

export interface MenuCategory {
  id: string;
  type: number;
  name: string;
  desc: string;
  pic: string;
  class: string;
  items: MenuItem[];
  index: number;
  phase: number;

  mhide?: boolean;
  whide?: boolean;

  lang?: string;
}

export interface MenuItem {
  id: string;
  name: string;
  price: string;
  prices?: MenuItemPriceListPrices[];
  desc: string;
  formatted: string;
  class: string;
  ic: string;
  alc: boolean;
  pic: string;
  vat: number;
  phase: number;
  phaseid: string;
  wait: number;
  use_scale: boolean;
  tare: number;
  pc: string;
  hidden: boolean;
  when: WhenFilter[];
  pushes: MenuItemPush[];
  form: "f"|"d";
  deadline?: DeadlineFilter;
  post_actions?: string;

  attributes: MenuItemAttribute[];
  contains: MenuItemContain[];
  adjusts: MenuItemAdjust[];
  tags: string[];
  mhide?: boolean;
  whide?: boolean;
  lang?: string;
  package?: MenuPackage;

  // Translucent
  currentOrderCount: number;
  currentStockState: boolean;
}

export interface MenuPackage {
  included: MenuPackageItem[];
  display: string[];
  desc: string;
  items: string[];
  index: number;
  lang?: string;
}

export interface MenuPackageItem {
  name: string;
  id: string;
  price: number;
  display: string[];
}

export interface MenuItemPriceListPrices {
  name: string;
  price: string;
}

export interface MenuItemAttribute {
  id: string;
  options: MenuItemOption[];
  hidden: boolean;
}

export interface MenuItemOption {
  price: number;
  price_diff: string;
  key: string;
  formatted: string;
  value: string;
  desc: string;
  default: boolean;
  contains: MenuItemContain[];
  lang?: string;
}

export interface MenuItemContain {
  del: boolean;
  id: string;
  name: string;
  info: string;
}

export interface MenuItemAdjust {
  id: string;
  name: string;
}

export let EMPTY_WHEN_FILTER = {};
export interface WhenFilter {
  // [1,2,3], bt30: "11:00", et30: "13:00", bd: "20180307", ed: "20180407"
  bt30?: string;
  et30?: string;
  wds?: number[];
  bd?: string;
  ed?: string;
}

export interface DeadlineFilter extends WhenFilter {
  rel_days?: number;
  rel_hours?: number;
}

export interface MenuItemPush {
  index: number;
  title: string;
  groups: MenuItemPushGroup[];
  included: boolean;
}

export interface MenuItemPushGroup {
  title: string;
  items: string[];

  // Resolved in run time
  menuItems: MenuItem[];
}

export interface MenuAddendum {
  items: MenuAddendumItem[];
}

export interface MenuAddendumItem {
  id: string;
  name: string;
  inventory_count: number;
  is_inventoried: boolean;
  out_of_stock: boolean;
  updated: string;
}

export class DateSpan {
  start: moment.Moment;
  end: moment.Moment;

  constructor(start: moment.Moment, end: moment.Moment) {
    this.start = start;
    this.end = end;
  }
}

export class PostActions {
  postActions: PostAction[];

  constructor(postActionsJson?: string) {
    if (postActionsJson) {
      this.postActions = JSON.parse(postActionsJson) as PostAction[];
    }
  }

  getPostAction(action: string): PostAction {
    return this.postActions?.find(pa => pa.action === action);
  }
}

export class PostAction {
  action: string;
  card: string;
}
