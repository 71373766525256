<mat-dialog-content class="item-dialog mat-typography">

  <div class="item-header">
    <div class="item-title">{{translate.single("Ange rabattkod")}}</div>
  </div>

  <div class="item-content webcode-content">
    <div style="margin-top: 8px;margin-bottom: 16px;">
      <input [disabled]="sending" type="text" class="webcode-input" [(ngModel)]="code" [placeholder]="translate.single('RABATTKOD')" autofocus (input)="onInput($event)" (keyup)="onKeyup($event)" style="text-align: center;">
    </div>
    <div style="height: 32px;">
      <div *ngIf="error && !sending" style="color: #7d7d7d;font-size: 16px;">{{errorMessage}}</div>
      <mat-spinner *ngIf="sending" class="add-item-spinner" [diameter]="32"></mat-spinner>
    </div>
  </div>

  <div class="button-list" style="padding: 16px;margin-top: 0px;">
    <button mat-stroked-button mat-dialog-close>{{translate.single("Stäng")}}</button>
  </div>

</mat-dialog-content>

