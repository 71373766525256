<div>
  <div *ngIf="!closeable" style="margin: 8px;font-size: 16px;display: flex;flex-direction: column;align-items: center;">
    <div style="font-weight: bold">{{getString("Välkommen till")}} {{venueName}}</div>
    <div style="font-size: 15px;margin-top: 8px;">{{getString("Välj meny nedan")}}</div>
  </div>
  <div *ngFor="let it of list" (click)="openLink($event,it.key)" class="nice-button" mat-ripple>
    <div style="color:#f39a83;font-weight: bold;">{{getString(it.title)}}</div>
    <div>{{getString(it.desc)}}</div>
  </div>
  <div *ngIf="closeable" class="close-div">
    <button mat-stroked-button style="" (click)="closeDeliverTypeSelector()" >{{getString("Stäng")}}</button>
  </div>
</div>

<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
