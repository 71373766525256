<mat-dialog-content class="item-dialog mat-typography">
    <div class="item-header">
        <div class="item-title">{{getString("Välj totalbelopp")}}: {{orderAmount | number : '1.2-2'}} kr</div>
      </div>

  <div class="item-content">
    <div  class="tip-panel">
        <input type="number" min={{orderAmount}} class="tip-input" [(ngModel)]="totalAmount" (keyup)=setupSubmit()  autofocus style="text-align: center;">
        <div type="submit" *ngIf="enabled" mat-ripple class="payment-button tip-button" (click)=submit()>{{btnText}}</div>
        <div type="submit" *ngIf="!enabled" class="payment-button tip-button tip-button-disabled" (click)=submit()>{{btnText}}</div>
    </div>
  </div>

  <div class="button-list" style="padding: 16px;">
    <button mat-stroked-button mat-dialog-close>{{getString("Stäng")}}</button>

  </div>

</mat-dialog-content>
