import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {from, Observable, of} from 'rxjs';
import { FSMenu, FSMenuAddendum, MenuAddendum, MenuStructure } from '../models/FSMenu';
import { map } from 'rxjs/operators';
import { FSConfig, FSConnectAccount, VenueConfig } from '../models/FSConfig';
import { AccountEvent } from '../models/AccountEvent';
import { FSPayment } from '../models/PaymentModels';
import { AngularFirestoreDocument } from '@angular/fire/firestore/document/document';
import {Translation} from "../models/Translation";
import firebase from "firebase";

@Injectable({
  providedIn: 'root'
})
export class FireService {

  constructor(private afs: AngularFirestore) { }

  observeMenuStructure(venueId: string): Observable<MenuStructure> {
    return this.afs.doc<FSMenu>(`menus/${venueId}:menu`).valueChanges().pipe(
      map<FSMenu, MenuStructure>(menu => {
        return menu ? JSON.parse(menu.data) : null;
      })
    );
  }

  observeMenuAddendum(venueId: string): Observable<MenuAddendum> {
    //return of({items: []});
    return this.afs.doc<FSMenuAddendum>(`addendums/${venueId}`).valueChanges().pipe(
      map<FSMenuAddendum, MenuAddendum>(addendum => {
        return addendum ? JSON.parse(addendum.data) : null;
      })
    );
  }

  observeTranslations(venueId: number, lang: string): Observable<Translation[]> {
    console.log("observeTranslations", venueId, lang);
    return this.afs.collection<Translation>("translations", ref => ref
      .where("venue_id", "==", venueId)
      .where("lang", "==", lang)
    ).valueChanges();
  }

  getTranslation(venueId: number, lang: string, strId: string): Observable<Translation> {
    return this.afs.doc<Translation>(`translations/${lang}:${strId}:${venueId}`).get().pipe(
      map<firebase.firestore.DocumentSnapshot, Translation>( snapshot => {
        return snapshot.data() as Translation;
      })
    );
  }

  observeTranslation(venueId: number, lang: string, strId: string): Observable<Translation> {
    return this.afs.doc<Translation>(`translations/${lang}:${strId}:${venueId}`).valueChanges();
  }

  observeVenueConfig(venueId: string): Observable<VenueConfig> {
    return this.afs.doc<FSConfig>(`configs/${venueId}:cfg`).valueChanges().pipe(
      map<FSConfig, VenueConfig>(venueConfig => {
        return venueConfig ? JSON.parse(venueConfig.data) : null;
      })
    );
  }

  observeEvents(chatId: number): Observable<AccountEvent[]> {
    return this.afs.collection<AccountEvent>("account_events", ref => ref
      .where("chat_id", "==", chatId)
      .orderBy("date", "asc")
    ).valueChanges();
  }

  observeEventsWindow(chatId: number, newerThan: Date): Observable<AccountEvent[]> {
    return this.afs.collection<AccountEvent>("account_events", ref => ref
      .where("chat_id", "==", chatId)
      .where("date", ">", newerThan)
      .orderBy("date", "asc")
    ).valueChanges();
  }

  observePayment(receiptKey: string): Observable<FSPayment> {
    return this.afs.doc<FSPayment>(`payments/${receiptKey}`).valueChanges();
  }

  observeLivesplit(table: string, venueId: number): Observable<any> {
    return this.afs.doc(`livesplit/${table}:${venueId}`).valueChanges();
  }

  getLivesplitDocRef(table: string, venueId: number): AngularFirestoreDocument {
    return this.afs.doc(`livesplit/${table}:${venueId}`);
  }

  runTransaction<T>(updateFunction: (transaction: any) => Promise<T>): Promise<T> {
    return this.afs.firestore.runTransaction(updateFunction);
  }

  observeConnectAccount(documentId: string): Observable<FSConnectAccount> {
    console.log(`Observing: connect_accounts/${documentId}`);
    return this.afs.doc<FSConnectAccount>(`connect_accounts/${documentId}`).valueChanges();
  }

  observeMiniSplit(table: string, venueId: number): Observable<any> {
    return this.afs.doc(`minisplit/${table}:${venueId}`).valueChanges();
  }

  // observeEvents(chatId: number): Observable<AccountEvent[]> {
  //   return this.afs.collection<AccountEvent>("account_events", ref => ref
  //     .where("chat_id", "==", chatId)
  //     .orderBy("date", "desc")
  //     ).stateChanges().pipe(
  //       map<DocumentChangeAction<AccountEvent>[], AccountEvent[]>( snapshot => {
  //         return snapshot.map( doc => doc.payload.doc.data() as AccountEvent );
  //       })
  //     );
  // }
}
