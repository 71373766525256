<div *ngIf="item.class!=='promo'" class="list-item" (click)="openDialog()">
  <div *ngIf="outOfStock" class="stock">{{getString("SLUTSÅLD")}}</div>
  <div class="title" [ngClass]="{'strikethrough':outOfStock}"><span class="badge ng-binding ng-scope" style="float:none" *ngIf="count>0">{{count}}</span>{{title}} - <span class="price">{{priceFormatted}}</span></div>
  <div class="desc">{{desc}}</div>
  <div class="ing">{{ingridients}}</div>
  <div class="ing" *ngIf="readableWhen">{{readableWhen}}</div>
</div>
<div *ngIf="item.class==='promo'">
  <img [src]="'https://storage.googleapis.com/heynow/danilo/place-holder.gif'" class="promo-image" [appHires]="item.pic">
</div>
