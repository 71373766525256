import { getLocaleDateTimeFormat } from "@angular/common";
import * as moment from "moment";

export enum UserType {
    Signin = "auth",
    Messenger = "messenger"
}

export enum DeliveryType {
    Table = "table",
    Takeaway = "takeaway",
    Pickup = "pickup",
    Foodcourt = "foodcourt",
    Catering = "catering",
    Roomservice = "roomservice"
}
export interface AuthUserData {
    user: User;
    chatId: number;
}
export class Identity {
    venueId: string = undefined;
    magicLink: string = undefined;
    created: number = Date.now();

    // In order
    userType: UserType = undefined;
    table: string = undefined;
    userId: string = undefined;
    chatId: number = undefined;
    user: User = undefined;
    deliveryType: string = undefined;

    // get resolved(): boolean {
    //     return this.chatId != null;
    // }

    // public expired() {
    //     const diff = moment().diff(this.created, 'minutes');
    //     return diff > 60;
    // }

    // public isSignin(): boolean {
    //     return this.userType === UserType.Signin;
    // }

    // public isMessenger(): boolean {
    //     return this.userType === UserType.Messenger;
    // }

    // public getAuthUserData(): AuthUserData {
    //     return {user: this.user, chatId: this.chatId};
    // }

    // public setAuthUserData(identity: Identity): void {
    //     this.user = identity.user;
    //     this.userId = identity.user?.userId;
    //     this.chatId = identity.chatId;
    //     this.userType = identity.userType;
    //     this.magicLink = identity.magicLink;
    //     this.created = identity.created;
    // }

    // public equals(other: Identity): boolean {
    //     const master = Object.keys(this) >= Object.keys(other) ? this : other;
    //     const slave = Object.keys(this) >= Object.keys(other) ? other : this;
    //     for (const prop in master) {
    //         if (!master.hasOwnProperty(prop)) { continue; }
    //         if (prop === "user") {
    //             if (master[prop]?.userId !== slave[prop]?.userId) { return false; } else { continue; }
    //         }
    //         if (!slave.hasOwnProperty(prop)) { return false; }
    //         if (master[prop] !== slave[prop]) { return false; }
    //     }
    //     return true;
    // }
    //
    // public isNew(): boolean {
    //     for (const prop in this) {
    //         if (!this.hasOwnProperty(prop)) { continue; }
    //         if (prop === "created") { continue; }
    //         if (this[prop] != null) { return false; }
    //     }
    //     return true;
    // }

}

export class User {
    constructor(public displayName: string, public email: string, public userId: string, public creationTime: string) {
    }
}

export interface UrlDiff {
    venueId: boolean;
    magicLink: boolean;
    userType: boolean;
}
