import {Component, Input, OnInit} from '@angular/core';
import {PrintInstruction} from "../../models/print-job";

@Component({
  selector: 'app-dossier-viewer',
  templateUrl: './dossier-viewer.component.html',
  styleUrls: ['./dossier-viewer.component.css']
})
export class DossierViewerComponent implements OnInit {

  @Input() printInstructions: PrintInstruction[];

  constructor() { }

  ngOnInit(): void {
    this.prepareInstructions();
  }

  private prepareInstructions() {
    for (const pi of this.printInstructions) {
      const t = pi.text;
      if (t) {
        if (t.style === "bold") {
          pi.tclass = "dos-bold";
        }
        if (t.center && (!t.layout || t.layout === "0,1,0")) {
          if (t.center.startsWith("----")) {
            pi.temp = "div";
          } else {
            pi.temp = "center";
          }
        } else {
          if (t.layout === "5,5,8") {
            pi.temp = "row5-5-8";
          } else {
            pi.temp = "row";
          }
        }
        if (t.left === "") { t.left = "&nbsp;"; }
        if (t.center === "") { t.center = "&nbsp;"; }
        if (t.right === "") { t.right = "&nbsp;"; }
        // Hack to fix space indent
        if (t.left?.startsWith("        ")) { t.left = t.left.replace("        ", "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"); }
      }
    }
  }

}
