import { Pipe, PipeTransform } from '@angular/core';
import {MenuItemAttribute} from '../models/FSMenu';

@Pipe({
  name: 'filterHiddenAtr'
})
export class FilterHiddenAtrPipe implements PipeTransform {

  transform(attributes: MenuItemAttribute[], ...args: unknown[]): unknown {
    return attributes?.filter(f => !f.hidden);
  }

}
