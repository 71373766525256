<mat-dialog-content class="item-dialog mat-typography">

  <div class="item-header">
    <div class="item-title">{{getString("Ange webbkoden")}}</div>
  </div>

  <div class="item-content webcode-content">
    <div style="margin-top: 8px;margin-bottom: 16px;">
      <input [disabled]="sending" type="text" class="webcode-input" [(ngModel)]="webcode" [placeholder]="getString('WEBBKOD', 'upper')" autofocus (keyup)="onKeyup($event)" style="text-align: center;">
    </div>
    <div style="height: 32px;">
      <div *ngIf="error && !sending" style="color: red;font-size: 16px;">{{getString("Felaktig webbkod")}}</div>
      <mat-spinner *ngIf="sending" class="add-item-spinner" [diameter]="32"></mat-spinner>
    </div>
    <div class="hint-text">
      <span>{{getString("Du kan även skanna QR-kod med din kamera")}}</span>
    </div>
  </div>

  <div class="button-list" style="padding: 16px;margin-top: 0px;">
    <button mat-stroked-button mat-dialog-close>{{getString("Stäng")}}</button>
  </div>

</mat-dialog-content>
