<mat-dialog-content class="item-dialog mat-typography">

  <div class="item-header">
    <div class="item-title">{{title}}</div>
  </div>

  <div class="item-content">
    <div style="text-align: center;margin-top: 16px;margin-bottom: 16px;font-size: 15px;">{{getString("Hur många personer skall dela?")}}</div>
    <div class="payment-button" mat-ripple *ngFor="let btn of buttons" (click)="btnClicked(btn)">
      <div class="payment-button-text">{{btn.text}}</div>
    </div>
  </div>

  <div class="button-list" style="padding: 16px;margin-top: 32px;">
    <button mat-stroked-button mat-dialog-close>{{getString("Stäng")}}</button>
  </div>

</mat-dialog-content>
