<div *ngIf="message" class="voucher-message"><i>{{message}}</i></div>
<div *ngIf="type==='feed'" class="voucher-name">🎟️ {{getString(name)}}</div>
<div *ngIf="type!=='feed'" class="voucher-name-l">{{getString(name)}}</div>
<div class="viewport">
  <div class="drag-area">
    <div class="drag-msg" [ngClass]="{'voucher-used': voucher.consumed}">{{voucher.consumed ? "Förbrukad" : "Activate 1 min"}}</div>
    <div class="drag-me" [ngClass]="{'voucher-used': voucher.consumed}" cdkDrag cdkDragBoundary=".drag-area" (cdkDragEnded)="dragEnd($event)" (cdkDragStarted)="dragStart($event)">
      <img class="drag-handle" src="https://storage.googleapis.com/heynow/media/arrow-circle-right-coral.svg">
    </div>
  </div>
</div>
