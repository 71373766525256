<div class="sidebar-container">
  <mat-nav-list>
    <mat-list-item *ngIf="showFood" routerLink="{{venueId}}/menu/food" routerLinkActive="active-link">
      <mat-icon>restaurant</mat-icon>
      <div>{{getString("Mat")}}</div>
    </mat-list-item>

    <mat-list-item *ngIf="showDrinks" routerLink="{{venueId}}/menu/drinks" routerLinkActive="active-link">
      <mat-icon>wine_bar</mat-icon>
      <div>{{getString("Dryck")}}</div>
    </mat-list-item>

    <mat-list-item *ngIf="showCart" routerLink="{{venueId}}/cart" routerLinkActive="active-link">
      <mat-icon>shopping_basket</mat-icon>
      <div>{{getString("Varukorg")}}</div>
    </mat-list-item>

    <mat-divider *ngIf="isLoggedIn" style="margin-top: 8px;margin-bottom: 8px;"></mat-divider>

    <mat-list-item *ngIf="(cfg?.payment?.has_payable_orders || cfg?.dt == 'table') && !isKiosk" routerLink="{{venueId}}/livesplit" routerLinkActive="active-link">
      <mat-icon>receipt_long</mat-icon>
      <div>{{getString("Betala nota")}}</div>
    </mat-list-item>

    <mat-list-item *ngIf="(isLoggedIn || anonOrdering) && cfg?.user_type === 'auth' && !isMonter" routerLink="{{venueId}}/feed" routerLinkActive="active-link">
      <mat-icon>chat_bubble_outline</mat-icon>
      <div>{{getString("Händelser")}}</div>
    </mat-list-item>

    <mat-list-item *ngIf="(isLoggedIn) && config?.payment?.prepaid_name" routerLink="{{venueId}}/prepaid" routerLinkActive="active-link">
      <mat-icon>card_membership</mat-icon>
      <div>{{config?.payment?.prepaid_name}}</div>
    </mat-list-item>

    <mat-list-item *ngIf="isLoggedIn" routerLink="{{venueId}}/account" routerLinkActive="active-link">
      <mat-icon>account_box</mat-icon>
      <div>{{getString("Mitt konto")}}</div>
    </mat-list-item>

    <mat-list-item *ngIf="isLoggedIn" routerLink="{{venueId}}/receipts" routerLinkActive="active-link">
      <!-- TODO Icon? -->
      <mat-icon>receipt</mat-icon>
      <div>{{getString("Mina kvitton")}}</div>
    </mat-list-item>

    <mat-divider style="margin-top: 8px;margin-bottom: 8px;"></mat-divider>

    <mat-list-item *ngIf="config?.show_overview" routerLink="{{venueId}}/week" routerLinkActive="active-link">
      <mat-icon>calendar_view_month</mat-icon>
      <div>{{getString("Veckans meny")}}</div>
    </mat-list-item>

    <mat-list-item *ngIf="showMenuSelector && !isKiosk" (click)="openBottomSheet()">
      <mat-icon>menu_book</mat-icon>
      <div>{{getString("Byt meny")}}</div>
    </mat-list-item>

    <mat-list-item *ngIf="showLang" routerLink="{{venueId}}/language" routerLinkActive="active-link">
      <mat-icon>translate</mat-icon>
      <div>{{getString("Språk")}} ({{lang}})</div>
    </mat-list-item>

  </mat-nav-list>
  <div *ngIf="isKiosk" (click)="deactivateKioskMode()" class="deactivate-kioskMode">HEYNOW KIOSK</div>
</div>
