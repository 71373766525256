<main>
  <section *ngIf="!isLoading">
    <div class="cart-container">
      <div class="back" (click)="backClicked()">
        <mat-icon style="font-size: 27px">navigate_before</mat-icon>
        <div style="margin: 3px 0 0 5px;">{{getString("Mina kvitton","upper")}}</div>
      </div>

      <div *ngFor="let item of receipts; let i = index; let last = last;">
        <div class="row" mat-ripple routerLink="../receipt/{{item.key}}"><div>{{item.paid_date}}</div><div>{{item.amount|number:'.2'}} kr</div></div>
        <div *ngIf="!last" class="item-separator"></div>
      </div>

      <p *ngIf="receipts.length === 0"><br/>Här visas normalt dina kvitton. Eftersom att du inte har köpt något ännu så finns inget att visa. 🙂 </p>

    </div>
  </section>
</main>
<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
