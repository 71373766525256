import { Injectable } from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import { MenuStructure } from '../models/FSMenu';
import { VenueConfig } from '../models/FSConfig';
import { Order } from '../models/Order';
import {first, map} from 'rxjs/operators';
import { MenuService } from './menu.service';
import { AppState } from '../models/AppState';
import { AuthService } from './auth.service';
import {Identity} from '../objects/Identity';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private firstTime = true;
  private venueId: string;
  private identity: Identity;
  private appstateSubject = new ReplaySubject<AppState>(1);
  private sub: Subscription;
  private identSub: Subscription;

  constructor(private menu: MenuService, private auth: AuthService, private config: ConfigService) { }

  private setupObserver(venueId: string, identity: Identity) {
    this.sub = this.menu.observeMenuConfigOrder(venueId).pipe(
      map<[MenuStructure, VenueConfig, Order], AppState>(res => {
        const [menu, cfg, order] = res;
        const appState = new AppState();
        appState.menu = menu;
        appState.cfg = cfg;
        appState.order = order;
        appState.identity = identity; //TODO this seems not be used anywhere?
        appState.venueId = Number(venueId);
        appState.lang = localStorage.getItem("collinsLang") || "sv";
        return appState;
      })
    ).subscribe(as => {
      this.appstateSubject.next(as);
    });
  }

  private setupConfigObsFirst(venueId: string) {
    this.config.observeConfig(venueId).pipe(first()).subscribe(r => {this.setupObserver(this.venueId, this.identity);});
  }

  private initStateService() {
    this.identSub?.unsubscribe();
    this.identSub = this.auth.observeIdentity().subscribe(identity => {
      if (identity != null && identity.venueId != null) {
        if (identity.venueId !== this.venueId) {
          console.log(`Start state service... ${identity.venueId}`);
          this.venueId = identity.venueId;
          this.identity = identity;
          this.setupConfigObsFirst(this.venueId);
          //this.setupObserver(this.venueId, this.identity);
        }
      } else {
        console.log("Stop state service...");
        this.venueId = null;
        this.identity = null;
        this.sub?.unsubscribe();
      }
    });
  }

  observeAppState(): Observable<AppState> {
    if (this.identSub == null) {
      this.initStateService();
    }
    return this.appstateSubject;
  }

  shouldRedirectToOtherTab(pageId: string, appState: AppState, emptyMenu: boolean = false): string {
    if (this.firstTime) {
      this.firstTime = false;
      if (appState.cfg.start_tab != null && appState.cfg.start_tab !== pageId) {
        return appState.cfg.start_tab;
      }
    }
    if (pageId === "food" && appState.menu.food?.length === 0 && appState.menu.drinks?.length > 0) {
      console.log("Trying to view food tab, but menu contains no food items, redirect to drinks");
      return "drink";
    }
    if (emptyMenu && pageId === "food" && appState.menu.drinks?.length > 0) {
      console.log("Trying to view food tab, but menu contains no available food items, redirecting to drinks");
      return "drink";
    }
    return null;
  }
}
