<main>
  <section>
    <div *ngIf="!isLoading" class="container">
      <div class="back" (click)="backClicked()">
        <mat-icon style="font-size: 27px">navigate_before</mat-icon>
        <div style="margin: 3px 0 0 2px;">🎟️ Biljett</div>
      </div>
    </div>

    <div style="padding: 0 32px 32px 32px;">
      <div *ngIf="receiptData">
        <div class="top-img"><img src="https://storage.googleapis.com/heynow/media/chef_phone3.png"></div>

        <div *ngIf="receiptData.mvoucher">
          <div>
            <div *ngFor="let o of receiptData.mvoucher">
              <app-dragger [voucher]="o" [venueId]="venueId"></app-dragger>
            </div>
            <div class="r-panel-info-sm">
              <p>{{getString(translate.STRING_ACTIVATE1)}}</p>
              <p>{{getString(translate.STRING_ACTIVATE2)}}</p>
            </div>
          </div>
        </div>
        <div *ngIf="!receiptData.mvoucher">
          <div style="padding-top:16px;padding-left: 0;padding-right: 0;color: #404040;font-size: 15px;">
            <div>
              <p>Biljett använd</p>
              <p>Kontakta personal om du har frågor</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</main>
<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
