import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appPhoneNumberValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: PhoneNumberValidatorDirective,
    multi: true
  }]
})
export class PhoneNumberValidatorDirective implements Validator {

  validate(control: AbstractControl) : {[key: string]: any} | null {
    console.log("control: " + control);
    if (control.value) {
      console.log("Apa: " + control.value);
      // Remove spaces and dashes
      let v = control.value.replace(/[- +()]/g, '');
      const pattern = new RegExp('^[0-9]{10,14}$');
      if (!pattern.test(v)) {
        return { 'phoneNumberInvalid': true };
      }
    }
    return null;
  }
}
