import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReceiptDetails } from '../../../models/ReceiptDetails';
import { ApiService } from '../../../services/api.service';
import { UrlHistoryService } from '../../../services/url.history.service';
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-voucher-activation',
  templateUrl: './voucher-activation.component.html',
  styleUrls: ['./voucher-activation.component.css']
})
export class VoucherActivationComponent implements OnInit {
  receiptData: ReceiptDetails;
  isLoading = true;
  receiptId: string;
  venueId: string;

  constructor(private route: ActivatedRoute, private api: ApiService, private identityService: UrlHistoryService, public translate: TranslateService) { }

  ngOnInit(): void {
    console.log("VoucherActivationComponent init");
    this.route.paramMap.subscribe(data => {
      this.receiptId = data.get("receipt_id");
      this.venueId = data.get("venue_id");
      console.log("Getting receipt_id", this.receiptId);
      this.loadReceipt();
    });
  }

  loadReceipt(): void {
    this.api.getReceiptDetails(this.venueId, this.receiptId, false).subscribe(r => {
      console.log("Loaded receipt", r);
      this.isLoading = false;
      this.receiptData = r;
    });
  }

  backClicked() {
    this.identityService.navigateBack("/receipts");
  }

  getString(s: string) {
    return this.translate.single(s);
  }

}
