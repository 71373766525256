import { Component, OnDestroy, OnInit } from '@angular/core';
import { UrlHistoryService } from '../../../services/url.history.service';
import { AppState } from '../../../models/AppState';
import { Subscription } from 'rxjs';
import { AppStateService } from '../../../services/app-state.service';
import { AuthorizedApiService } from '../../../services/authorized-api.service';
import { filter, first, flatMap, tap } from 'rxjs/operators';
import { FireService } from '../../../services/fire.service';
import { OrderService } from '../../../services/order.service';
import { ConnectCodeResponse } from '../../../models/Order';

@Component({
  selector: 'app-connect-messenger',
  templateUrl: './connect-messenger.component.html',
  styleUrls: ['./connect-messenger.component.css']
})
export class ConnectMessengerComponent implements OnInit, OnDestroy {
  app: AppState;
  code: string;
  connectCode: ConnectCodeResponse;
  isConnected = false;
  public isLoading = true;
  private sub: Subscription;
  private subCA: Subscription;

  constructor(private appState: AppStateService, private identityService: UrlHistoryService,
              private api: AuthorizedApiService, private fire: FireService, private orderService: OrderService) { }

  ngOnInit(): void {
    this.sub = this.appState.observeAppState().pipe(
      first(),
      tap(appState => this.app = appState),
      flatMap(appState => this.api.getConnectCode())
    ).subscribe(code => {
      console.log("GOT STUFF");
      this.isLoading = false;
      this.connectCode = code;
      this.code = code.code;
      this.startObservingConnectAccountStatus();
    });
  }

  ngOnDestroy() {
    if (this.sub) { this.sub.unsubscribe(); }
    if (this.subCA) { this.subCA.unsubscribe(); }
  }

  back() {
    this.identityService.navigateBack("/account");
  }

  private startObservingConnectAccountStatus() {
    this.subCA = this.fire.observeConnectAccount(this.connectCode.document_id).pipe(
      filter(ca => ca && ca.state === "connected"),
      flatMap(ca => {
        return this.orderService.fetchCurrentOrder();
      })
    ).subscribe(ca => {
      this.isConnected = true;
    });
  }
}
