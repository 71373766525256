import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthorizedApiService} from '../../../services/authorized-api.service';
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-split-item-dialog',
  templateUrl: './split-item-dialog.component.html',
  styleUrls: ['./split-item-dialog.component.css']
})
export class SplitItemDialogComponent implements OnInit {
  title: string;
  buttons: any[];
  private item: any;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<SplitItemDialogComponent>, private api: AuthorizedApiService, private translate: TranslateService) {
    this.item = data.item;
  }

  ngOnInit(): void {
    this.title = this.getString("Dela upp") + ": " + this.item.name;
    this.buttons = [];
    this.buttons.push({text: '2 😀😀', parts: 2});
    this.buttons.push({text: '3 😀😀😀', parts: 3});
    this.buttons.push({text: '4 😀😀😀😀', parts: 4});
    this.buttons.push({text: '5 😀😀😀😀😀', parts: 5});
  }

  btnClicked(btn: any) {
    console.log("btn:", btn);
    this.dialogRef.close(btn);
  }

  getString(s: string, pc: string = "normal") {
    return this.translate.single(s, pc);
  }

}
