import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrderService } from 'src/app/services/order.service';
import { HackUtils } from 'src/app/utils/Utils';
import { WebCodeDialogComponent } from '../web-code-dialog/web-code-dialog.component';

@Component({
  selector: 'app-rescan-dialog',
  templateUrl: './rescan-dialog.component.html',
  styleUrls: ['./rescan-dialog.component.css']
})
export class RescanDialogComponent implements OnInit {
  title: string;
  message: string;
  buttons: { action: string, text: string }[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) data, private rescanDialog: MatDialogRef<RescanDialogComponent>, public dialog: MatDialog, private order: OrderService) {
    this.title = data.title;
    this.message = data.message;
    this.buttons = data.buttons;
  }

  ngOnInit(): void {
  }

  dialogButtons(action) {
    switch(action) {
      case 'confirm':
        this.confirmTable();
        break;
      case 'changeTable':
        this.changeTable();
        break;
      default :
        this.rescanDialog.close(false);
    }
  }

  confirmTable() {
    this.order.confirmTable().subscribe(result => {
      this.rescanDialog.close({confirmed: true});
    });
  }

  changeTable() {
    const webDialog = this.dialog.open(WebCodeDialogComponent, HackUtils.DLG({
      data: {}
    }));
    webDialog.afterClosed().subscribe(result => {
      if (result) {
        this.rescanDialog.close({confirmed: false});
      }
    });
  }
}
