<mat-dialog-content class="item-dialog mat-typography" style="max-height: 80vh;">

  <div class="item-header">
    <div class="item-title">{{title}}</div>
  </div>

  <div class="item-content">
    <div class="info-form">
      <mat-form-field class="info-full-width">
        <mat-label>{{getString("E-post")}}</mat-label>
        <input #emailInput matInput [formControl]="emailFormControl" placeholder="Ex. pat@example.com" type="email" [(ngModel)]="email">
      </mat-form-field>

      <label class="clickable-area">
        <mat-checkbox [(ngModel)]="saveEmail" *ngIf="action=='send_receipt' && !isKiosk">
          <div>{{getString("Spara e-post")}}</div>
        </mat-checkbox>
      </label>
      <div style="font-size: 13px;display: flex;flex-direction: row; align-items: center;color: #626262;" *ngIf="action!='send_receipt'">
        <mat-icon style="font-size: 16px; width: 16px;padding-top: 5px;">info</mat-icon>
        <div style="padding-left: 8px;">Tips: Om du har svårt att hitta {{entityName}}, kika i ditt spamfilter eller skräppost om den råkat hamna fel.</div>
      </div>
    </div>
  </div>

  <div class="button-list">
    <button mat-stroked-button mat-dialog-close>{{getString("Stäng")}}</button>
    <mat-spinner class="add-item-spinner" *ngIf="isWorking" [diameter]="32"></mat-spinner>
    <button [disabled]="isWorking || !isEmailValid()" mat-raised-button color="accent" (click)="onSend()">{{getString("Skicka")}}</button>
  </div>

</mat-dialog-content>
