<div class="">
  <div *ngIf="prepaidData" class="container">
    <div class="back" (click)="back()">
      <mat-icon style="font-size: 27px">navigate_before</mat-icon>
      <div style="margin: 3px 0 0 5px;">{{config?.payment?.prepaid_name}}</div>
    </div>
    <div class="card" *ngIf="prepaidData.identity">
      <img src="https://storage.googleapis.com/heynow/media/logo_coral_100.png" class="logo-card">
      <div *ngIf="prepaidData.number" style="position: relative; width: 100%; height: 0">
        <div class="qr-thing"><a (click)="showQR()"><mat-icon *ngIf="!showQRCode" style="font-size: 33px;">qr_code_2</mat-icon><mat-icon *ngIf="showQRCode" style="font-size: 33px;">close</mat-icon></a></div>
      </div>
      <label>{{prepaidData.card_name}}</label>
      <div id="user" class="cardnumber"><span>{{prepaidData.identity}}</span></div>
      <table width="100%">
        <tr>
          <td><label>Name:</label></td>
          <td><label>Balans:</label></td>
        </tr>
        <tr>
          <td><div class="name">{{prepaidData.name}}</div></td>
          <td><div class="ccv">{{prepaidData.balance}}</div></td>
        </tr>
      </table>
      <div *ngIf="showQRCode" style="display:flex; flex-direction:column; align-items: center; margin-top: 16px;">
        <img src="https://steward.heynows.com/qr?content={{prepaidData.number}}&size=120&margin=1">
        <div class="under">ⓘ {{getString("Kan användas vid betalning i kassan")}}</div>
      </div>
    </div>
  </div>

  <div *ngIf="prepaidData && !showQRCode && appState?.order.user_cfg.is_anon === false" style="margin-top: 32px;">
    <div class="payment-button" mat-ripple (click)="refill()">
      <div class="payment-button-text">{{getString("Fyll på kort med")}} {{prepaidData.formatted}}</div>
    </div>
  </div>

  <div *ngIf="appState?.order.user_cfg.is_anon === true" style="margin-top: 32px;">
    <div class="payment-button" mat-ripple (click)="login()">
      <div class="payment-button-text">{{getString("Logga in för att fylla på kort")}}</div>
    </div>
  </div>

  <div class="overlay" *ngIf="isLoading">
    <div class="center">
      <mat-progress-spinner mode="indeterminate" class="spinner">
      </mat-progress-spinner>
    </div>
  </div>

</div>

