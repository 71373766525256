<main>
  <section>
    <div *ngIf="!isLoading" class="container">
      <div class="back" (click)="backClicked()">
        <mat-icon style="font-size: 27px">navigate_before</mat-icon>
        <div style="margin: 3px 0 0 5px;">PRESENTKORT</div>
      </div>
    </div>

    <div *ngIf="!isLoading" style="padding: 0 16px 0 16px;">
      <div class="collect-panel">
        <div class="collect-panel-title">
          <mat-icon style="font-size: 16px; width: 16px;">card_giftcard</mat-icon>
          <div style="margin-left: 4px;">
            <div *ngIf="!reciverLayout" class="under">Presentkort {{amount}} - (<span *ngIf="receiverName">{{receiverName}}, </span>{{lastFour}})</div>
            <div *ngIf="reciverLayout" class="under">Presentkort ({{lastFour}})</div>
          </div>
        </div>
        <div *ngIf="!reciverLayout">
          Tack så mycket för köpet av presentkort. Vi hoppas det kommer väl till användning!
        </div>
        <div *ngIf="reciverLayout">
          <p>Hej, du har fått ett presentkort! 🎁</p>
          <div *ngIf="senderName || message" class="regards">
            <p *ngIf="message">{{message}}</p>
            <p *ngIf="senderName" style="margin: 0;">Hälsningar {{senderName}}</p>
          </div>
          <p>Du kan betala med det när du är på restaurangen. Du kan använda presentkortet med mobilen eller så kan du välja att ladda ner det.</p>
          <p>Vi ser framemot att få ta emot er som gäst!</p>
        </div>
        <div style="margin-top:16px;font-size: 13px;font-style: italic;color: #8c8c8c;">
          Tänk på att presentkortet är en värdehandling. Ge ingen obehörig tillgång till kortet.
        </div>
        <div class="card" *ngIf="reciverLayout && giftcard">
          <img src="https://storage.googleapis.com/heynow/media/logo_coral_100.png" class="logo-card">
          <div *ngIf="giftcard.identity" style="position: relative; width: 100%; height: 0">
            <div class="qr-thing"><a (click)="showQR()"><mat-icon *ngIf="!showQRCode" style="font-size: 33px;">qr_code_2</mat-icon><mat-icon *ngIf="showQRCode" style="font-size: 33px;">close</mat-icon></a></div>
          </div>
          <label>Presentkort</label>
          <div id="user" class="cardnumber"><span>{{giftcard.identity}}</span></div>
          <table width="100%">
            <tr>
              <td><label>Belopp:</label></td>
              <td *ngIf="giftcard.valid_to"><label>Giltig tom:</label></td>
            </tr>
            <tr>
              <td><div class="ccv">{{giftcard.amount}} kr</div></td>
              <td *ngIf="giftcard.valid_to"><div class="ccv">{{giftcard.valid_to}}</div></td>
            </tr>
          </table>
          <div *ngIf="showQRCode" style="display:flex; flex-direction:column; align-items: center; margin-top: 16px;">
            <img src="https://steward.heynows.com/qr?content={{giftcard.identity}}&size=120&margin=1">
            <div class="under" style="color: white;margin-top: 16px;">ⓘ Visa upp koden i samband med att du betalar med presentkortet. Bon appetit!</div>
          </div>
        </div>
        <div *ngIf="showButton=='sms'" style="margin-top: 16px;">
          <div class="payment-button" mat-ripple (click)="sendInternalSMS()">
            <div class="payment-button-text">Skicka SMS direkt till mottagaren</div>
          </div>
        </div>
        <div *ngIf="showDLLink" class="under-panel">
          <mat-icon style="font-size: 20px;width: 20px;">get_app</mat-icon>
          <div style="margin-left: 6px;">
            <div class="under">eller hämta kortet, för t.ex. utskrift.</div>
            <div class="under2"><a href (click)="download()">Presentkort för nedladdning</a></div>
          </div>
        </div>
        <div *ngIf="showButton=='download'" style="margin-top: 16px;">
          <div class="payment-button" mat-ripple (click)="download()">
            <div class="payment-button-text">Ladda ner presentkort</div>
          </div>
        </div>
        <div class="under-panel">
          <mat-icon style="font-size: 20px;width: 20px;">forward_to_inbox</mat-icon>
          <div style="margin-left: 6px;">
            <div class="under">eller skicka</div>
            <div class="under2"><a href (click)="sendEmail()">Skicka presentkort till email</a></div>
          </div>
        </div>
      </div>

    </div>

  </section>
</main>
<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>

