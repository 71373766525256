<div *ngIf="!isLoading">
  <div class="container">
    <div class="back" (click)="back()">
      <mat-icon style="font-size: 27px">navigate_before</mat-icon>
      <div style="margin: 3px 0 0 5px;">{{getString("Språk", "upper")}}</div>
    </div>

    <div style="margin-bottom: 100px;">
      <div class="payment-button" mat-ripple *ngFor="let lang of languages" (click)="selectLang(lang)">
        <div class="payment-button-text">{{lang.flag}} {{lang.name}} ({{lang.code}})</div>
      </div>
    </div>
  </div>

</div>

<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
