<div style="padding: 16px;">
  <div *ngIf="title" class="collect-panel">
    <div class="collect-panel-title">
      <mat-icon style="font-size: 16px; width: 16px;">info</mat-icon>
      <div style="margin-left: 4px;">
        <div class="under">Info</div>
      </div>
    </div>
    <div>
      {{title}}
    </div>
  </div>
</div>
