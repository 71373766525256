<section *ngIf="!isLoading">
  <div class="container">
    <div class="back" (click)="back()">
      <mat-icon style="font-size: 27px">navigate_before</mat-icon>
      <div style="margin: 3px 0 0 5px;">{{getString("Mitt konto", "upper")}}</div>
    </div>

    <div *ngIf="app">
      <div class="profile">
        <table class="prop-table">
          <tr class="row">
            <td class="prop">{{getString("Namn")}}</td>
            <td class="value">{{app.order?.user_cfg?.user_name}}</td>
          </tr>
          <tr *ngIf="app.order?.user_cfg?.email" class="row">
            <td class="prop">{{getString("E-post")}}</td>
            <td class="value">{{app.order?.user_cfg?.email}}</td>
          </tr>
          <tr *ngIf="app.order?.user_cfg?.connected_account" class="row">
            <td class="prop">Messenger-konto</td>
            <td class="value">Kopplat</td>
          </tr>
          <tr *ngIf="accountType" class="row">
            <td class="prop">{{getString("Kontotyp")}}</td>
            <td class="value">{{accountType}}/{{messengerSDKInfoShort}}</td>
          </tr>
          <!-- FOR TEST -->
          <tr *ngIf="smartTable" class="row">
            <td class="prop">SmartTable&#8482;</td>
            <td class="value">
              <div *ngIf="!smartTable?.account">Aktivt</div>
              <div style="margin-top: 8px;" *ngIf="smartTable?.account"><span class="prop">{{getString("Konto:")}}</span><span>{{smartTable.account}}</span></div>
              <div style="margin-top: 8px;" *ngIf="smartTable?.price_list"><span class="prop">{{getString("Prislista:")}}</span><span>{{smartTable.price_list}}</span></div>
              <div style="margin: 8px 0 8px 0;">
                <button mat-button color="primary" (click)="leaveSmartTable()" style="padding: 0">
                  <mat-icon class="mat-icon">cancel</mat-icon>
                  Lämna SmartTable&#8482;
                </button>
              </div>
            </td>
          </tr>
          <tr class="row">
            <td class="prop">{{getString("Konto skapat")}}</td>
            <td class="value">{{app.order?.user_cfg?.user_created_date}}</td>
          </tr>
        </table>
      </div>

      <div style="margin-top: 32px;">
        <div *ngIf="false" class="payment-button" mat-ripple (click)="editProfile()">
          <div class="payment-button-text">REDIGERA PROFIL</div>
        </div>
        <div *ngIf="app.order.user_cfg.user_type !== 'messenger' && !app.order?.user_cfg?.connected_account" class="payment-button" mat-ripple (click)="connectMessengerAccount()">
          <div class="payment-button-text">{{getString("KOPPLA MESSENGER-KONTO", "upper")}}</div>
        </div>
        <div *ngIf="showLogout" class="payment-button" mat-ripple (click)="logout()">
          <div class="payment-button-text">{{getString("Logga ut", "upper")}}</div>
        </div>
        <div style="margin-top:24px;font-size:12px;display: flex; justify-content: center">
          <a style="color:gray;margin-right: 16px;" href (click)="openGdpr()">Mer om vårt dataskydd och GDPR</a>
        </div>
      </div>
    </div>
  </div>
</section>

<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
