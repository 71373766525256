<mat-dialog-content class="item-dialog mat-typography">

  <div class="item-header">
    <div class="item-title">{{title}}<br>{{range}}</div>
  </div>

  <div *ngIf="config" class="item-content">
    <div style="margin-bottom: 32px;margin-top: 16px;" *ngIf="namespaces">
      <div class="selector-header">Välj upphämtningsplats</div>
      <select class="time-selector" [disabled]="isWorking" [(ngModel)]="namespace" (ngModelChange)="nsChanged()">
        <option *ngFor="let ns of namespaces" [ngValue]="ns.id">{{ns.name}}</option>
      </select>
    </div>
    <div class="date-list">
      <div class="" [ngClass]="{'date-button': date.date_key !== selectedDateKey, 'date-button-sel': date.date_key === selectedDateKey}" mat-ripple *ngFor="let date of dates" (click)="selectedDate(date.date_key)"><div class="date">{{date.date}}</div><div>{{date.week_day}}</div></div>
    </div>
    <div class="time-select" *ngIf="slots">
      <select class="time-selector" [disabled]="isWorking" [(ngModel)]="time">
        <option *ngFor="let slot of slots" [ngValue]="slot.time">{{slot.time}}</option>
      </select>
    </div>
    <div class="err-mess">{{errorMessage}}</div>

    <div *ngIf="noDates" style="text-align: center;padding-top:16px;margin-bottom: 16px;color: #c52e2e;">Tyvärr finns inga mer lediga tider.</div>
  </div>

  <div class="button-list">
    <button mat-stroked-button mat-dialog-close>Stäng</button>
    <mat-spinner class="add-item-spinner" *ngIf="isWorking" [diameter]="32"></mat-spinner>
    <button [disabled]="isWorking" mat-raised-button color="accent" (click)="selectedTime()" *ngIf="!noDates">OK</button>
  </div>

</mat-dialog-content>
