import { Injectable } from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import { FireService } from './fire.service';
import { filter, first, map, tap } from 'rxjs/operators';
import { VenueConfig } from '../models/FSConfig';
import { MenuUtils } from '../utils/Utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  currentConfigSubject = new ReplaySubject<VenueConfig>(1);
  private venueId: string;
  private sub: Subscription;
  private config: VenueConfig;
  private _base_url = environment.base_url;

  public get base_url() {
    return this._base_url;
  }

  constructor(private fire: FireService) { }

  observeConfig(venueId: string): Observable<VenueConfig> {
    if (venueId && venueId !== this.venueId) {
      if (this.sub) {
        this.sub.unsubscribe();
      }
      this.sub = this.initConfigService(venueId).subscribe(ord => { });
    }
    return this.currentConfigSubject;
  }

  private initConfigService(venueId: string): Observable<VenueConfig> {
    this.venueId = venueId;
    console.log(`Init config service ${venueId}`);
    return this.fire.observeVenueConfig(venueId).pipe(
      tap<VenueConfig>(config => {
        this._base_url = this.resolveBaseURL(config);
        if (config != null) {
          console.log("Initial config state:");
          console.log(config);
          this.config = config;
          this.currentConfigSubject.next(config);
        }
      })
    );
  }

  private resolveBaseURL(config?: VenueConfig): string {
    return environment.base_url;
  }

  getNonEmptyConfig(): Observable<VenueConfig> {
    return this.currentConfigSubject.pipe(filter<VenueConfig>(c => c.dt != null), first());
  }

  getNonEmptyConfigInit(venueId: string): Observable<VenueConfig> {
    return this.observeConfig(venueId).pipe(filter<VenueConfig>(c => c.dt != null), first());
  }

  public deriveDeliverTypeObs(table: string): Observable<string> {
    return this.getNonEmptyConfig().pipe(map(cfg => MenuUtils.deriveDeliverType(table, this.config)));
  }

  public getConfigOrNull() {
    return this.config;
  }

  public getText(key: string, defaultText?: string): string {
    if (this.config?.text_db) {
      if (this.config.text_db.hasOwnProperty(key)) {
        return this.config.text_db[key];
      }
    }
    return defaultText ? defaultText : key;
  }

  getSnapshot(): VenueConfig {
    return this.config;
  }
}
