import { Component, OnInit, OnDestroy } from '@angular/core';
import { UrlHistoryService } from 'src/app/services/url.history.service';
import {TranslateService} from "../../services/translate.service";
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { ProfileInfo } from 'src/app/models/Profile';
import { AuthorizedApiService } from 'src/app/services/authorized-api.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { MatDialog } from '@angular/material/dialog';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { HackUtils } from 'src/app/utils/Utils';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AppState } from 'src/app/models/AppState';
import {ErrorDialogService} from "../../services/error-dialog.service";

@Component({
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.css']
})
export class GdprComponent implements OnInit, OnDestroy {

  profileInfo: ProfileInfo;
  app: AppState;
  public isLoading: boolean;
  private profileSub: Subscription;
  private appStateSub: Subscription;
  private signoutSub: Subscription;

  constructor(private identityService: UrlHistoryService, private api: AuthorizedApiService,
              private translate: TranslateService, private appStateService: AppStateService,
              private dialog: MatDialog, private router: Router, private authService: AuthService, private errorDialogService: ErrorDialogService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.appStateSub = this.appStateService.observeAppState().subscribe(appState => {
      this.app = appState;
      this.fetchProfileInfo();
    });
  }

  ngOnDestroy() {
    this.profileSub?.unsubscribe();
    this.appStateSub?.unsubscribe();
    this.signoutSub?.unsubscribe();
  }

  private fetchProfileInfo() {
    this.profileSub = this.api.getProfileInfo().subscribe(res => {
      this.profileInfo = res;
      this.isLoading = false;
    });
  }

  cleanEvents() {
    const dialogRef = this.dialog.open(SimpleDialogComponent, HackUtils.DLG({
      minWidth: "95vw",
      position: { top: "8px" },
      data: { title: this.getString("Rensa händelser?"), message: this.getString("Alla händelser från din händelselista kommer att raderas.", "upper") }
    }));

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.api.cleanEventHistory().subscribe(res => {
          console.log(res);
        });
      }
    });
  }

  wipeProfile() {
    const dialogRef = this.dialog.open(SimpleDialogComponent, HackUtils.DLG({
      minWidth: "95vw",
      position: { top: "8px" },
      data: { title: this.getString("Är du säker på att du vill radera profilen?", "upper"), message: "All data, inklusive kvitton, rabattkort, rabattkoder etc., kommer att försvinna." }
    }));

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.api.wipeProfile().subscribe(res => {
          console.log(res);
          this.logout();
        }, err => {
          console.log(err.error);
          this.errorDialogService.openDialog(err.error);
        });
      }
    });
  }

  private async logout() {
    this.isLoading = true;
    this.signoutSub = this.appStateService.observeAppState().pipe(skip(1)).subscribe(appState => {
      this.identityService.clearHistory();
      this.router.navigateByUrl(`${this.app.venueId}/signout`);
    });
    const r = await this.authService.logout();
  }

  back() {
    this.identityService.navigateBack();
  }

  getString(s: string, pc = "normal") {
    return this.translate.single(s, pc);
  }

  navtoReceipts() {
    console.log("navtoReceipts", this.app.venueId);
    this.router.navigateByUrl(`${this.app.venueId}/receipts`);
    return false;
  }
}
