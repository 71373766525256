import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {combineLatest, from, Observable} from 'rxjs';
import { ReceiptDetails } from '../models/ReceiptDetails';
import { Giftcard, Order } from '../models/Order';
import {flatMap, map} from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private cfg: ConfigService
  ) { }

  private getOptions(venueId: string): any {
    const headers = new HttpHeaders({ "Venue-Id": venueId });
    return { headers, observe: "body", responseType: 'json' };
  }

  private postWithUserToken(venueId: string, user: firebase.User, body: any): Observable<Order> {
    // Get user token, then make sure we have a config in place, then make the request
    return from(user.getIdToken()).pipe(
      flatMap(token => {
        return this.cfg.getNonEmptyConfigInit(venueId).pipe(
          flatMap(cfg => {
            const o = this.getOptions(venueId);
            o.headers = o.headers.append("Authorization", token);
            return this.http.post<Order>(`${this.cfg.base_url}/api/mnu/exp`, body, o) as unknown as Observable<Order>;
          })
        );
      }),
    );
  }

  private postWithOptions<T>(venueId: string, url: string, body: object): Observable<T> {
    const o: any = this.getOptions(venueId);
    return this.http.post<T>(url, body, o) as unknown as Observable<T>;
  }

  public getCustomTokenForMessenger(magicLink: string): Observable<string> {
    const url = `${this.cfg.base_url}/api/mnu/exp`;
    const body = { action: "getcustomtoken", ml: magicLink };
    const options: any = { headers: new HttpHeaders({ "Content-Type": "application/json" }), responseType: 'text' };
    return this.http.post<string>(url, body, options) as unknown as Observable<string>;
  }

  public getCustomTokenForUrlToken(urlSec: string, uid: string): Observable<string> {
    const body = { action: "getcustomurltoken", url_sec: urlSec, uid };
    const url = `${this.cfg.base_url}/api/mnu/exp`;
    const options: any = { headers: new HttpHeaders({ "Content-Type": "application/json" }), responseType: 'text' };
    return this.http.post<string>(url, body, options) as unknown as Observable<string>;
  }

  // public resolveChat(user: firebase.User, table: string, venueId: string, deliveryType?: string): Observable<Order> {
  //   const body = { action: "resolvechat", table, user, deliveryType };
  //   return this.postWithUserToken(venueId, user, body);
  // }

  public signInFromFirebase(user: firebase.User, tableCode: string, venueId: string, oldFirebaseUser: firebase.User | null): Observable<Order> {
    const body = { action: "sign_in_from_firebase", table_code: tableCode, user, old_user: oldFirebaseUser };
    return this.postWithUserToken(venueId, user, body);
  }

  public getReceiptDetails(venueId: string, receiptId: string, includeDossier: boolean): Observable<ReceiptDetails> {
    const body = { action: "receipt_details", receipt_id: receiptId, include_dossier: includeDossier };
    return this.postWithOptions<ReceiptDetails>(venueId, `${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public sendReceiptPdf(venueId: string, receiptKey: string, email: string, saveEmail: boolean): Observable<void> {
    const body = { action: "send_receipt_pdf", receipt_key: receiptKey, save: saveEmail, email };
    return this.postWithOptions<void>(venueId, `${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public sendVoucherPdf(venueId: string, voucherId: string, email: string): Observable<void> {
    const body = { action: "send_voucher_pdf", voucher_id: voucherId, email };
    return this.postWithOptions<void>(venueId, `${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public sendAllVoucherPdfs(venueId: string, receiptKey: string, email: string): Observable<void> {
    const body = { action: "send_all_voucher_pdfs", receipt_key: receiptKey, email };
    return this.postWithOptions<void>(venueId, `${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public sendGiftcard(venueId: string, postId: string, email: string): Observable<void> {
    const body = { action: "send_giftcard", post_id: postId , email };
    return this.postWithOptions<void>(venueId, `${this.cfg.base_url}/api/mnu/exp`, body);
  }

  // public fetchInitialOrder(venueId: string, token: string, urlTableParam: string | undefined): Observable<Order> {
  //   // @ts-ignore
  //   const headers = new HttpHeaders({Authorization: token, "Venue-Id": venueId});
  //   const options = {headers, observe: "body", responseType: 'json'};
  //   const url = `${this.cfg.base_url}/api/mnu/exp`;
  //   const body = {action: "order", table: urlTableParam};
  //   // @ts-ignore
  //   return this.http.post<Order>(url, body, options) as unknown as Observable<Order>;
  // }

  public getGiftcard(venueId: string, postId: string): Observable<Giftcard> {
    const body = { action: "giftcard", post_id: postId };
    return this.postWithOptions<Giftcard>(venueId, `${this.cfg.base_url}/api/mnu/exp`, body);
  }

}
