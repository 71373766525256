import {Component, Input, OnInit} from '@angular/core';
import {AccountEventVoucher} from '../../models/AccountEvent';
import {CdkDragEnd, CdkDragStart} from '@angular/cdk/drag-drop';
import {AppState} from '../../models/AppState';
import {Router} from '@angular/router';
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-dragger',
  templateUrl: './dragger.component.html',
  styleUrls: ['./dragger.component.css']
})
export class DraggerComponent implements OnInit {
  @Input() voucher: AccountEventVoucher;
  @Input() venueId: number;
  @Input() type: string;
  name: string;
  message: string;

  constructor(private router: Router, private translate: TranslateService) { }

  ngOnInit(): void {
    this.name = this.voucher.name.replace("§§§", "").replace(/\|/g, ", ");
    this.message = this.voucher.message;
  }

  dragEnd($event: CdkDragEnd) {
    const pos = $event.source.getFreeDragPosition();
    console.log(pos);
    if (pos.x > 180) {
      this.router.navigateByUrl(`${this.venueId}/glance/${this.voucher.id}`);
    } else {
      const elm = $event.source.element.nativeElement;
      elm.classList.add("drag-me-trans");
      $event.source._dragRef.reset();
    }
  }

  dragStart($event: CdkDragStart) {
    const elm = $event.source.element.nativeElement;
    elm.classList.remove("drag-me-trans");
  }

  getString(s: string, pc: string = "normal") {
    return this.translate.single(s, pc);
  }

}
