import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ConnectCodeResponse,
  CreateReceiptResponse,
  LivesplitData,
  Order,
  PrepaidCardData,
  Voucher,
  Slot,
  SlotDates,
  ChargeItem
} from '../models/Order';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Receipt } from '../models/Receipt';
import {
  BamboraSession,
  BamboraTokenizedAuth,
  BamboraWalletSession,
  SwishLink,
  VivaPaymentOrder
} from '../models/PaymentModels';
import { flatMap } from 'rxjs/operators';
import { Identity } from '../objects/Identity';
import { AngularFireAuth } from '@angular/fire/auth';
import { ProfileInfo } from '../models/Profile';
import { ConfigService } from './config.service';

type SplitItem = {
  orderKey: string;
  rid: string;
};

@Injectable({
  providedIn: 'root'
})
export class AuthorizedApiService {
  private identity: Identity;

  constructor(
    private http: HttpClient,
    private firebaseAuth: AngularFireAuth,
    private cfg: ConfigService,
  ) { }

  private getOptions(token): any {
    const headers = new HttpHeaders({ Authorization: token, "Venue-Id": this.identity.venueId });
    return { headers, observe: "body", responseType: 'json' };
  }

  private async getCurrentUserToken() {
    const user = await this.firebaseAuth.currentUser;
    if (user == null) {
      return null;
    }
    return user.getIdToken();
  }

  private postWithOptions<T>(url: string, body: object): Observable<T> {
    return from(this.getCurrentUserToken()).pipe(flatMap(token => {
      if (token != null) {
        const o = this.getOptions(token);
        return this.http.post<Order>(url, body, o) as unknown as Observable<T>;
      } else {
        console.error("Cant post, no logged in user");
        console.error(body);
        throw new Error("Något fel har inträffat. Var god att ladda om sidan.");
      }
    }));
  }

  public getOrder(tableCode?: string): Observable<Order> {
    const body = { action: "order", table: tableCode };
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public addItemToOrder(itemId: string, comment: string, uid: string, postActionData?: any, sameAsRid?: string): Observable<Order> {
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, { action: "additem", item_id: itemId, comment, uid, post_action_data: postActionData, same_as_rid: sameAsRid });
  }

  public addItemsToOrder(itemIds: string[], parentRID: string, itemIncluded: boolean): Observable<Order> {
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, { action: "additems", item_ids: itemIds, parent_rid: parentRID, item_included: itemIncluded });
  }

  public removeItemFromOrder(itemId: string, isPackage: boolean): Observable<Order> {
    const body = { action: "removeitem", item_id: itemId, is_package: isPackage };
    console.log("Removing item", body);
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public changeDT(key: string): Observable<Order> {
    const body = { action: "changedt", dt: key };
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public changeTable(code: string, kiosk?: string): Observable<Order> {
    const body = { action: "changetable", code, kiosk};
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public leaveSmartTable(): Observable<Order> {
    const body = { action: "leavesmarttable"};
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public confirmTable(): Observable<Order> {
    const body = { action: "confirmed_table"};
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public addDiscountCode(code: string): Observable<Order> {
    const body = { action: "adddiscount", code };
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public createBamboraSession(tip: any, receiptKey?: string, itemRefs?: any[], plKey?: string, payableRefs?: string, chargeItems?: ChargeItem[]): Observable<BamboraSession> {
    const body = { action: "beginpay", tip, receipt_key: receiptKey, refs: itemRefs, pl_key:plKey, payable_refs: payableRefs, charge_items: chargeItems };
    return this.postWithOptions<BamboraSession>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public bamboraCreateWalletSessionSub(tip: any, receiptKey?: string, itemRefs?: any[], plKey?: string, payableRefs?: string, chargeItems?: ChargeItem[]): Observable<any> {
    const body = { action: "bambora_create_wallet_session", tip, receipt_key: receiptKey, refs: itemRefs, pl_key:plKey, payable_refs: payableRefs, charge_items: chargeItems };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public bamboraCreateWalletSession(tip: any, receiptKey?: string, itemRefs?: any[], plKey?: string, payableRefs?: string, chargeItems?: ChargeItem[]): Promise<any> {
    return this.bamboraCreateWalletSessionSub(tip, receiptKey, itemRefs, plKey, payableRefs, chargeItems).toPromise();
  }

  public createVivaPaymentOrder(tip: any, receiptKey?: string, itemRefs?: any[], plKey?: string, payableRefs?: string, chargeItems?: ChargeItem[], loc?: string): Observable<VivaPaymentOrder> {
    const body = { action: "create_viva_payment_order", tip, receipt_key: receiptKey, refs: itemRefs, pl_key:plKey, payable_refs: payableRefs, charge_items: chargeItems, loc};
    return this.postWithOptions<VivaPaymentOrder>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public chargeSavedCard(tip: any, receiptKey?: string, itemRefs?: any[], declineRoute?: string, browserInfo?: {}, plKey?: string, payableRefs?: string, chargeItems?: ChargeItem[]): Observable<BamboraTokenizedAuth> {
    const body = { action: "chargecard", tip, receipt_key: receiptKey, refs: itemRefs, decline_route: declineRoute, browser_info: browserInfo, pl_key:plKey, payable_refs: payableRefs, charge_items: chargeItems };
    return this.postWithOptions<BamboraTokenizedAuth>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public swishLink(tip: any, callbackurl?: string, receiptKey?: string, itemRefs?: any[], plKey?: string, payableRefs?: string, chargeItems?: ChargeItem[], payerAlias?: string, saveNumber?: boolean): Observable<SwishLink> {
    const body = { action: "swishlink", tip, callbackurl, receipt_key: receiptKey, refs: itemRefs, pl_key:plKey, payable_refs: payableRefs, charge_items: chargeItems, payer_alias: payerAlias, save_number: saveNumber };
    return this.postWithOptions<SwishLink>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public placeOrder(): Observable<Order> {
    const body = { action: "placeorder" };
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public getReceipts(): Observable<Receipt[]> {
    const body = { action: "receipts_list" };
    return this.postWithOptions<Receipt[]>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public printVoucher(voucherSetKey: string, stationId: string): Observable<any> {
    const body = { action: "receipt_bump_voucher_set", voucher_set_key: voucherSetKey, station_id: stationId };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public livesplitData(receiptKey?: string, plKey?: string): Observable<LivesplitData> {
    const body = { action: "livesplit", receipt_key: receiptKey, pl_key:plKey };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public activateLivesplit(plKey?: string): Observable<LivesplitData> {
    const body = { action: "activate_livesplit", pl_key:plKey };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public updateCollected(phoneNumber?: string, time?: any, pickupLocation?: any, roomnumber?: any, dateKey?: string, namespace?: string, anonEmail?: string, genericData?: any, customId?: string, customValue?: any): Observable<Order> {
    const genericDataString = genericData ? JSON.stringify(genericData) : undefined;
    const body = { action: "update_collected", phone: phoneNumber, time, pickup_location: pickupLocation, roomnumber,
      date_key: dateKey, namespace, anon_email: anonEmail, generic_json: genericDataString, custom_id: customId, custom_value: customValue };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public splitItems(itemsToSplit: SplitItem[], parts: number, plKey?: string): Observable<LivesplitData> {
    // Construct the body to include all items with their respective orderKey and rid
    const body = {
      action: "split_items",
      items: itemsToSplit.map(item => ({
        order_key: item.orderKey,
        rid: item.rid
      })),
      parts,
      pl_key: plKey
    };

    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public activateVoucherItem(voucherId: number): Observable<Voucher> {
    const body = { action: "activate_voucher_item", voucher_id: voucherId };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public fetchPrepaidData(): Observable<PrepaidCardData> {
    const body = { action: "prepaid_data" };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public createRefillReceipt(itemId: string): Observable<CreateReceiptResponse> {
    const body = { action: "create_refill_receipt", item_id: itemId };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public createDispReceipt(itemId: string, ref: string): Observable<CreateReceiptResponse> {
    const body = { action: "create_disp_receipt", item_id: itemId, ref };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public payWithPrepaid(tip: any, receiptKey?: string, itemRefs?: any[], plKey?: string, payableRefs?: string): Observable<Order> {
    const body = { action: "pay_with_prepaid", tip, receipt_key: receiptKey, refs: itemRefs, pl_key:plKey, payable_refs: payableRefs };
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public payWithAccount(tip: any, receiptKey?: string, itemRefs?: any[], plKey?: string, payableRefs?: string): Observable<Order> {
    const body = { action: "pay_with_account", tip, receipt_key: receiptKey, refs: itemRefs, pl_key:plKey, payable_refs: payableRefs };
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public payWithFree(tip: any, receiptKey?: string, itemRefs?: any[]): Observable<Order> {
    const body = { action: "pay_with_free", tip, receipt_key: receiptKey, refs: itemRefs};
    return this.postWithOptions<Order>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public getConnectCode(): Observable<ConnectCodeResponse> {
    const body = { action: "get_connect_code" };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public sendMessage(message: string): Observable<ConnectCodeResponse> {
    const body = { action: "send_message", message };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public callForWaiter(): Observable<ConnectCodeResponse> {
    const body = { action: "call_for_waiter" };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public fetchSlotDates(namespace: string): Observable<SlotDates> {
    const body = { action: "get_dates", namespace };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public fetchFreeSlots(dateKey: string, namespace: string): Observable<Slot[]> {
    const body = { action: "free_slots", date_key: dateKey, namespace };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  setIdentity(identity: Identity) {
    this.identity = identity;
  }

  public getProfileInfo(): Observable<ProfileInfo> {
    const body = { action: "profile_info" };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public cleanEventHistory(): Observable<any> {
    const body = { action: "clean_event_history" };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public wipeProfile(): Observable<any> {
    const body = { action: "wipe_profile" };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public vivaResolveReceiptKey(orderCode: string, transactionId: string): Observable<any> {
    const body = { action: "viva_resolve_receipt_key", order_code: orderCode, transaction_id: transactionId };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }

  public vivaCheckOrderStatus(orderCode: string, transactionId: string): Observable<any> {
    const body = { action: "viva_check_order_status", order_code: orderCode, transaction_id: transactionId };
    return this.postWithOptions<any>(`${this.cfg.base_url}/api/mnu/exp`, body);
  }
}
