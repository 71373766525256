import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.css']
})
export class SimpleDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string;
      message: string;
      posBtn: string;
      negBtn: string;
    },
    private dialogRef: MatDialogRef<SimpleDialogComponent>, private translate: TranslateService
  ) {
    this.data.posBtn = this.data.posBtn || this.getString("Ok");
  }

  ngOnInit(): void {
  }

  posClick(): void {
    this.dialogRef.close(true);
  }

  negClick(): void {
    this.dialogRef.close(false);
  }

  getString(s: string, pc = "normal") {
    return this.translate.single(s, pc);
  }

}
