import { Component, OnDestroy, OnInit } from '@angular/core';
import {ReceiptDetails, ReceiptDetailsPostAction, ReceiptDetailsStation} from '../../models/ReceiptDetails';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthorizedApiService } from '../../services/authorized-api.service';
import { ApiService } from '../../services/api.service';
import { ReceiptEmailDialogComponent } from './receipt-email-dialog/receipt-email-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UrlHistoryService } from '../../services/url.history.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderService } from '../../services/order.service';
import MoneyUtils, { HackUtils } from '../../utils/Utils';
import {PrintDossier} from '../../models/print-job';
import {TranslateService} from "../../services/translate.service";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit, OnDestroy {
  receiptData: ReceiptDetails;
  isLoading = true;
  receiptId: string;
  bumpState = { sending: false, save_email: 0, entered_code: "", error: false, bump: "i", bump_station: "" };
  venueId = "";
  receiptDossier: PrintDossier;
  isKiosk: boolean;
  isMonter: boolean

  public MoneyUtils = MoneyUtils;

  constructor(
    private identityService: UrlHistoryService,
    private route: ActivatedRoute,
    private api: ApiService,
    private authorizedApi: AuthorizedApiService,
    public dialog: MatDialog,
    private orderService: OrderService,
    private snackbar: MatSnackBar,
    private router: Router,
    public translate: TranslateService,
    private auth: AuthService,
    ) { }

  ngOnInit(): void {
    this.isKiosk = this.auth.isKioskMode();
    this.isMonter = this.auth.getKioskState() === "monter";
    console.log("ReceiptComponent init");
    this.route.paramMap.subscribe(data => {
      this.receiptId = data.get("receipt_id");
      this.venueId = data.get("venue_id");
      console.log("Getting receipt_id", this.receiptId);
      this.getReceipt(this.receiptId);
    });
  }

  ngOnDestroy(): void {
  }

  getReceipt(receiptId: string): void {
    this.api.getReceiptDetails(this.venueId, receiptId, true).subscribe(r => {
      this.isLoading = false;
      console.log("Got receipt", r);
      this.receiptData = r;
      this.receiptId = r.key;
      this.receiptDossier = r.dossier;
    });
  }

  printVoucher(voucherKey: string, station: ReceiptDetailsStation): void {
    this.bumpState.sending = true;
    this.bumpState.bump_station = station.name;
    this.authorizedApi.printVoucher(voucherKey, station.id).subscribe(r => {
      this.bumpState.sending = false;
      this.bumpState.bump = "s";
      console.log(`Voucher successfully sent to ${station.name}`);
    }, error => {
      this.bumpState.sending = false;
    });
  }

  sendMail() {
    // TODO activate send mail directly to email account, use this when we have support for apple anon emails
    this.snackbar.open("Kvittokopia skickad till din e-post", null, { duration: 2000 });
  }

  openMail(): void {
    const dialogRef = this.dialog.open(ReceiptEmailDialogComponent, HackUtils.DLG({
      data: {
        action: "send_receipt",
        title: this.getString("Skicka kvittokopia"),
        receiptKey: this.receiptId,
        venueId: this.venueId,
        receipt_mail: this.orderService.getOrderOrNull()?.user_cfg.receipt_mail
      } // todo
    }));
  }

  sendTicket(voucherId: string): void {
    const dialogRef = this.dialog.open(ReceiptEmailDialogComponent, HackUtils.DLG({
      data: {
        action: "send_voucher",
        title: "Skicka biljett",
        venueId: this.venueId,
        voucher_id: voucherId,
        receipt_mail: this.orderService.getOrderOrNull()?.user_cfg.receipt_mail
      } // todo
    }));
  }

  sendAllTickets() {
    const dialogRef = this.dialog.open(ReceiptEmailDialogComponent, HackUtils.DLG({
      data: {
        action: "send_all_vouchers",
        title: "Skicka biljetter",
        // receiptKey: this.receiptId,
        receiptKey: this.receiptData.key,
        venueId: this.venueId,
        receipt_mail: this.orderService.getOrderOrNull()?.user_cfg.receipt_mail
      } // todo
    }));
  }

  backClicked(): void {
    this.identityService.navigateBack("/receipts");
  }

  getGiftcardName(pa: ReceiptDetailsPostAction) {
    const receiver_name = pa.spec?.receiver_name;
    if (receiver_name) {
      return receiver_name;
    }
    return "";
  }

  openGiftcard(pa: ReceiptDetailsPostAction) {
    this.router.navigateByUrl(`${this.venueId}/giftcard/${pa.unique_id}`);
  }

  goToStartpage() {
    this.router.navigateByUrl(`${this.venueId}/menu/food`);
  }

  getString(s: string, postCase = "normal") {
    return this.translate.single(s, postCase);
  }

}
