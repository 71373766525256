import { Component, OnInit } from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfigService} from '../../../services/config.service';

@Component({
  selector: 'app-select-room-dialog',
  templateUrl: './select-room-dialog.component.html',
  styleUrls: ['./select-room-dialog.component.css']
})
export class SelectRoomDialogComponent implements OnInit {
  room = "";
  error = false;
  sending = false;
  lastTestedCode: string;
  roomHint: string;
  roomPlaceholder: string;

  constructor(private order: OrderService, private dialogRef: MatDialogRef<SelectRoomDialogComponent>, private config: ConfigService) { }

  ngOnInit(): void {
    this.roomHint = this.config.getText("room_hint");
    this.roomPlaceholder = this.config.getText("room_example", "RUM");
  }

  selected() {
    if (this.room) {
      this.sending = true;
      this.order.updateCollected(undefined, undefined, undefined, this.room).subscribe( r => {
        this.sending = false;
        console.log(r);
        this.dialogRef.close();
      }, error => {
        this.sending = false;
        throw error;
      });
    }
  }
}
