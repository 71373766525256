<div *ngIf="!isSelected" class="section-row" routerLink="./{{encodedSectionId()}}">
  <div class="section-row-in"><div>{{section.name.toUpperCase()}}</div><div *ngIf="section.desc" class="section-row-d">{{section.desc}}</div></div>
  <mat-icon style="font-size: 27px">navigate_next</mat-icon>
</div>
<div *ngIf="isSelected" class="section-row-back" routerLink="..">
  <mat-icon style="font-size: 27px">navigate_before</mat-icon> <div style="margin: 3px 0 0 5px;">{{section.name.toUpperCase()}}</div>
</div>

<div *ngFor="let category of visibleCategories(); let i = index; let last = last;">
  <app-menu-list-category [category]="category">
  </app-menu-list-category>
  <div *ngIf="!last" class="item-separator"></div>
</div>

