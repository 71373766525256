import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {OrderService} from '../../services/order.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HackUtils} from "../../utils/Utils";
import {GenericInfoDialogComponent} from "../generic-info-dialog/generic-info-dialog.component";
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-collect-info-dialog',
  templateUrl: './collect-info-dialog.component.html',
  styleUrls: ['./collect-info-dialog.component.css']
})
export class CollectInfoDialogComponent implements OnInit {

  title: string;
  closeTitle: string;
  okTitle: string;
  collectItems: any[];
  isWorking = false;
  onOK: any;
  infoLink: string;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<CollectInfoDialogComponent>, private order: OrderService, private snackbar: MatSnackBar, private dialog: MatDialog, private cdRef: ChangeDetectorRef) {
    this.collectItems = data.collectItems;
    for (const item of this.collectItems) {
      item.model = item.value;
    }
    this.title = data.title;
    this.closeTitle = data.closeTitle ?? "Stäng";
    this.okTitle = data.okTitle ?? "OK";
    this.onOK = data.onOK ?? "saveInfo";
    this.infoLink = data.infoLink;
  }

  ngOnInit(): void {
    for (var ci of this.collectItems) {
      if (ci.model) {
        ci.touched = true;
      }
    }
  }

  onSubmit(myForm: NgForm) {
    if(myForm.valid) {
      this.saveInfo();
    }
  }

  private saveInfo() {
    console.log(this.onOK);
    if (this.onOK === "justClose") {
      const values = Object.assign({}, ...this.collectItems.map((x) => ({[x.id]: x.model})));
      this.dialogRef.close(values);
      return;
    }

    this.isWorking = true;
    console.log("saveInfo...");
    const orgPhone = this.collectItems[0].value;
    const newPhone = this.collectItems[0].model;
    if (orgPhone !== newPhone) {
      console.log(`New phone entered: ${newPhone}`);
      this.order.updateCollected(newPhone, undefined, undefined, undefined).subscribe(order => {
        this.dialogRef.close(newPhone);
      }, error => {
        this.isWorking = false;
        if (error.error.message === "Phone number not valid") {
          this.snackbar.open("Angivet telefonnummer verkar inte vara korrekt", null, { duration: 2000 });
        } else {
          this.snackbar.open("Något gick fel, prova igen.", null, { duration: 2000 });
        }
      });
    } else {
      console.log("Nothing changed...");
      this.dialogRef.close(orgPhone);
    }
  }

  openPersonalGiftcardInfoDialog() {
    this.dialog.open(GenericInfoDialogComponent, HackUtils.DLG({
      data: { title: "Personligt presentkort", type: "personalGiftcard"}
    }));
    return false;
  }

  modelChange(text: string, ci: any) {
    const filteredText = this.removeEmojisChars(text);
    if (filteredText !== text) {
      ci.model = null;
      this.cdRef.detectChanges();
      ci.model = this.removeEmojisChars(text);
      this.cdRef.detectChanges();
    } else {
      ci.model = text;
    }
    console.log(ci.model);
    localStorage.setItem(ci.id, ci.model);
  }

  removeEmojisChars(text: string) {
    return text.replace(/[^+\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
  }
}
