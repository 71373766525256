<mat-sidenav-container
    [style.height]="containerHeight"
    [style.margin-top]="containerTopMargin"
    class="mat-typography" [ngClass]="{'outer-container':showBottomNav,'outer-container-nobn':!showBottomNav}">

  <mat-sidenav [mode]="mode" [opened]="isKiosk || opened" (opened)="opened">
    <app-sidebar></app-sidebar>
  </mat-sidenav>

  <mat-sidenav-content class="content" (scroll)="onScroll($event)" appScroll1>
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>

<router-outlet name="popup"></router-outlet>

<mat-toolbar *ngIf="showTopNav" class="mobile-toolbar">
  <button mat-icon-button (click)="clickMenu()" aria-label="Menu">
    <mat-icon *ngIf="!isKiosk">menu</mat-icon>
    <mat-icon *ngIf="isKiosk">nightlife</mat-icon>
  </button>
  <div class="nav-text">
    <div class="nav-venue-name">{{venueName}}</div>
    <div *ngIf="dtTitle" class="nav-dt">{{dtTitle}}</div>
  </div>
  <div style="margin-left: auto;"></div>
  <div *ngIf="flagEmoji" class="tb-btn-tn" mat-ripple (click)="selectLang()" >
    <div>{{flagEmoji}}</div>
  </div>
  <div *ngIf="showCallForWaitress && !isMonter" class="tb-btn-tn" mat-ripple (click)="callForWaitress()" >
    <div>🙋</div>
  </div>
  <div *ngIf="showLogin" [ngClass]="{'tb-btn-pay-sm':brightLoginBtn,'tb-btn-pay-sm-2':!brightLoginBtn}" style="width:64px;" mat-ripple (click)="login()" >
    <div>{{getString("Logga in")}}</div>
  </div>
  <div *ngIf="showTopNavCartButton" class="tb-btn-pay-sm" mat-ripple routerLink="/{{venueId}}/cart">
    <div>{{getString("Varukorg")}}</div><div>{{cartValue}}</div>
  </div>

  <!--  <div *ngIf="showPay" class="tb-btn-pay-sm" mat-ripple routerLink="/{{venueId}}/livesplit" style="">-->
  <!--    <div>Betala</div><div>Notan</div>-->
  <!--  </div>-->
</mat-toolbar>

<mat-toolbar *ngIf="showBottomNav" class="bottom-container">
  <div *ngIf="showPay" class="tb-btn-pay" mat-ripple routerLink="/{{venueId}}/livesplit" style="flex-grow: 0;">
    <mat-icon>receipt_long</mat-icon><div style="margin-top: 2px;">{{getString("Betala")}}</div><div>{{getString("Notan")}}</div>
  </div>
  <div *ngIf="showFood" class="tb-btn" mat-ripple routerLink="/{{venueId}}/menu/food" style="flex-grow: 1;">
    <img style="margin-top: 10px" src="https://storage.googleapis.com/heynow/media/utensils.png">
    <div class="btn-title">{{getString("Mat")}}</div>
  </div>
  <div *ngIf="showDrinks" class="tb-btn" mat-ripple routerLink="/{{venueId}}/menu/drinks" style="flex-grow: 1;">
    <img style="margin-top: 10px;" src="https://storage.googleapis.com/heynow/media/glas.png">
    <div class="btn-title">{{getString("Dryck")}}</div>
  </div>
  <div *ngIf="!cartEmpty" class="tb-btn-order" mat-ripple routerLink="/{{venueId}}/cart" (click)="openCart()" style="flex-grow: 1;">
    <div class="order-text">{{getString("Varukorg")}}</div>
    <div class="tb-btn-order-inner">
      <img style="margin-top: 5px; margin-left: 8px;" src="https://storage.googleapis.com/heynow/media/invoice.png">
      <div class="value-text">{{cartValue}}</div>
    </div>
  </div>
</mat-toolbar>
