import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MenuCategory, MenuSection} from '../../models/FSMenu';
import {TranslateService} from "../../services/translate.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-menu-list-section',
  templateUrl: './menu-list-section.component.html',
  styleUrls: ['./menu-list-section.component.scss']
})
export class MenuListSectionComponent implements OnInit, OnDestroy {
  @Input() section: MenuSection;
  @Input() isSelected: boolean;
  categories: MenuCategory[];
  private sub: Subscription;

  constructor(private translate: TranslateService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.categories = this.isSelected ? this.section.categories : null;

    this.sub = this.translate.translationsReceivedSubject.subscribe(() => {
      console.log("MenuListSectionComponent translations received");
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  encodedSectionId() {
    return encodeURIComponent(this.section.id);
  }

  visibleCategories() {
    const cats = this.categories?.filter( s => !s.mhide );
    return this.translate.categories(cats);
  }

}
