import { Component, OnInit, OnDestroy } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-deactivate-kiosk-mode-dialog',
  templateUrl: './deactivate-kiosk-mode-dialog.component.html',
  styleUrls: ['./deactivate-kiosk-mode-dialog.component.css']
})
export class DeactivateKioskModeDialogComponent implements OnInit {
  passcode = "";
  wrongPin: boolean;
  isWorking = false;

  constructor(private dialogRef: MatDialogRef<DeactivateKioskModeDialogComponent>, private auth: AuthService) { }

  ngOnInit(): void {
  }

  onKeyup(event: KeyboardEvent) {
    this.wrongPin = false;
    let code = this.passcode.toUpperCase();
    if (code.length > 4) {
      code = code.substring(0, 4);
    }
    this.passcode = code;
    if (this.passcode.length === 4) {
      if (this.passcode === "1122") {
        this.isWorking = true;
        this.auth.deactivateKioskMode().subscribe( r => {
          this.dialogRef.close(true);
          location.reload();
        });
      } else {
        this.isWorking = false;
        this.passcode = "";
        this.wrongPin = true;
      }
    }
  }
}
