import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { FormControl, Validators } from '@angular/forms';
import { UrlHistoryService } from '../../../services/url.history.service';
import {TranslateService} from "../../../services/translate.service";
import { AuthService } from 'src/app/services/auth.service';
import {phoneValidator} from "../../../validators/phone_validator";

@Component({
  selector: 'app-swish-number-dialog',
  templateUrl: './swish-number-dialog.component.html',
  styleUrls: ['./swish-number-dialog.component.css']
})
export class SwishNumberDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  savePhone: boolean;
  receiptKey = "";
  venueId = "";
  isWorking: boolean;
  phoneFormControl = new FormControl('', [
    Validators.required,
    phoneValidator(),
  ]);
  phone: string;

  @ViewChild("phoneInput") phoneInput: ElementRef;
  isKiosk: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) data,
              private dialogRef: MatDialogRef<SwishNumberDialogComponent>,
              public api: ApiService, private identity: UrlHistoryService, private translate: TranslateService,
              private auth: AuthService,) {
    if (data.phone) {
      this.phone = data.phone;
      this.savePhone = true;
    } else {
      this.phone = "";
      this.savePhone = false;
    }
    this.isWorking = false;
  }

  ngOnInit(): void {
    this.isKiosk = this.auth.isKioskMode();
    if (this.isKiosk) {
      this.phone = "";
      this.savePhone = false;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.phoneInput.nativeElement.focus());
  }

  ngOnDestroy() {
  }

  isValid(): boolean {
    return !this.phoneFormControl.hasError('required') && !this.phoneFormControl.hasError('invalidPhone');
  }

  onSend(): void {
    const res = { phone: this.phone, savePhone: this.savePhone };
    this.dialogRef.close(res);
  }

  getString(s: string) {
    return this.translate.single(s);
  }

}
