<main>
  <section>
    <div class="menu-list">
      <div *ngIf="selectedSection">
        <app-menu-list-section [section]="selectedSection" [isSelected]="true">
        </app-menu-list-section>
      </div>
      <div *ngIf="promoItem" style="margin-bottom: 32px;">
        <div class="collect-panel">
          <div class="collect-panel-title">
            <mat-icon style="font-size: 16px; width: 16px;">info</mat-icon>
            <div style="margin-left: 4px;">
              <div class="under">Missade kortdragningar</div>
            </div>
          </div>
          <div [innerHTML]="promoItem.desc">
          </div>
          <div class="payment-button" mat-ripple (click)="payPromo()">
            <div class="payment-button-text">Betala {{promoItem.formatted}}</div>
          </div>
        </div>
      </div>
      <div *ngFor="let category of visibleRootCategories; let i = index; let last = last;">
        <app-menu-list-category [category]="category">
        </app-menu-list-category>
        <div *ngIf="!last" class="item-separator"></div>
      </div>
      <div *ngFor="let section of visibleSections; let i = index; let last = last;">
        <app-menu-list-section *ngIf="section.id!=='__root'" [section]="section" [isSelected]="false">
        </app-menu-list-section>
      </div>
      <div *ngIf="emptyMenu" style="margin-top: 32px;">
        <div style="text-align: center;font-size: 16px;">
          Inga artiklar till försäljning
        </div>
        <div *ngIf="appState?.cfg?.show_overview" class="payment-button" mat-ripple style="margin-top: 32px;" (click)="openWeekOverview()">
          <div class="payment-button-text">Se veckomenyn</div>
        </div>
      </div>
    </div>
    <div class="scrollfooter"></div>
  </section>
</main>

<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
