<main>
  <section>
    <div *ngIf="!isLoading" class="container">
      <div class="back" (click)="backClicked()">
        <mat-icon style="font-size: 27px">navigate_before</mat-icon>
        <div style="margin: 3px 0 0 2px;">{{getString("Veckans meny")}}</div>
      </div>
    </div>
    <div *ngIf="promoUrl">
      <img [src]="'https://storage.googleapis.com/heynow/danilo/place-holder.gif'" class="week-top-image" [appHires]="promoUrl">
    </div>
    <div >
      <div class="parent">
        <div class="week-button wb-selected" [ngClass]="{'wb-selected': filterWeekDay === day.ix}" mat-ripple (click)="showDay(day.ix)" *ngFor="let day of days">
          {{day.name}}
        </div>
      </div>
    </div>
    <div class="menu-list">
      <div *ngFor="let category of categories; let i = index; let last = last;">
        <app-menu-list-category [category]="category" [visiProp]="'week'" [itemsDisabled]="true">
        </app-menu-list-category>
        <div *ngIf="!last" class="item-separator"></div>
      </div>
      <div *ngIf="categories?.length===0" style="text-align: center;font-size: 16px;">
        {{getString(translate.WEEK_EMPTY)}}
      </div>
    </div>
  </section>
</main>
<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>

