import { Component, OnInit } from '@angular/core';
import { UrlHistoryService } from '../../services/url.history.service';
import { AppStateService } from '../../services/app-state.service';
import { AppState } from '../../models/AppState';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {
  busName: string;
  orgNumber: string;
  swishInfo: string;
  app: AppState;
  public isLoading: boolean;
  private sub: Subscription;

  constructor(private appState: AppStateService, private identityService: UrlHistoryService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.sub = this.appState.observeAppState().subscribe(appState => {
      this.isLoading = false;
      this.app = appState;
      this.busName = appState.cfg.bus_name;
      this.orgNumber = appState.cfg.org_number;
      this.swishInfo = appState.cfg.swish_info;
    });
  }

  back() {
    this.identityService.navigateBack();
  }
}
