import { Component, OnDestroy, OnInit } from '@angular/core';
import { UrlHistoryService } from 'src/app/services/url.history.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppStateService } from '../../services/app-state.service';
import { AppState } from '../../models/AppState';
import { MessengerService } from '../../services/messenger.service';
import { AuthService } from 'src/app/services/auth.service';
import { skip } from 'rxjs/operators';
import {TranslateService} from "../../services/translate.service";
import {UserSpecificSmartTable} from "../../models/Order";
import {SimpleDialogComponent} from "../simple-dialog/simple-dialog.component";
import {HackUtils} from "../../utils/Utils";
import {MatDialog} from "@angular/material/dialog";
import {OrderService} from "../../services/order.service";

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit, OnDestroy {
  app: AppState;
  messengerSDKInfo: string;
  messengerSDKInfoShort: string;
  accountType: string;
  showLogout = true;
  public isLoading: boolean;
  private sub: Subscription;
  private signoutSub: Subscription;
  smartTable: UserSpecificSmartTable;

  constructor(private appState: AppStateService, private identityService: UrlHistoryService,
              private router: Router, private messengerService: MessengerService, private authService: AuthService,
              private translate: TranslateService, public dialog: MatDialog, public orderService: OrderService ) { }

  ngOnInit(): void {
    this.beginObserving();
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.signoutSub?.unsubscribe();
  }

  private beginObserving() {
    this.sub = this.appState.observeAppState().subscribe(appState => {
      this.app = appState;
      this.messengerSDKInfo = this.messengerService.getMessengerState();
      this.messengerSDKInfoShort = this.messengerSDKInfo?.substring(0, 1);
      this.accountType = appState.order.user_cfg.user_type;
      this.smartTable = appState.order.user_cfg.smart_table;
      this.showLogout = true; //appState.order.user_cfg.user_type !== "messenger" || !this.messengerService.inMessenger();
    });
  }

  public async logout() {
    this.isLoading = true;
    localStorage.removeItem(`ml_link_${this.app.venueId}`);

    this.signoutSub = this.appState.observeAppState().pipe(skip(1)).subscribe(appState => {
      this.identityService.clearHistory();
      this.router.navigateByUrl(`${this.app.venueId}/signout`);
    });
    const r = await this.authService.logout();
  }

  connectMessengerAccount() {
    this.router.navigateByUrl(`${this.app.venueId}/connect-messenger`);
  }

  editProfile() {

  }

  back() {
    this.identityService.navigateBack();
  }

  openGdpr() {
    this.router.navigateByUrl(`${this.app.venueId}/gdpr`);
    return false;
  }

  getString(s: string, pc = "normal") {
    return this.translate.single(s, pc);
  }

  leaveSmartTable() {
    const dialogRef = this.dialog.open(SimpleDialogComponent, HackUtils.DLG({
      minWidth: "95vw",
      position: { top: "8px" },
      data: { title: "Lämna SmartTable?", message: "Du har tidigare scannat en QR-kod som är kopplad till en SmartTable, vill du lämna?" }
    }));

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderService.leaveSmartTable().subscribe(res => {
          console.log(res);
        });
      }
    });
  }
}
