import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import {Observable, of, Subscription} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import { DeliveryType } from 'src/app/objects/Identity';
import {VenueConfig} from '../../../models/FSConfig';
import {OrderService} from '../../../services/order.service';
import { ChangeDeliverTypeAlertDialogComponent } from './change-deliver-type-alert-dialog/change-deliver-type-alert-dialog.component';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-select-deliver-type',
  templateUrl: './select-deliver-type.component.html',
  styleUrls: ['./select-deliver-type.component.css']
})
export class SelectDeliverTypeComponent implements OnInit, OnDestroy {
  list: any[];
  isLoading = false;
  closeable = true;
  venueName: string;
  private sub: Subscription;
  private config: VenueConfig;

  constructor(private bottomSheetRef: MatBottomSheetRef<SelectDeliverTypeComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: {cfg: VenueConfig, closeable: boolean}, private order: OrderService,
              private auth: AuthService, private dialog: MatDialog, private router: Router, private translate: TranslateService, private cdRef: ChangeDetectorRef) {
    this.config = data.cfg;
    this.closeable = data.closeable;
    this.venueName = data.cfg.name;
    this.buildList();
  }

  ngOnInit(): void {
    this.sub = this.translate.translationsReceivedSubject.subscribe(() => {
      console.log("SelectDeliverTypeComponent translations received");
      this.buildList();
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


  private buildList() {
    const cfg: VenueConfig = this.config;
    this.list = [];
    if (cfg.dt.inhouse) {
      this.list.push({title: cfg.dt.inhouse.title, desc: cfg.dt.inhouse.desc ?? '', key: DeliveryType.Table});
    }
    if (cfg.dt.takeaway) {
      this.list.push({title: cfg.dt.takeaway.title, desc: cfg.dt.takeaway.desc ?? '', key: DeliveryType.Takeaway});
    }
    if (cfg.dt.roomservice) {
      this.list.push({title: cfg.dt.roomservice.title, desc: cfg.dt.roomservice.desc ?? '', key: DeliveryType.Roomservice});
    }
    if (cfg.dt.pickup) {
      if (cfg.dt.pickup.selectable == null || cfg.dt.pickup.selectable === true) {
        this.list.push({title: cfg.dt.pickup.title, desc: cfg.dt.pickup.desc ?? '', key: DeliveryType.Pickup});
      }
    }
    if (cfg.dt.foodcourt) {
      this.list.push({title: cfg.dt.foodcourt.title, desc: cfg.dt.foodcourt.desc ?? '', key: DeliveryType.Foodcourt});
    }
  }

  openLink(event: MouseEvent, key: string): void {
    this.isLoading = true;
    const order = this.order.getOrderOrNull();
    const hasItems = order.items.length > 0;
    const okToChange$ = hasItems && order.user_cfg.dt !== key ? this.openAlertDialog() : of(true);
    okToChange$.pipe(
      flatMap(ok => ok ? this.order.changeDeliverType(key) : of())
    ).subscribe({
      complete: () => { this.afterChange(key); }
    });
    event.preventDefault();
  }

  private openAlertDialog(): Observable<any> {
    const dialogRef = this.dialog.open(ChangeDeliverTypeAlertDialogComponent, { autoFocus: false });
    return dialogRef.afterClosed();
  }

  private afterChange(key) {
    this.isLoading = false;
    this.bottomSheetRef.dismiss(key);
    this.router.navigateByUrl(`${this.auth.venueId}`);
  }

  closeDeliverTypeSelector() {
    this.bottomSheetRef.dismiss(null);
  }

  getString(s: string) {
    return this.translate.single(s);
  }
}
