<mat-dialog-content class="item-dialog mat-typography">

  <div class="item-header">
    <div class="item-title">{{lang.flag}} {{lang.name}}</div>
  </div>

  <div class="item-content alert" >
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <mat-spinner *ngIf="isWorking" diameter="48" style="margin-top: 52px;"></mat-spinner>
      <div style="padding: 16px;">
        {{alert}}
      </div>
    </div>
  </div>

  <div class="button-list" style="padding: 16px;margin-top: 32px;">
    <button mat-stroked-button mat-dialog-close><mat-icon>close</mat-icon></button>
    <button [disabled]="isWorking" mat-raised-button color="accent" (click)="selectLang()"><mat-icon>done</mat-icon></button>
  </div>

</mat-dialog-content>

