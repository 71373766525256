<div class="container" *ngIf="!isLoading">
  <div class="back" (click)="goToStartPage()">
    <mat-icon style="font-size: 27px">navigate_before</mat-icon>
    <div style="margin: 3px 0 0 5px;">Utloggad</div>
  </div>

  <div class="lo-panel">
    <div class="title">Tack för besöket!</div>
    <div>Du är nu utloggad</div>
  </div>
</div>

<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
