<div mat-dialog-content class="item-dialog mat-typography">
    <div class="item-header" cdkFocusRegionstart>
        <div class="item-title">Ett fel har inträffat!</div>
    </div>
    <div class="error-content">
        <p *ngIf="data.status">
            <b>Kod: {{data.status}}</b>
        </p>
        <p>
            Något har gått fel!
        </p>
        <p class="error-message">
            {{data?.message}}
        </p>
    </div>
</div>
<div class="button-list">
    <button mat-stroked-button mat-dialog-close>Stäng</button>
</div>
