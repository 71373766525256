import { Injectable } from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {filter, first, map, tap} from 'rxjs/operators';
import {
  EMPTY_WHEN_FILTER,
  MenuAddendum,
  MenuCategory,
  MenuItem,
  MenuSection,
  MenuStructure,
  WhenFilter
} from '../models/FSMenu';
import {FireService} from './fire.service';
import * as moment from 'moment';
import {ConfigService} from './config.service';
import {findPlausibleNonLoggedInDeliverType, hasMoreThanOneDeliverType, VenueConfig} from '../models/FSConfig';
import {OrderService} from './order.service';
import {Order} from '../models/Order';
import {Moment} from 'moment';
import {MenuUtils} from '../utils/Utils';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private currentMenuSubject = new BehaviorSubject<MenuStructure | null>(null);
  private currentAddendumSubject = new BehaviorSubject<MenuAddendum | null>(null);
  private venueId: string;
  private menuSub: Subscription;
  private addendumSub: Subscription;

  constructor(private fire: FireService, private config: ConfigService, private orderService: OrderService) { }

  observeUnfilteredMenu(venueId: string): Observable<MenuStructure> {
    if (venueId && venueId !== this.venueId) {
      this.initMenuService(venueId);
    }
    return this.currentMenuSubject.pipe(filter<MenuStructure>(m => m != null ));
  }

  getAddendum(): MenuAddendum | null {
    return this.currentAddendumSubject.getValue();
  }

  getMenuStructure(): MenuStructure | null {
    return this.currentMenuSubject?.getValue();
  }

  observeAddendum(): Observable<MenuAddendum> {
    return this.currentAddendumSubject.pipe(filter<MenuAddendum>(m => m != null ));
  }

  private initMenuService(venueId: string) {
    if (this.menuSub) {
      this.menuSub.unsubscribe();
    }
    if (this.addendumSub) {
      this.addendumSub.unsubscribe();
    }
    this.venueId = venueId;
    console.log(`Init menu service ${venueId}`);

    this.menuSub = this.fire.observeMenuStructure(venueId).pipe(
      tap<MenuStructure>( menu => {
        console.log(`Initial menu state: ${menu?.food?.length} food items `);
        this.currentMenuSubject.next(menu);
      })
    ).subscribe( m => {
      if (this.addendumSub == null || this.addendumSub.closed) {
        this.addendumSub = this.fire.observeMenuAddendum(venueId).pipe(
          tap<MenuAddendum>( addendum => {
            console.log("Initial addendum state:", addendum);
            this.currentAddendumSubject.next(addendum);
          })
        ).subscribe( ad => {});
      }
    });

  }

  observeMenuConfigOrder(venueId: string): Observable<[MenuStructure, VenueConfig, Order]> {
    return combineLatest([
      this.observeUnfilteredMenu(venueId),
      //this.config.observeConfig(venueId),
      this.config.currentConfigSubject,
      this.orderService.currentOrder()]
    ).pipe(
      map(([menu, cfg, order]) => {
        this.updateHiddenFlag(menu, cfg, order);
        return [menu, cfg, order];
      })
    );
  }

  updateHiddenFlag(menu: MenuStructure, cfg: VenueConfig, order: Order) {
    const now = moment();
    const itemFilter = MenuUtils.buildFilter(order);
    if (menu.food) {
      MenuUtils.updatePageHiddenFlag(menu.food, cfg, order, now, "mhide", false, false, order?.user_cfg?.when_tag, itemFilter);
    }
    if (menu.drinks) {
      MenuUtils.updatePageHiddenFlag(menu.drinks, cfg, order, now, "mhide", false, false, order?.user_cfg?.when_tag, itemFilter);
    }
  }

  getItem(itemId: string, venueId: string): Observable<MenuItem | null> {
    return this.observeUnfilteredMenu(venueId).pipe(map( menu => MenuUtils.getItemInMenu(itemId, menu)), first());
  }

  isOutOfStock(itemId: string) {
    return this.getAddendum()?.items.find( it => it.id === itemId)?.inventory_count <= 0 ?? false;
  }
}
