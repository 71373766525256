import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import { OrderService } from '../../services/order.service';
import { Order, OrderItem } from '../../models/Order';
import { combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { VenueConfig } from '../../models/FSConfig';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';
import { UrlHistoryService } from 'src/app/services/url.history.service';
import { MatDialog } from '@angular/material/dialog';
import { PayDialogComponent } from '../pay-dialog/pay-dialog.component';
import { filter, flatMap, tap } from 'rxjs/operators';
import { AuthorizedApiService } from '../../services/authorized-api.service';
import {BamboraSession, BamboraTokenizedAuth, VivaPaymentOrder} from '../../models/PaymentModels';
import { WebCodeDialogComponent } from '../web-code-dialog/web-code-dialog.component';
import { MenuItemDialogComponent } from '../menu-item-dialog/menu-item-dialog.component';
import { MenuService } from '../../services/menu.service';
import { UserType } from 'src/app/objects/Identity';
import { CollectInfoDialogComponent } from '../collect-info-dialog/collect-info-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectTimeDialogComponent } from './select-time-dialog/select-time-dialog.component';
import { MenuItem } from '../../models/FSMenu';
import { HackUtils, MenuUtils, TimeUtils } from '../../utils/Utils';
import { MessengerService } from '../../services/messenger.service';
import { EnterDiscountCodeDialogComponent } from './enter-discount-code-dialog/enter-discount-code-dialog.component';
import {SelectDateTimeDialogComponent} from './select-date-time-dialog/select-date-time-dialog.component';
import * as moment from 'moment';
import { SelectRoomDialogComponent } from './select-room-dialog/select-room-dialog.component';
import { AppStateService } from '../../services/app-state.service';
import { AngularFireAuth } from '@angular/fire/auth';
import {FireService} from "../../services/fire.service";
import { RescanDialogComponent } from '../rescan-dialog/rescan-dialog.component';
import {FormControl, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {TranslateService} from "../../services/translate.service";

declare const WorldlineOnlineCheckout: {
  GooglePay: {
    create(configuration: any, data: any): Promise<any>;
  };
};

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  order: Order;
  editableItems: OrderItem[];
  fixedItems: OrderItem[];
  payableItems: any[];
  private stateSub: Subscription;
  private sub2: Subscription;
  private venueId: any;
  config: VenueConfig;
  showLogin = false;
  isKiosk: boolean;
  showAnonOrderPanel = false;
  anonPanelOpen = false;
  isLoading = true;
  showSpinner = false;
  pickupTime = "now";
  pickupLocation: string;
  inlineRecommendations: any[];
  inlineDonations: any[];
  selectedItems = {};
  attrValues = {};
  roomHint = "rum";
  pickupDateText: string;
  namespaceName: string;
  whenReadable: string;
  emailFormControl = new FormControl('', [Validators.email]);
  anonWantsToLogin = false;
  customCollects: any[];

  private navUrlHack: string;
  private liveSub: Subscription;
  private amount: number;
  private totalPre: number;
  private left: number;
  private itemRefs: any[];
  private googlePay: any;

  constructor(private orderService: OrderService, private configService: ConfigService,
              private app: AppStateService, private route: ActivatedRoute,
              public dialog: MatDialog, private identityService: UrlHistoryService,
              private api: AuthorizedApiService, private router: Router, private menu: MenuService,
              private snackbar: MatSnackBar, private messengerService: MessengerService,
              private firebaseAuth: AngularFireAuth, private ngZone: NgZone, private fire: FireService,
              private auth: AuthService, public translate: TranslateService) { }

  ngOnInit(): void {
    console.log("CartComponent init");
    this.beginObserving();
    this.isKiosk = this.auth.isKioskMode();
  }

  ngOnDestroy() {
    this.stateSub?.unsubscribe();
    this.liveSub?.unsubscribe();
    this.sub2?.unsubscribe();
  }

  private beginObserving() {
    this.stateSub = combineLatest([
      this.route.paramMap,
      this.app.observeAppState()]
    ).subscribe(res => {
      const pm = res[0];
      const appState = res[1];
      const orderHasChanged = this.order !== appState.order;
      this.order = appState.order;
      this.venueId = appState.venueId;
      this.config = appState.cfg;
      this.isLoading = false;
      const iao = this.order.user_cfg?.is_anon_orderable;
      this.showLogin = this.order.user_cfg.is_anon && !iao && !this.auth.kioskMode;
      this.showAnonOrderPanel = this.order.user_cfg.is_anon && iao && !this.auth.kioskMode;
      this.roomHint = this.configService.getText("room_hint");
      this.setupItemsForDisplay();
      this.setupPayableItems();

      this.pickupTime = this.order.pickup_time ? "later" : "now";
      if (this.order.pickup_date) {
        const pd = moment(this.order.pickup_date, "YYYY-MM-DD HH:mm:ss");
        this.ngZone.run(() => {this.pickupDateText = TimeUtils.getRelativeDayStringTimeDate(pd);});
      }
      if (!this.order.pickup_location) {
        const pls = this.order.user_cfg?.collect?.pickup_locations;
        if (pls) {
          this.pickupLocation = pls[0];
        }
      } else {
        this.pickupLocation = this.order.pickup_location;
      }

      if (orderHasChanged) {
        this.prepareInlinePush();
      }

      if (this.order.namespace) {
        this.namespaceName = this.config.dt?.takeaway?.slotting?.namespaces?.find(ns => ns.id === this.order.namespace)?.name;
      } else {
        this.namespaceName = undefined;
      }

      if (appState.order?.user_cfg?.when_tag) {
        const w = MenuUtils.readableWhen(appState.order?.user_cfg?.when_tag);
        if (w) {
          this.whenReadable = `Serveras ${w}`;
        } else {
          this.whenReadable = undefined;
        }
      }

      if (appState.order.user_cfg.is_anon && appState.order.user_cfg.email != null) {
        this.emailFormControl.setValue(appState.order.user_cfg.email);
        this.anonPanelOpen = true;
      }

      if (appState.order.user_cfg.collect?.custom != null) {
        this.customCollects = [];
        for (const cc of appState.order.user_cfg.collect.custom) {
          const inputType = cc.type ?? "text";
          const validatorOrOpts = cc.required ? [Validators.required] : [];
          const fc = new FormControl(cc.value, validatorOrOpts);
          this.customCollects.push({ id: cc.id, title: cc.title, inputType, placeholder: cc.placeholder, value: cc.value, formControl: fc});
        }
      }

      this.initWallet();
    });

    this.sub2 = this.translate.translationsReceivedSubject.subscribe(() => {
      console.log("Retranslate order after translations received");
      this.translate.order(this.order);
    });
  }

  backClicked() {
    this.identityService.navigateBack();
  }

  send() {
    if (this.order.user_cfg.must_rescan && !this.isKiosk) {
      this.openRescanDialog( confirmed => {
        if (confirmed) {
          this.send();
        }
      });
      return;
    }

    if (this.showSpinner) {
      return;
    }
    this.showSpinner = true;
    this.addInlinePushToOrder().pipe(
      flatMap(res => this.orderService.placeOrder())
    ).subscribe({
      next: res => {
        this.messengerService.doPostAction(this.venueId, "order_sent");
      },
      complete: () => { this.showSpinner = false; }
    });
  }

  openWebcodeDialog() {
    const dialogRef = this.dialog.open(WebCodeDialogComponent, HackUtils.DLG({
      data: {}
    }));
    return false;
  }

  openRoomserviceDialog() {
    const dialogRef = this.dialog.open(SelectRoomDialogComponent, HackUtils.DLG({
      data: {}
    }));
    return false;
  }

  openRescanDialog(after: (confirmed: boolean) => void) {
    const dialogRef = this.dialog.open(RescanDialogComponent, HackUtils.DLG({
      minWidth: "95vw",
      position: { top: "8px" },
      data: {
        title: this.getString("Bekräfta din plats"),
        message: (this.getString("Är du kvar på") + " " + this.order.user_cfg.table + "?"),
        buttons: [
          { action:'confirm', text: this.getString("Ja") },
          { action:'changeTable', text: this.getString("Byt bord") },
        ]
      }
    }));
    dialogRef.afterClosed().subscribe(result => {
      after(result.confirmed);
    });
  }

  openDiscount() {
    const dialogRef = this.dialog.open(EnterDiscountCodeDialogComponent, HackUtils.DLG({
      data: {}
    }));
    return false;
  }

  hasItems() {
    if (!this.order?.current) {
      return null;
    }
    return this.order?.items.length > 0;
  }

  openItemDialog(orderItem: OrderItem) {
    if (orderItem.included) {
      return;
    }
    this.menu.getItem(orderItem.item, this.venueId).subscribe(item => {
      const outOfStock = this.menu.isOutOfStock(item.id);
      const venueOpen = this.isDTOpen();
      const dialogRef = this.dialog.open(MenuItemDialogComponent, HackUtils.DLG({
        data: { item, outOfStock, cartPage: true, orderItem, venueOpen, showWhen: this.order?.user_cfg?.when_tag != null }
      }));
    });
  }

  openCollectDialog(): Observable<any> {
    const collectItems = [{ id: "phone", title: this.getString("Ditt mobilnummer"), type: "tel", placeholder:"+46 (0)761 244 520", value: this.order.user_cfg.phone }];
    const dialogRef = this.dialog.open(CollectInfoDialogComponent, HackUtils.DLG({
      data: { title: this.getString("Ange kontaktuppgifter"), collectItems }
    }));
    return dialogRef.afterClosed();
  }

  openSelectTimeDialog(): Observable<any> {
    const collectItems = [{ id: "phone", title: this.getString("Ditt mobilnummer"), type: "tel", placeholder:"+46 (0)761 244 520F", value: this.order.user_cfg.phone }];
    const dialogRef = this.dialog.open(SelectTimeDialogComponent, HackUtils.DLG({
      data: { title: "Ange kontaktuppgifter", collectItems }
    }));
    return dialogRef.afterClosed();
  }

  openSelectDateTimeDialog(): Observable<any> {
    const dialogRef = this.dialog.open(SelectDateTimeDialogComponent, HackUtils.DLG({
      data: { }
    }));
    return dialogRef.afterClosed();
  }

  //////////////////////////
  // PAYMENT RELATED
  //
  // TODO DRY this! Code is repeated
  //  in livesplit component, this should be fixed.
  //////////////////////////
  pay(type: string) {
    console.log("Pay with type", type);
    if (this.anonPanelOpen && !this.emailFormControl.valid) {
      this.snackbar.open("Angiven E-post är ogiltig", "stäng", { duration: 2000 });
      return;
    }
    if (this.order.user_cfg.must_rescan && !this.isKiosk) {
      this.openRescanDialog( confirmed => {
        if (confirmed) {
          this.pay(type);
        }
      });
      return;
    }
    if (this.showSpinner) {
      return;
    }
    this.addInlinePushToOrder().subscribe(r => { this.pay_1(type); });
  }

  pay_1(type: string) {
    if (this.order.user_cfg.collect?.slot_time && this.order.pickup_date == null) {
      this.openSelectDateTimeDialog();
      return;
    }
    const collectPhoneNumber = this.order.user_cfg.collect?.phone;
    if (collectPhoneNumber && type !== "swish") {
      this.openCollectDialog().subscribe(phone => {
        console.log("Phone: ", phone);
        if (phone) {
          this.pay_2(type);
        }
      });
    } else {
      this.pay_2(type);
    }
  }

  pay_2(type: string) {
    this.openPayDialog(type).pipe(
      filter(res => res),
      flatMap(res => {
        this.showSpinner = true;
        const payableRefs = this.buildPayableRefs();
        switch (type) {
          case "card":
            console.log(`Pay dialog: ${res.selected} (tip ${res.tip})`);
            if (res.selected === "saved_card") {
              return this.api.chargeSavedCard(res.tip, undefined, undefined, undefined, HackUtils.getBrowserInfo(), undefined, payableRefs).pipe(
                tap(bamTok => {
                  if (bamTok.method !== "noredirect") {
                    this.redirectSCA(bamTok);
                  }
                })
              );
            } else {
              return this.api.createBamboraSession(res.tip, undefined, undefined, undefined, payableRefs).pipe(
                flatMap(session => this.openBamboraCheckout(session))
              );
            }
          case "viva_smart":
            console.log(`Pay viva_smart dialog: ${res.selected} (tip ${res.tip})`);
            const loc = this.router.url;
            return this.api.createVivaPaymentOrder(res.tip, undefined, undefined, undefined, payableRefs, undefined, loc).pipe(
              flatMap(vpo => this.openVivaSmartCheckout(vpo))
            );
          case "swish":
            return from(this.firebaseAuth.currentUser).pipe(
              flatMap(user => {
                const feedUrlHack = window.location.href.replace("/cart", `/paystatus/RECEIPT_KEY?sec=_SECURITY_&uid=${user.uid}`);
                const href = this.order.user_cfg.user_type === UserType.Messenger ? undefined : feedUrlHack;
                console.log("Swish callback", href);
                return this.api.swishLink(res.tip, href, undefined, undefined, undefined, payableRefs);
              }),
              tap(sl => {
                this.navUrlHack = null;
                // Redirect to app will fail on desktop in messenger
                if (this.messengerService.isOnDesktopAndInIFrame() || this.isKiosk) {
                  this.router.navigateByUrl(`${this.venueId}/paystatus/${res.receipt_key}`);
                } else {
                  // Patch link with receipt key
                  const link = sl.link.replace("RECEIPT_KEY", sl.receipt_key);
                  console.log("redirect to: " + link);
                  window.location.replace(link);

                  // Build pre navigate url to make sure we open same tab in mobile browsers
                  const urlparts = decodeURIComponent(link).split("/paystatus/");
                  if (urlparts.length === 2) {
                    const navUrl = `${this.venueId}/paystatus/${urlparts[1]}`;
                    console.log("nav to: " + navUrl);
                    this.navUrlHack = navUrl;
                  }
                }
              })
            );
          case "prepaid":
            console.log(`Pay dialog: ${res} (tip ${res.tip})`);
            return this.orderService.payWithPrepaid(res.tip, undefined, undefined, undefined, payableRefs);
          case "account":
            console.log(`Pay dialog: ${res} (tip ${res.tip})`);
            return this.orderService.payWithAccount(res.tip, undefined, undefined, undefined, payableRefs);
          case "free":
            console.log(`Pay dialog: ${res} (tip ${res.tip})`);
            return this.orderService.payWithFree(res.tip);
        }
      }),
      tap(res => {
        this.showSpinner = false;
        console.log(res);
        if (res.receipt_key) {
          // Payment initiated waiting for external confirmation (swish payment, 3D-secure...)
          if (this.navUrlHack != null) {
            this.router.navigateByUrl(this.navUrlHack);
          } else {
            this.router.navigateByUrl(`${this.venueId}/paystatus/${res.receipt_key}`);
          }
        } else {
          if (type !== "prepaid") {
            this.orderService.clearCurrentOrder();
          }
          this.messengerService.doPostAction(this.venueId, "cart_paid");
        }
      })
    ).subscribe(res => {
    }, error => {
      this.showSpinner = false;
      const message = error?.error?.message;
      const s = this.getString("Tyvärr kunde vi inte genomföra betalningen.");
      this.snackbar.open(`${s}\n${message}`, this.getString("Stäng"), { duration: 20000 });
      console.log("Gick inte att betala", error);
    });
  }

  private buildPayableRefs() {
    if (this.itemRefs?.length>0) {
      return this.itemRefs.join(",");
    }
    return undefined;
  }

  private redirectSCA(bamTok: BamboraTokenizedAuth) {
    if (bamTok.method === "post") {
      console.log("Redirect with POST");
      let inputs = "";
      for (const param of bamTok.parameters) {
        inputs += `<input name="${param.name}" type="hidden" value="${param.value}" />`;
      }
      const html = `<form id="innerFormPost" method="post" action="${bamTok.redirect_url}">${inputs}</form>`;
      document.getElementById("formPostDiv").innerHTML = html;
      console.log(html);
      const innerFormPost = document.getElementById("innerFormPost");
      console.log(innerFormPost);
      if (innerFormPost) {
        // @ts-ignore
        innerFormPost.submit();
      }
    } else if (bamTok.method === "redirect") {
      console.log("Redirect to", bamTok.redirect_url);
      window.location.replace(bamTok.redirect_url);
    }
  }

  private openPayDialog(type: string): Observable<any> {
    // TODO: Tip for prepaid payments is turned off for now, since it dosent work correctly
    const isCardAndHasSavedCard = type === "card" && this.order.user_cfg.payment?.saved_card;
    const typeWithoutTip = ["prepaid", "free", "account"].includes(type);
    const needToOpen = !typeWithoutTip && (isCardAndHasSavedCard || this.order.user_cfg.payment.use_tip);
    if (needToOpen) {
      const dialogRef = this.dialog.open(PayDialogComponent, HackUtils.DLG({
        data: { type, userCfg: this.order.user_cfg, amount: this.order.amount }
      }));
      return dialogRef.afterClosed();
    } else {
      return from([{ tip: 0 }]);
    }
  }

  private openBamboraCheckout(session: BamboraSession): Observable<any> {
    console.log(`Open bambora checkout: ${session}`);
    this.showSpinner = false;
    return new Observable(obs => {
      // @ts-ignore
      const checkout = new Bambora.ModalCheckout(session.token);
      // @ts-ignore
      checkout.on(Bambora.Event.Authorize, payload => {
        obs.next(payload.data);
        obs.complete();
      });
      checkout.show();
    });
  }

  private openVivaSmartCheckout(vpo: VivaPaymentOrder): Observable<any> {
    console.log(`Open viva smart checkout: ${vpo}`);
    this.showSpinner = false;
    return new Observable(obs => {
      console.log("redirect to: " + vpo.redirect_url);
      window.location.replace(vpo.redirect_url);
    });
  }

  refill() {
    this.router.navigateByUrl(`${this.venueId}/prepaid`);
    return false;
  }

  terms() {
    this.router.navigateByUrl(`${this.venueId}/terms`);
    return false;
  }

  timeClassChanged(tc: string) {
    if (tc === "later") {
      this.openSelectTimeDialog().subscribe(res => {
        console.log(res);
      });
    } else if (tc === "now") {
      this.showSpinner = true;
      this.orderService.updateCollected(undefined, 0, undefined, undefined).subscribe(res => {
        this.showSpinner = false;
      });
    }
    return false;
  }

  selectSlotTime() {
    this.openSelectDateTimeDialog().subscribe(res => {
      console.log(res);
    });
  }

  changePickupLocation(pl: string) {
    this.showSpinner = true;
    this.orderService.updateCollected(undefined, undefined, pl, undefined).subscribe(res => {
      this.showSpinner = false;
    });
  }

  selectedChange(selectedItem: MenuItem) {
    // Setup default attribute values
    this.attrValues[selectedItem.id] = {};
    for (const atr of selectedItem.attributes ?? []) {
      if (!atr.hidden && !this.attrValues[selectedItem.id][atr.id]) {
        const opt = atr.options.find(o => o.default) ?? (atr.options.length > 1 ? atr.options[0] : undefined);
        if (opt) {
          this.attrValues[selectedItem.id][atr.id] = opt.key;
        }
      }
    }
  }

  prepareInlinePush() {
    const menuStruct = this.menu.getMenuStructure();
    const pushIds = new Set();
    const itemIds = new Set();
    for (const item of this.order.items) {
      const itemId = item.item;
      itemIds.add(itemId);
      if (!itemId.startsWith("iddrk")) { continue; }
      const mit = MenuUtils.getItemInMenu(itemId, menuStruct);
      for (const mipush of mit?.pushes ?? []) {
        for (const pgroup of mipush.groups) {
          for (const pushId of pgroup.items) {
            if (!this.menu.isOutOfStock(pushId)) {
              pushIds.add(pushId);
            }
          }
        }
      }
    }

    //No inline push when ordering food
    for (const itemId of itemIds) {
      // @ts-ignore
      if (itemId.startsWith("idfd")) {
        pushIds.clear();
      }
    }

    const recList = [];
    for (const pushId of pushIds) {
      if (itemIds.has(pushId)) { continue; }
      // @ts-ignore
      const mi = MenuUtils.getItemInMenu(pushId, menuStruct);
      if (mi) {
        recList.push(mi);
      }
    }

    this.selectedItems = {};
    this.inlineRecommendations = recList.length > 0 ? recList : null;

    // Donations
    if (menuStruct.donation_items?.length > 0) {
      const donationIds = menuStruct.donation_items.map(d => d.id);
      let includesDonation = false;
      for (const item of this.order.items) {
        const itemId = item.item;
        if (donationIds.includes(itemId)) {
          //Already includes a donation
          includesDonation = true;
        }
      }
      this.inlineDonations = !includesDonation ? menuStruct.donation_items : undefined;
    }

  }

  addInlinePushToOrder(): Observable<any> {
    const menuStruct = this.menu.getMenuStructure();
    const itemIds = MenuUtils.collectSelectedItemIds(this.selectedItems, this.attrValues, menuStruct);
    if (itemIds.length > 0) {
      console.log("addInlinePushToOrder...");
      this.showSpinner = true;
      return this.orderService.addItemsToOrder(itemIds, undefined, false).pipe(
        tap(r => { this.showSpinner = false; })
      );
    } else {
      return of({});
    }
  }

  isDTOpen(): boolean {
    return MenuUtils.isDeliverTypeOpen(this.order.user_cfg.dt, this.config);
  }

  mustScan(): boolean {
    if (this.auth.kioskMode) { return false; }
    if (this.order.user_cfg.table?.startsWith("SNOTA_")) {
      return true;
    }
    return this.order.user_cfg.must_scan;
  }

  canOrder(): boolean {
    return this.auth.kioskMode || this.order?.user_cfg.payment.can_order;
  }

  private setupItemsForDisplay() {
    this.editableItems = [];
    this.fixedItems = [];
    // Filter out package items that are components (package is not null and package.aggregated_cent is null)
    const filteredItems = this.order.items.filter(item => item.package == null || item.package.aggregated_cent != null);
    for (const item of filteredItems) {
      const fixed = item.fixed;
      if (fixed) {
        this.fixedItems.push(item);
      } else {
        this.editableItems.push(item);
      }
    }
  }

  isFree() {
    return this.order.amount === 0;
  }

  pickedItemChanged(item: OrderItem) {
    console.log(item);
    this.itemSelected(item);
  }

  private setupPayableItems() {
    // Check if closed bill then listen for payable items
    if (this.order?.user_cfg.use_mini_split && this.order?.user_cfg.dt === "table" && this.order?.user_cfg.dt_open_for_order && this.order?.user_cfg.payment.can_order && !this.order?.user_cfg.payment.open_order) {
      this.beginObservingLivesplitData(this.order?.user_cfg.table);
    }
  }

  private beginObservingLivesplitData(table: string) {
    if (this.liveSub) { return; }
    console.log(`Observe table: ${table}`);
    this.liveSub = this.fire.observeLivesplit(table, Number(this.venueId)).subscribe(ls => {
      if (ls == null) {
        console.warn("Livesplit data is missing");
        return;
      }
      const items = this.groupPaymentData(ls);
      this.payableItems = items;
      //this.priceFormatted = MoneyUtils.format(this.amount);
      //this.leftFormatted = MoneyUtils.format(this.left);
      console.log("Payable items:", items);
    });
  }

  private addRowToCurrentSelected(row) {
    const count = row.count ?? 1;
    const priceSum = row.price * count;
    const prePriceSum = (row.pre_price ?? row.price) * count;
    this.amount += priceSum;
    this.totalPre += prePriceSum;
    this.itemRefs.push(`${row.ok}|${row.rid}`);
  }

  private groupPaymentData(data) {
    console.log("Document data:", data);
    const userId = this.order.user_cfg.user_id;
    this.amount = 0;
    this.totalPre = 0;
    this.left = 0;
    this.itemRefs = [];
    const items = [];
    const groupCount = data.groups;
    for (let i = 0; i < groupCount; i++) {
      const group = data["group" + i];
      const rids = group.rids.split(",");
      let allSelected = true;
      for (const rid of rids) {
        const row = data["rid" + rid];
        const isMine = userId === row.user_id;
        if (row.state === "paid") {
          row.canChange = false;
        } else {
          if (row.state === "reserved") {
            row.canChange = isMine;
            if (isMine) {
              this.addRowToCurrentSelected(row);
            }
          } else {
            const count = row.count ?? 1;
            const priceSum = count * row.price;
            this.left += priceSum;
            row.canChange = true;
            allSelected = false;
          }
        }

        row.selected = row.state === "paid" || row.state === "reserved";
        items.push(row);
      }
    }

    return items;
  }

  itemSelected(item: any) {
    console.log("itemSelected", item);
    const docRef = this.fire.getLivesplitDocRef(this.order.user_cfg.table, Number(this.venueId)).ref;

    this.fire.runTransaction(transaction => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(docRef).then( doc => {
        if (!doc.exists) {
          throw new Error("Document does not exist!");
        }

        const rowName = "rid" + item.rid;
        const data = doc.data();
        const orgRowData = data[rowName];
        const reserve = item.selected;

        //Check has correct state
        const orgIsOpen = orgRowData.state == null || orgRowData.state === "open";
        const orgIsReserved = orgRowData.state === "reserved";
        if (reserve && !orgIsOpen || !reserve && !orgIsReserved) {
          console.log("Race condition, and you lost!");
          transaction.update(docRef, {});
          this.snackbar.open("Oj den hann bli reserverad!", null, { duration: 2000 });
          return;
        }

        if (reserve) {
          orgRowData.state = "reserved";
          orgRowData.user_id = this.order.user_cfg.user_id;
          orgRowData.user = this.order.user_cfg.user_name;
        } else {
          orgRowData.state = "open";
          orgRowData.user_id = 0;
          orgRowData.user = "";
        }

        const d = {};
        d[rowName] = orgRowData;
        transaction.update(docRef, d);
      });
    }).then( () => {
      console.log("Transaction successfully committed!");
    }).catch( error => {
      this.snackbar.open("Gick inte att reservera artikeln", null, { duration: 2000 });
      item.selected = !item.selected;
      console.log("Transaction failed: ", error);
    });
  }

  toggleShowAnonLogin() {
    this.anonWantsToLogin = !this.anonWantsToLogin;
    this.clearEmail();
    return false;
  }

  anonEmailChanged() {
    if (this.emailFormControl.valid) {
      const anonEmail = this.emailFormControl.value;
      console.log("anonEmailChanged is a valid email:", anonEmail);
      this.orderService.updateCollected(undefined, 0, undefined, undefined, undefined, undefined, anonEmail).subscribe(res => {
        console.log("Updated anon email:", res);
      });
    }
  }

  customChanged(cust: any) {
    if (cust.formControl.valid) {
      const val = cust.formControl.value;
      console.log(`Custom changed: ${cust.id} = ${val}`);
      this.orderService.updateCollected(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, cust.id, val).subscribe(res => {
        console.log("Updated custom collect:", res);
      });
    }
  }

  openAnonOrderPanel() {
    this.anonPanelOpen = true;
    return false;
  }

  getString(s: string, pc: string = "normal") {
    return this.translate.single(s, pc);
  }

  clearEmail() {
    this.emailFormControl.setValue("");
  }

  async initWallet() {
    if (this.googlePay != null) {
      this.googlePay = null;
    }

    //const merchantNumber = "P400486502";
    const merchantNumber = "T400486501";
    const merchantId = "12345678901234567890";

    const googlePayData= {
      merchantInfo: {
        merchantId,
        merchantName: this.config.name
      },
      allowedPaymentMethods: [{
        parameters: {
          allowedCardNetworks: ["VISA", "MASTERCARD"]
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "worldlineonlinecheckout",
            gatewayMerchantId: merchantNumber,
          }
        }
      }],
      transactionInfo: {
        currencyCode: "SEK",
        countryCode: "SE",
        totalPrice: this.order.amount.toString(),
      }
    };

    const googlePayConfiguration = {
      clientConfiguration: {
        environment: "TEST" // 'PRODUCTION' or 'TEST'
      },
      sessionProvider: {
        fetchSession: this.fetchGooglePaySession.bind(this)
      }
    };

    try {
      console.log("googlePayConfiguration", googlePayConfiguration);
      console.log("googlePayData", googlePayData);
      this.googlePay = await WorldlineOnlineCheckout.GooglePay.create(googlePayConfiguration, googlePayData);
      console.log("googlePay", this.googlePay);

      // Check if Google Pay is available
      const isReady = await this.googlePay.isReady();
      if (isReady) {
        // Create the Google Pay button
        const buttonContainer = document.getElementById('google-pay-button');
        if (buttonContainer) {
          // Remove any existing button
          buttonContainer.innerHTML = '';
          // Create a new button

          const options = {
            buttonRadius: 100,
            buttonSizeMode: 'fill'
          };
          this.googlePay.createButton(buttonContainer, options);
        }

        // Start the payment process when the button is clicked
        buttonContainer?.addEventListener('click', async () => {
          try {
            await this.googlePay.start();
          } catch (error) {
            console.error('Error starting Google Pay:', error);
          }
        });
      } else {
        console.log('Google Pay is not available');
        this.googlePay = null;
      }
    } catch (error) {
      console.error('Error initializing Google Pay:', error);
      this.googlePay = null;
    }
  }

  async fetchGooglePaySession() {
    console.log("fetchGooglePaySession");

    // Call your backed and create a new Google Pay wallet session
    const sessionData = await this.api.bamboraCreateWalletSession("0", undefined, undefined, undefined, undefined);
    console.log("Session data", sessionData);

    // Extract Identifier and CallbackUrl
    const identifier = sessionData.session.data.find(item => item.key === "Identifier").value;
    const callbackUrl = sessionData.session.data.find(item => item.key === "CallbackUrl").value;
    console.log("Identifier:", identifier);
    console.log("CallbackUrl:", callbackUrl);

    return {
      data: {
        Identifier: identifier,
        CallbackUrl: callbackUrl
      }
    };
  }

  // fetchGooglePaySession(done) {
  //   console.log("fetchGooglePaySession");
  //   // console.log(this);
  //   // console.log(this.api);
  //
  //   // Call your backend and create a new Google Pay wallet session
  //   this.api.bamboraCreateWalletSession("0", undefined, undefined, undefined, undefined)
  //     .subscribe(sessionData => {
  //       console.log("Session data", sessionData);
  //
  //       // Extract Identifier and CallbackUrl
  //       const identifier = sessionData.session.data.find(item => item.key === "Identifier").value;
  //       const callbackUrl = sessionData.session.data.find(item => item.key === "CallbackUrl").value;
  //       console.log("Identifier:", identifier);
  //       console.log("CallbackUrl:", callbackUrl);
  //
  //       // Invoke the callback with the session data
  //       done({
  //         data: {
  //           Identifier: identifier,
  //           CallbackUrl: callbackUrl
  //         }
  //       });
  //     }, error => {
  //       // Handle error if necessary
  //       console.error("Error fetching session data", error);
  //       done(null, error);
  //     });
  // }
}
