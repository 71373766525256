<mat-dialog-content class="dialog-container mat-typography">
  <div class="dialog-header" cdkFocusRegionstart>
      <div class="dialog-title">{{getString(data?.title)}}</div>
  </div>
  <div *ngIf="data?.message" class="dialog-content">
      <p class="dialog-message">{{getString(data?.message)}}</p>
  </div>
  <div class="button-list">
    <button *ngIf="data?.negBtn" mat-stroked-button mat-dialog-close (click)="negClick()">{{getString(data?.negBtn)}}</button>
    <button mat-raised-button color="accent" (click)="posClick()">{{getString(data?.posBtn)}}</button>
  </div>
</mat-dialog-content>
