import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription, timer} from "rxjs";

@Component({
  selector: 'app-dunder',
  templateUrl: './dunder.component.html',
  styleUrls: ['./dunder.component.css']
})
export class DunderComponent implements OnInit, OnDestroy {
  isLoading = true;
  message = "Välkommen...";
  private sub: Subscription;
  private timerSub1: Subscription;
  private timerSub2: Subscription;

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sub = this.auth.observeIdentity().subscribe( identity => {
      console.log("DONE...");
      this.router.navigate(["../cart"], {relativeTo: this.route});
    });
    this.timerSub1 = timer(10000).subscribe(val => {
      this.message = "Hmm, det verkar ta lite längre tid än vanligt...";
    });
    this.timerSub2 = timer(20000).subscribe(val => {
      this.router.navigate(["../menu/food"], {relativeTo: this.route});
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.timerSub1?.unsubscribe();
    this.timerSub2?.unsubscribe();
  }

}
