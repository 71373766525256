import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-post-landing',
  templateUrl: './post-landing.component.html',
  styleUrls: ['./post-landing.component.css']
})
export class PostLandingComponent implements OnInit {
  private venueId: string;
  private messageId: string;
  title: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      this.messageId = data.get("message_id");
      this.setupMessage();
    });
  }

  private setupMessage() {
    switch (this.messageId) {
      case "order_sent":
        this.title = "Din beställning är skickad.";
        break;
      case "cart_paid":
        this.title = "Din beställning är betald och skickad.";
        break;
      case "payment_failed":
        this.title = "Betalningen har avbrutits eller nekats.";
        break;
      case "waitress_called":
        this.title = "Du har kallat på servitör.";
        break;
      default:
        this.title = "Klart!";
    }
  }
}
