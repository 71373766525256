import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appHires]'
})
export class HiresDirective {
  @Input() appHires: string;

  constructor(private elementRef: ElementRef) {}

  // Trigger when image has been correctly loaded
  @HostListener('load') load() {
    if (this.elementRef.nativeElement.src !== this.appHires) {
      const isServingUrl = this.appHires.includes("lh3.googleusercontent.com");
      if (!isServingUrl) {
        this.elementRef.nativeElement.src = this.appHires;
      } else {
        // width 600, no upscale
        this.elementRef.nativeElement.src = this.appHires + "=w600-nu";
      }
      this.appHires = this.elementRef.nativeElement.src;
    }
  }
}
