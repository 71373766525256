<div class="container" *ngIf="!isLoading">
  <div class="back" (click)="back()">
    <mat-icon style="font-size: 27px">navigate_before</mat-icon>
    <div style="margin: 3px 0 0 5px;">Köpvillkor</div>
  </div>

  <div style="margin-top: 32px">

    <ol>
      <li>

        <b>Företagsinformation</b>
        <p>Heynow tillhandahålls av Heynow AB med Org nummer 559103-2189. www.heynow.ai info@heynow.ai</p>
        <p>Försäljning via swish tillhandahålls av {{busName}} med Org nummer {{orgNumber}}</p>
        <div [innerHTML]="swishInfo"></div>

      </li>
      <li>
        <b>Priser</b>
        <p>Varje vara anges inklusive moms i Svenska kronor. I ordersammanställningen ser man det totala priset
          inklusive avgifter.</p>

      </li>
      <li>

        <b>Betalning</b>
        <p>Betalning sker genom vald betalningsmetod vid beställning av vara. Betalningsmetoden tillhandahålls av
          Bambora tillsammans med samarbetspartners.</p>

      </li>
      <li>

        <b>Kortbetalning</b>
        <p>Korbetalning accepteras av Visa, MasterCard och Maestro. Debitering av ditt betalkort sker direkt.</p>
      </li>
      <li>

        <b>Återbetalningsskyldighet</b>
        <p>Om du mot förmodan har blivit dubbel debiterad eller betalat fel pris kontakta Heynow omgående.</p>
      </li>
      <li>

        <b>Personuppgiftsbehandling</b>
        <p>För att kunna ta emot, hantera och leverera din beställning kommer Heynow AB att behandla dina
          personuppgifter. All vår behandling av personuppgifter sker i enlighet med gällande
          personuppgiftslagstiftning. Du kan läsa mer om hur vi behandlar dina personuppgifter under Bilaga 1,
          Personuppgiftsbehandling.</p>
      </li>
      <li>

        <b>Kontakt & Kundtjänst</b>
        <ul>
          <li>Email: support@heynow.ai</li>
          <li>Telefon: 010-214 60 77</li>
        </ul>
      </li>
      <li>

        <b>Cookies</b>
        <p>En cookie är en textfil som skickas från en websida till din dator där den lagras antingen i minnet (session
          cookies) eller som en liten textfil (textbaserade cookies). Cookies används för att lagra t.ex.
          inloggningsinformation eller din kundvagn när du surfar runt på olika webplatser. Om din webbläsare är
          inställd på att inte acceptera cookies kommer du inte ha möjlighet att göra någon beställning på vår sida.
          Du kan lätt ändra detta under inställningar i din webbläsare. Observera att vi inte använder cookies för att
          spara personlig information om dig</p>

      </li>
      <li>

        <b>Force Majeure</b>
        <p>Force Majeure såsom krig, omfattande arbetskonflikt, blockad, eldsvåda, miljökatastrof, allvarlig
          smittspridning eller annan omfattning som part inte råder över och som förhindrar part att fullgöra sina
          skyldigheter och befriar part från detta. Sådan befrielse gäller under förutsättning att verksamheten inte
          heller kan bedrivas under dessa förhållanden. Motparten skall omedelbart underrättas om omständigheter som
          kan tillämpning denna bestämmelse.</p>

      </li>
      <li>

        <b>Ändring av avtalsvillkor</b>
        <p>Efter att kund genomfört ett köp äger Säljföretaget inte rätt att ändra villkoren för aktuellt köp om inget
          annat är överenskommet</p>
      </li>
      <li>

        <b>Klagomål</b>
        <p>Har kund klagomål skall dessa i första hand framföras till personalen på restaurangen, alternativt kontakta via kontaktuppgifterna under punkt 7.</p>
      </li>
      <li>

        <b>Tvister & lagar</b>
        <p>Svensk rätt skall tillämpas på samtliga köp enligt dessa avtalsvillkor. Tvist om köp enligt dessa
          avtalsvillkor skall exklusivt prövas av svensk allmän domstol.</p>
      </li>
    </ol>

    <h4>Bilaga 1, Personuppgiftsbehandling</h4>
    <p>Heynow AB, org. nr. 559103-2189, behandlar personuppgifter i samband med handeln via Heynows beställningssystem och
      är personuppgiftsansvarigt för behandlingen. Personuppgifter behandlas för att Heynow AB ska kunna tillhandahålla
      sina tjänster, huvudsakligen för följande ändamål (”Ändamålen”):</p>
    <ul>
      <li>För att skapa och upprätthålla ditt personliga konto.</li>
      <li>För att ta emot och bearbeta dina beställningar och betalningar.</li>
      <li>För att hantera eventuella returer.</li>
      <li>För att besvara dina frågor och informera dig om nya eller ändrade tjänster.</li>
      <li>För att skicka marknadsföringserbjudanden som nyhetsbrev och kataloger.</li>
      <li>För att skicka dig enkäter som ger dig möjlighet att påverka Heynow AB:s erbjudanden och tjänster.</li>
    </ul>


    <p>De uppgifter som behandlas är de uppgifter som du anger, t.ex. när du lägger beställningar, kontaktar kundtjänst
      eller dylikt. De uppgifter du anger omfattar t.ex. kontaktinformation, leveransadress och beställda varor
      (”Personuppgifterna”). Personuppgifterna är nödvändiga för att Heynow AB ska kunna uppnå Ändamålen. Viss information
      kan även komma att inhämtas från offentliga register eller från andra allmänt tillgängliga källor. Den huvudsakliga
      rättsliga grunden för behandlingen är att behandlingen är nödvändig för att Heynow AB ska kunna fullgöra sina
      åtaganden gentemot sina kunder. Vissa behandlingar baseras även på Heynow AB:s berättigade intresse att kunna
      bedriva sin verksamhet på ett effektivt och säkert sätt samt på Heynow AB:s skyldighet att följa rättsliga
      förpliktelser. Personuppgifterna kan komma att delas med myndigheter och andra parter som Heynow AB anlitar för att
      uppnå Ändamålen. Dessa aktörer kan vara baserade i länder utanför EU/EES. Om Europakommissionen inte anser att
      landet säkerställer en adekvat skyddsnivå kommer överföringen till den tredje parten att ha stöd i kommissionens
      standardavtalsklausuler för överföring av personuppgifter till ett land utanför EU/EES, se artiklarna 45–46 i
      förordning 2016/679 (”GDPR”). Dessa standardavtalsklausuler finns åtkomliga här:
      http://ec.europa.eu/justice/data-protection/international-transfers/transfer/. Behandlingen av Personuppgifterna
      kommer pågå så länge det krävs för att uppfylla Ändamålen eller så länge som Heynow AB är förpliktade att göra
      detta. Därefter raderas Personuppgifterna. Om du har några frågor kring behandlingen av Personuppgifterna kan du
      kontakta Heynow AB via info@heynow.ai. Du kan även använda denna adress om du vill utöva någon av de rättigheter du
      enligt GDPR har som registrerad. Vänligen notera att rättigheterna enligt GDPR inte är absoluta och att ett
      åberopande av en rättighet därför inte nödvändigtvis leder till en åtgärd. Dina rättigheter enligt GDPR inkluderar
      följande:</p>
    <ul>
      <li>Rätt till tillgång – Enligt artikel 15 GDPR har du rätt att ta del av Personuppgifterna och att bli informerad
        om behandlingen. Den informationen finns i detta dokument.
      </li>
      <li>Rätt till rättelse – Enligt artikel 16 GDPR har du rätt att utan onödigt dröjsmål få felaktiga personuppgifter
        som rör dig rättade. Med beaktande av ändamålet med behandlingen har du också rätt att komplettera ofullständiga
        personuppgifter.
      </li>
      <li>Rätt till radering (”rätten att bli bortglömd”) – Under vissa omständigheter har du med stöd av artikel 17 GDPR
        rätt att få Personuppgifterna raderade.
      </li>
      <li>Rätt till begränsning av användning – Under vissa omständigheter har du enligt artikel 18 GDPR rätt att begränsa
        behandlingen av Personuppgifterna.
      </li>
      <li>Rätt till dataportabilitet – Enligt artikel 20 GDPR har du rätt att få ut Personuppgifterna i ett strukturerat,
        allmänt använt och maskinläsbart format. Du har också rätt att överföra dessa uppgifter till en annan
        personuppgiftsansvarig.
      </li>
      <li>Rätt att göra invändningar – Enligt artikel 21 GDPR har du rätt att invända mot viss behandling av
        Personuppgifterna, exempelvis sådan behandling som grundar sig i Test AB:s berättigade intressen. Slutligen har
        du också rätt att inge klagomål till Datainspektionen, som kan kontaktas via
        datainspektionen@datainspektionen.se eller 08-657 61 00.
      </li>
    </ul>

  </div>
</div>

<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
