import {Component, NgZone, OnInit} from '@angular/core';
import {AppStateService} from '../../../services/app-state.service';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from "../../../services/translate.service";

@Component({
  selector: 'app-sign-in-dialog',
  templateUrl: './sign-in-dialog.component.html',
  styleUrls: ['./sign-in-dialog.component.css']
})
export class SignInDialogComponent implements OnInit {

  constructor(private app: AppStateService, private dialogRef: MatDialogRef<SignInDialogComponent>,
              private ngZone: NgZone, private translate: TranslateService) { }

  ngOnInit(): void {
    this.app.observeAppState().subscribe((appState) => {
      if (!appState.order.user_cfg.is_anon) {
        console.log("Signed in from dialog...");
        this.ngZone.run(() => this.dialogRef.close());
      }
    });
  }

  getString(s: string) {
    return this.translate.single(s);
  }
}
