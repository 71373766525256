<mat-dialog-content class="item-dialog mat-typography">

  <div class="item-header">
    <div class="item-title">{{title}}</div>
  </div>

  <div class="item-content" *ngIf="type=='personalGiftcard'">
    <div>
      Gör presentkortet personligt genom att skriva en hälsning till mottagaren.
    </div>
    <div style="margin-top:16px;">
      Din hälsning och ditt namn kommer att skrivas på presentkortet. Se nedan för ett exempel.
    </div>
    <div style="margin-top: 16px;">
      <img src="https://storage.googleapis.com/heynow/media/test_giftcard_pdf.png" width="100%">
    </div>
  </div>

  <div class="button-list" style="padding: 16px;margin-top: 32px;">
    <button mat-stroked-button mat-dialog-close>Stäng</button>
  </div>

</mat-dialog-content>
