import {MenuStructure} from './FSMenu';
import {VenueConfig} from './FSConfig';
import {Order} from './Order';
import {Identity} from '../objects/Identity';

export class AppState {
  menu: MenuStructure;
  cfg: VenueConfig;
  order: Order;
  venueId: number;
  identity: Identity;
  lang: string;
}
