<section *ngIf="!isLoading">
  <div class="container">
    <div class="back" (click)="back()">
      <mat-icon style="font-size: 27px">navigate_before</mat-icon>
      <div style="margin: 3px 0 0 5px;">KOPPLA MESSENGER-KONTO</div>
    </div>

    <div>Öppna Messenger och skicka koden nedan som ett meddelande för att koppla ihop kontot</div>
    <div class="code-panel">
      <div class="code">{{code}}</div>
    </div>
    <div class="wait-spinner" *ngIf="!isConnected">
      <mat-spinner [diameter]="16"></mat-spinner>
      <div style="margin-left:8px;display: flex;align-items: center;">Väntar på meddelande...</div>
    </div>
    <div class="wait-spinner" *ngIf="isConnected">
      <div style="margin-left:8px;display: flex;align-items: center;">Dina konton är nu ihopkopplade 👍</div>
    </div>

  </div>
</section>

<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>

