import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MenuCategory, MenuItem} from '../../models/FSMenu';
import {combineLatest, Subscription} from 'rxjs';
import {OrderService} from '../../services/order.service';
import {Order} from '../../models/Order';
import {MenuService} from '../../services/menu.service';
import {MenuUtils} from '../../utils/Utils';
import {ConfigService} from '../../services/config.service';
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-menu-list-category',
  templateUrl: './menu-list-category.component.html',
  styleUrls: ['./menu-list-category.component.scss']
})
export class MenuListCategoryComponent implements OnInit, OnDestroy {
  @Input() visiProp: string;
  @Input() itemsDisabled = false;
  @Input() showWhen = false;
  private orderSub: Subscription;
  private order: Order;
  visitems: MenuItem[];
  venueOpen = true;

  // tslint:disable-next-line:variable-name
  _category: MenuCategory;
  @Input() set category(cat: MenuCategory) {
    console.log("MenuListCategoryComponent set: " + cat.name);
    this._category = cat;
    this.buildVisibleItems();
  }

  constructor(private orderService: OrderService, private menuService: MenuService, private configService: ConfigService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.orderSub = combineLatest([
      this.orderService.currentOrder(),
      this.menuService.observeAddendum()
    ]).subscribe(([order, addendum]) => {
      this.order = order;
      this.showWhen = order?.user_cfg?.when_tag != null;

      const cfg = this.configService.getConfigOrNull();
      if (cfg) {
        this.venueOpen = MenuUtils.isDeliverTypeOpen(order.user_cfg.dt, cfg);
      }
      this.buildVisibleItems();
    });
  }

  getCount(item: MenuItem): number {
    return this.orderService.getCount(item.id, false, item.package != null);
  }

  isOutOfStock(item: MenuItem): boolean {
    return this.menuService.isOutOfStock(item.id);
  }

  private buildVisibleItems() {
    console.log("buildVisibleItems...");
    if (this.visiProp === "week") {
      this.visitems = this._category.items?.filter( s => !s.whide );
    } else {
      this.visitems = this._category.items?.filter( s => !s.mhide );
    }
    for (const item of this.visitems) {
      item.currentOrderCount = this.getCount(item);
      item.currentStockState = this.isOutOfStock(item);
    }
    return this.translate.items(this.visitems);
  }

  ngOnDestroy(): void {
    this.orderSub?.unsubscribe();
  }
}
