import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { UrlHistoryService } from './services/url.history.service';
import { Subscription } from 'rxjs';
import { HackUtils, MenuUtils } from './utils/Utils';
import { AppStateService } from './services/app-state.service';
import { MessengerService } from './services/messenger.service';
import { MatDialog } from '@angular/material/dialog';
import { CallWaitressDialogComponent } from './components/sidebar/call-waitress-dialog/call-waitress-dialog.component';
import { AppState } from './models/AppState';
import { SignInDialogComponent } from './components/sign-in/sign-in-dialog/sign-in-dialog.component';
import { Title } from '@angular/platform-browser';
import {AuthService} from "src/app/services/auth.service";
import {TranslateService} from "./services/translate.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'collins';
  opened = false;
  mode = 'side';
  isMobile = false;
  containerHeight: string;
  containerTopMargin: string;
  showTopNav: boolean;
  showBottomNav: boolean;
  private sub: Subscription;
  private sub2: Subscription;
  private matSideNavContentTarget: any;
  venueName: string;
  dtTitle: string;
  showPay = false;
  showFood = false;
  showDrinks = false;
  cartEmpty = true;
  venueId: number;
  cartValue = "";
  showTopNavCartButton = false;
  showCallForWaitress = false;
  showLogin = false;
  brightLoginBtn = false;
  private app: AppState;
  isKiosk: boolean;
  flagEmoji = null;
  isMonter = false;

  constructor(
    private breakpointObserver: BreakpointObserver, private router: Router, private identityService: UrlHistoryService,
    private appState: AppStateService, private messengerService: MessengerService,
    public dialog: MatDialog, private titleService: Title, private auth: AuthService, private translate: TranslateService,
    private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    console.log("AppComponent.ngOnInit");
    this.isKiosk = this.auth.isKioskMode();
    this.isMonter = this.auth.getKioskState() === "monter";
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      if (result.matches) {
        this.opened = false;
        this.mode = 'over';
        this.isMobile = true;
      } else {
        this.opened = true;
        this.mode = 'side';
        this.isMobile = false;
      }
      this.updateContainerDimensions();
    });

    this.router.events.subscribe(event => {
      if (this.isMobile) {
        this.opened = false;
      }
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects || event.url;
        this.identityService.pushUrl(url);
        this.updateContainerDimensions();
      }
    });

    this.beginObserving();
    this.messengerService.init();

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
  }

  private beginObserving() {
    this.sub = this.appState.observeAppState().subscribe(appState => {
      this.app = appState;
      this.cartEmpty = appState.order.items.length === 0;
      this.cartValue = appState.order.total;
      this.showPay = appState.order.user_cfg?.payment?.has_payable_orders && this.cartEmpty;
      this.venueName = appState.cfg.name;
      this.titleService.setTitle(`${this.venueName} - Heynow menu`);
      this.showFood = appState.menu.food?.filter(r => !r.mhide).length > 0;
      this.showDrinks = appState.menu.drinks?.filter(r => !r.mhide).length > 0;
      this.venueId = appState.venueId;
      this.updateSubTitle(appState);
      this.updateContainerDimensions();
      const bl = this.translate.getBrowserLang();
      if (bl !== "sv-SE" && this.isMobile) {
        this.flagEmoji = "🌍";
      }
    });

    this.sub2 = this.translate.translationsReceivedSubject.subscribe(() => {
      console.log("AppComponent translations received");
      this.updateSubTitle(this.app);
      this.cdRef.detectChanges();
    });

  }

  private updateSubTitle(appState: AppState) {
    let showCallForWaitress = false;
    let dtTitle = null;
    if (appState.order.user_cfg.dt) {
      const dtcfg = MenuUtils.getDeliverTypeConfig(appState.order.user_cfg.dt, appState.cfg);
      if (dtcfg) {
        dtTitle = `${this.getString("Meny")} - ${this.getString(dtcfg.title)}`;
        showCallForWaitress = dtcfg.open && dtcfg.call_waiter && appState.order.user_cfg.table != null;
      }
    }
    this.dtTitle = dtTitle;
    this.showCallForWaitress = showCallForWaitress;
  }

  private updateContainerDimensions() {
    const url = this.identityService.currentUrl();
    console.log("updateContainerDimensions: url = " + url);
    if (this.matSideNavContentTarget) {
      this.matSideNavContentTarget.scrollTo(0, 0);
    }
    this.showTopNav = true;
    if (this.isMobile) {
      if (url.indexOf("/menu") >= 0) {
        const buttonCount = (this.showFood ? 1 : 0) + (this.showDrinks ? 1 : 0) + (this.showPay ? 1 : 0) + (!this.cartEmpty ? 1 : 0);
        this.showBottomNav = buttonCount > 1;
      } else {
        this.showBottomNav = url.indexOf("/feed") >= 0 || url.indexOf("/post") >= 0;
      }
      this.showTopNavCartButton = false;
    } else {
      this.showTopNavCartButton = !this.cartEmpty && url.indexOf("/cart") < 0;
      this.showBottomNav = false;
    }

    if (this.showTopNav && this.showBottomNav) {
      this.containerHeight = 'calc( 100% - 143px )';
    } else if (this.showTopNav) {
      this.containerHeight = 'calc( 100% - 56px )';
    } else if (this.showBottomNav) {
      this.containerHeight = 'calc( 100% - 87px )';
    } else {
      this.containerHeight = '';
    }

    const isAHideUrl = url.indexOf("/cart") > 0 || url.indexOf("/feed") > 0 || url.indexOf("/receipt/") > 0 || url.indexOf("/g/") > 0;
    this.showLogin = !isAHideUrl && this.app?.order?.user_cfg?.is_anon && !this.isKiosk;
    this.containerTopMargin = this.showTopNav ? '56px' : '';
  }

  onScroll(event) {
    if (event.target) {
      this.matSideNavContentTarget = event.target;
    }
  }

  clickMenu() {
    this.opened = !this.opened;
  }

  openCart() {
  }

  callForWaitress() {
    this.openCallForWaitressDialog();
  }

  login() {
    console.log("login...");
    const dialogRef = this.dialog.open(SignInDialogComponent, HackUtils.DLG({
      data: {}
    }));
  }

  openCallForWaitressDialog() {
    const dialogRef = this.dialog.open(CallWaitressDialogComponent, {
      minWidth: "95vw",
      position: { top: "8px" },
      data: { venueId: this.venueId, table: this.app.order.user_cfg.table }
    });
    return false;
  }

  getString(s: string) {
    return this.translate.single(s);
  }

  selectLang() {
    this.router.navigateByUrl(`${this.venueId}/language`);
  }
}
